"use strict";
/**
 * ==================================================================================
 * Description:       desc
 * Creation Date:     09/07/16
 * Author:            AMcDaniel
 * ==================================================================================
 * Revision History
 * ==================================================================================
 * Rev     Date        Author      Task        Description
 * ==================================================================================
 * 1       09/07/16    AMcDaniel   109177071   Created
 * 2       09/14/16    AMcDaniel   109177071   Added individual attributes (shallow copy) for each nested entity inst to avoid cross-data corruption
 * 3       09/14/16    AMcDaniel   109177071   Added attribute.default_value functionality.  Added code to launch edit_forms for individual nested entity entries in array for custom html edit_forms
 * 4       09/23/16    AMcDaniel   109177071   Added caching of processed html and entity attributes for custom_html_template to prevent excessive computation. Added attribute.cached_rendered_component and .last_react_key properties. Fixed nested popup delete. Removed dynamic_input_holder.
 * 5       09/26/16    AMcDaniel   109177071   Added descriptions to fields
 * 6       09/26/16    AMcDaniel   109177071   Changed inline styling for Sections to use class form_section_title
 * 7       09/28/16    AMcDaniel   109177071   Changed/Added some styling fixes.  Added placeholders to attributes (currently for html templates only) in the new field html_template_placeholder_text.
 * 8       02/22/17    AMcDaniel   111619571   Added is_valid_month_input function for use with new dashboard options
 * 9       02/24/17    AMcDaniel   137483455   Added a DateTimeLocal type for displaying entity Created On and Last Updated On
 * ==================================================================================
 */ 

function create_form_controls_for_attributes(edit_form_context, options) {
    var entity_record_fields = ['entity_created_by', 'entity_created_on', 'entity_last_updated_by', 'entity_last_updated_on'],
        input_array = [];

    var attribute_options = {
        row_name: null,
        row_array: null,
        prev_same_row: null,
        same_row_count: null,
        type_string_row: null,
        currentSection: null,
        record_fields_box: []
    };
    for (var i = 0; edit_form_context.state.entity_attributes && i < edit_form_context.state.entity_attributes.attributes.length; i++) {
        var attribute = edit_form_context.state.entity_attributes.attributes[i];
        if (!attribute.form_visible) {
            continue;
        }
        if (entity_record_fields.indexOf(attribute.field_path) == -1 && String(attribute.same_row).match(/^entity_record_fields_\d$/)){
            attribute.same_row = "inline_record_fields" + String(attribute.same_row).substr(attribute.same_row.length -2);
        }
        var create_att_args = {
            edit_form_context: edit_form_context,
            attribute: attribute,
            entity_instance: edit_form_context.state.entity_instance,
            attribute_options: attribute_options
        };
        var input_div = create_control_for_attribute(create_att_args);
        if(input_div) {
            add_to_list(input_array, input_div);
        }
    }

    if (attribute_options.row_name) { //ending a previous row
        var input_div = <div className="row"
                         key={"QK-input-wrapper-row-name-"+attribute_options.row_name.toString()+"-"}>{attribute_options.row_array}</div>;
        if (String(attribute_options.row_name).match(/^entity_record_fields_\d$/)){
            attribute_options.record_fields_box.push(input_div);
        } else {
            input_array.push(input_div);
        }
    }


    var element = $('#record-fields-box-test');
    element.children().each(function(e){$(this).children().remove('div');});
    element.parent().show();
    options.record_fields_box_show = attribute_options.record_fields_box && attribute_options.record_fields_box.length > 0 && !element.is(':truncated');
    element.parent().hide();

    options.record_fields_box = attribute_options.record_fields_box;
    return input_array;
}

/**
 * Creates a single reactified control for an attribute
 * @param parameters
 * @param use_cached_attributes - same_row logic breaks if cached attributes are used
 * @returns {*}
 */
function create_control_for_attribute(parameters, use_cached_attributes) {
    var edit_form_context = parameters.edit_form_context;
    var attribute = parameters.attribute;
    var entity_instance = parameters.entity_instance;
    var attribute_options = parameters.attribute_options;
    var base_field_path = parameters.base_field_path;
    var nested_entity_name = parameters.nested_entity_name;
    if(attribute && nested_entity_name && (nested_entity_name != "")) {
        attribute.parent_entity_name = nested_entity_name;
        edit_form_context.state.individual_attributes_per_entity = edit_form_context.state.individual_attributes_per_entity || {};
        edit_form_context.state.individual_attributes_per_entity[base_field_path] = edit_form_context.state.individual_attributes_per_entity[base_field_path] || {};
        edit_form_context.state.individual_attributes_per_entity[base_field_path][attribute.field_path] = edit_form_context.state.individual_attributes_per_entity[base_field_path][attribute.field_path] || $.extend({}, attribute);
        attribute = edit_form_context.state.individual_attributes_per_entity[base_field_path][attribute.field_path];
    }
    if(attribute.cached_rendered_component && use_cached_attributes) {
        return attribute.cached_rendered_component;
    }

    var input_element;
    var input_div;
    var attribute_value;
    var attribute_caption_image;
    var field_change_event_args = {
        attribute: attribute,
        base_field_path: base_field_path,
        nested_entity_name: nested_entity_name
    };
    attribute_value = entity_instance[attribute.field_path] || get_json_data_using_field_paths(attribute.field_path, entity_instance);
    if((attribute_value === undefined) && edit_form_context.state.is_new_instance && edit_form_context.state.init && attribute && (attribute.default_value != null)) {
        attribute_value = attribute.default_value;
        set_json_data_using_field_paths(attribute.field_path, entity_instance, attribute_value);
    }
    attribute_caption_image = null;

    if (attribute_value && (typeof attribute_value == "object") && attribute.is_nested_entity && attribute.data_is_nested == false && !attribute.is_array) {
        if(typeof attribute_value.image === "undefined")
        {}
        else{
            if (attribute_value.image) {
                attribute_caption_image = attribute_value.image;
            }
        }

        attribute_value = attribute_value.caption || attribute_value.entity_id;
    }

    var same_row = attribute.same_row;
    if (same_row && attribute_options) {
        attribute_options.row_name = same_row;
        attribute_options.same_row_count = 0;
        attribute_options.type_string_row=0;
        var temp_section = null;
        for (var k = 0; k < edit_form_context.state.entity_attributes.attributes.length; k++) {
            if (!edit_form_context.state.entity_attributes.attributes[k].form_visible) {
                continue;
            }
            if (edit_form_context.state.entity_attributes.attributes[k].attribute_type == "Section") {
                temp_section = edit_form_context.state.entity_attributes.attributes[k].caption;
            }
            if (edit_form_context.state.entity_attributes.attributes[k].same_row
                && (edit_form_context.state.entity_attributes.attributes[k].same_row == attribute_options.row_name)
                && attribute_options.currentSection == temp_section) {
                if(edit_form_context.state.entity_attributes.attributes[k].attribute_type == "String" || edit_form_context.state.entity_attributes.attributes[k].attribute_type == "zipcode_field" || edit_form_context.state.entity_attributes.attributes[k].attribute_type == "DateTime" || edit_form_context.state.entity_attributes.attributes[k].attribute_type == "DateTimeLocal")
                {
                    attribute_options.type_string_row++;
                }
                attribute_options.same_row_count++;
                if (attribute_options.row_array == null) {
                    attribute_options.row_array = [];
                }
            }
        }
    }

    input_element = undefined;
    var omit_caption = attribute.hide_caption;
    if (attribute.is_array && attribute.is_nested_entity && attribute.data_is_nested) {
        var entity = attribute.db_type;
        var nested_list_entities = edit_form_context.state.nested_list_entities;
        if (edit_form_context.props.data && edit_form_context.props.data.nested_list_entities) {
            nested_list_entities = edit_form_context.props.data.nested_list_entities;
        }
        var data_list_object = nested_list_entities[entity];
        var app_object = data_list_object.app_object;
        if (edit_form_context.form_is_read_only()) {
            app_object.custom_properties.editable = false;
        }
        else {
            app_object.custom_properties.editable = !attribute.read_only;
        }
        var entity_attributes = data_list_object.entity_attributes;
        var entity_instances = attribute_value;
        if (!entity_instances) {
            entity_instance[attribute.field_path] = [];
            entity_instances = entity_instance[attribute.field_path];
        }
        var data_list_key = attribute.field_path;
        if(attribute.last_react_key) {
            data_list_key = attribute.last_react_key;
        }
        else if(use_cached_attributes) {
            attribute.last_react_key = data_list_key + create_guid();
            data_list_key = attribute.last_react_key;
        }
        if (attribute.field_path == "xenforma_notes") {
            input_element = <div className="Notescls"><DataListComponent skip_init={true}
                                                                         read_only={(attribute.read_only || edit_form_context.form_is_read_only())}
                                                                         handle_executing={edit_form_context.props.handle_executing}
                                                                         nested_list_entities={nested_list_entities}
                                                                         parent_react_entity={edit_form_context}
                                                                         parent_entity_field_attribute={attribute}
                                                                         parent_entity_array={entity_instances}
                                                                         app_object={app_object}
                                                                         entity_attributes={entity_attributes}
                                                                         entity_instances={entity_instances}
                                                                         key={data_list_key}
                                                                         delete_nested_entity={edit_form_context.delete_nested_entity}
                                                                         app_object_handler={edit_form_context.props.app_object_handler}
                                                                         nested_entity_handler={edit_form_context.nested_entity_handler}
                                                                         on_change={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                                         is_open={attribute.expanded || attribute.is_open}
                                                                         set_open_state={edit_form_context.set_open_state.bind(edit_form_context, attribute)}
            />
            </div>;
        }
        else {
            input_element = <DataListComponent skip_init={true}
                                               read_only={(attribute.read_only || edit_form_context.form_is_read_only())}
                                               handle_executing={edit_form_context.props.handle_executing}
                                               nested_list_entities={nested_list_entities}
                                               parent_react_entity={edit_form_context}
                                               parent_entity_field_attribute={attribute}
                                               parent_entity_array={entity_instances}
                                               app_object={app_object}
                                               entity_attributes={entity_attributes}
                                               entity_instances={entity_instances}
                                               key={data_list_key}
                                               delete_nested_entity={edit_form_context.delete_nested_entity}
                                               app_object_handler={edit_form_context.props.app_object_handler}
                                               nested_entity_handler={edit_form_context.nested_entity_handler}
                                               on_change={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                               is_open={attribute.expanded || attribute.is_open}
                                               do_refresh={true}
                                               set_open_state={edit_form_context.set_open_state.bind(edit_form_context, attribute)}
            />;
        }
        omit_caption = true;
    }
    else if(String(attribute.same_row).match(/^entity_record_fields_\d$/)){ //create the entity record field element.
        attribute_value = entity_instance[attribute.field_path];
        if (attribute_value && attribute_value.caption) {
            attribute_value = attribute_value?attribute_value.caption:'';
        }
        var field = "";
        input_element = function (local_attribute) {
            if(edit_form_context.state.is_new_instance != true){
                if(local_attribute.db_type =="user")
                {
                    style ={
                        "marginLeft": "20px",
                        "color": "#898989",
                        "fontSize": "11px"
                    };
                    field = <span key={attribute.field_path} style={style}>{local_attribute.caption} {attribute_value}</span>;
                }
                else{
                    style ={
                        "paddingLeft": "4px",
                        "color": "#898989",
                        "fontSize": "11px"
                    };

                    field = <span key={attribute.field_path} style={style}>{(moment(attribute_value).format("M/D/Y h:mm a"))} </span>;
                }}
            return field;
        }(attribute);

    }
    else if(attribute.entity_reference_variant){
        attribute_value = entity_instance[attribute.field_path];
        if (attribute_value) {
            attribute_value = attribute_value.entity_id;
        }
        input_element = function (local_attribute) {
            var addButton = '';
            var editButton = '';
            var choose_entity_popover ="";



            var variant_datasource = edit_form_context.variant_load(attribute);
            edit_form_context.variant_ref_add_button(attribute);
            if (!edit_form_context.state.entity_attributes.field_paths[attribute.field_path].hide_var_ref_buttons) { //add a + sign.

                if(edit_form_context.state.entity_attributes.field_paths[attribute.field_path].choose_entity) {
                    var options_variant = [];

                    for (var j = 0; j < edit_form_context.state.entity_attributes.field_paths[attribute.field_path].choose_entity.length; j++) {
                        var lov = edit_form_context.state.entity_attributes.field_paths[attribute.field_path].choose_entity[j];
                        var option_variant = <option key={lov.entity_name} data-html="true" value={lov.entity_name}>{lov.entity_name_caption}

                        </option>;

                        options_variant.push(option_variant);
                    }

                    choose_entity_popover = <div  key={attribute.field_path+"saasd"}
                                                  style={{"position":"absolute","left":"-9999px","visibility":"hidden"}}>
                        <div className="col-xs-12" id={"id"+attribute.field_path+"variant"}>
                            <div style={{"textAlign":"center","marginBottom":"10px"}}>
                                {R.variant_ref_caption? R.variant_ref_caption:"What would you like to create?"}
                            </div>
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    id={"variantref_"+attribute.field_path}>
                                    {options_variant}
                                </select>
                            </div>
                        </div>
                    </div>;

                }
                var add_func = function (local_attribute,e) {
                    e.preventDefault();
                    if(edit_form_context.state.entity_attributes.field_paths[local_attribute.field_path].choose_entity) {
                        edit_form_context.open_popover(local_attribute.field_path+"variant", function(){
                            try{
                                $("#variantref_"+local_attribute.field_path).each(function(){
                                    var element = $(this);
                                    var button_box = $('<div/>', {style:"padding:5px"}),
                                        save_button_var = $('<button/>',
                                            {
                                                type:'button',
                                                style:'margin:5px; float:right; margin-left:5px: margin-top:5px;margin-bottom:10px',
                                                class:'btn btn-success shiny workflow_button_margin',
                                                id:'button_choose_report_popover',
                                                text: R.client_label_ok?R.client_label_ok:"Ok",
                                                click: function(){
                                                    var selected_value = $( "#variantref_"+local_attribute.field_path ).val();
                                                    edit_form_context.setState({
                                                        flagSaveDropdown: 1,
                                                        currentFieldPath: local_attribute.field_path,
                                                        currentAttribute: local_attribute,
                                                        chosen_entity: selected_value
                                                    });
                                                    if (selected_value) {
                                                        edit_form_context.state.popover.hide();
                                                        edit_form_context.reference_entity_handler(local_attribute, null, selected_value);
                                                    }
                                                }
                                            }),
                                        cancel_button_var = $('<button/>',{
                                            type:'button',
                                            style:'margin:5px; float:right; margin-left:5px: margin-top:5px;margin-bottom:10px',
                                            class:'btn shiny btn-default',
                                            id:'button_cancel_assigned_to_user',
                                            text: R.label_cancel,
                                            click: function(){edit_form_context.state.popover.hide()}
                                        });
                                    cancel_button_var.appendTo(button_box);
                                    save_button_var.appendTo(button_box);
                                    $(button_box).appendTo($(element).parents()[0]);
                                });
                            }
                            catch(error){
                            }
                        });
                    }
                    else if (edit_form_context.state.entity_attributes.field_paths[local_attribute.field_path].var_ref_entity){
                        var chosen_entity = edit_form_context.state.entity_attributes.field_paths[local_attribute.field_path].var_ref_entity[0].entity_name;
                        edit_form_context.setState({
                            flagSaveDropdown: 1,
                            currentFieldPath: local_attribute.field_path,
                            currentAttribute: local_attribute,
                            chosen_entity: chosen_entity
                        });
                        if (chosen_entity) {
                            edit_form_context.reference_entity_handler(local_attribute, null, chosen_entity);
                        }
                    }
                };
                var edit_func = function (local_attribute,e) {
                    if(  entity_instance[local_attribute.field_path] == null ){
                        e.preventDefault();
                    }else{
                        var entity_id = entity_instance[local_attribute.field_path].entity_id || entity_instance[local_attribute.field_path];
                        var entity_name = entity_instance[local_attribute.field_path].entity_name;
                        edit_form_context.setState({
                            flagSaveDropdown: 1,
                            currentFieldPath: local_attribute.field_path,
                            currentAttribute: local_attribute
                        });

                        edit_form_context.variant_edit_function(local_attribute, entity_id, entity_name);
                    }
                };
                if (!(local_attribute.read_only || edit_form_context.form_is_read_only())) {
                    addButton = <button style={{ "marginTop" : "1px" }} type="button"
                                        id={attribute.field_path+"variant"}
                                        className="edit-controls-add-button add-button dx-button"
                                        onClick={add_func.bind( edit_form_context , local_attribute )}
                                        tabIndex={-1}
                    >
                        <div className='dx-button-content'>
                            <i
                                className='dx-icon dx-icon-edit-button-addrow fa fa-pencil-square-o'></i>
                        </div>
                    </button>;
                    editButton = <button  type="button"
                                          onClick={edit_func.bind( edit_form_context , local_attribute )}
                                          className="02 edit-controls-edit-button edit-button dx-button"
                                          style={{  zIndex : "100"    }}
                                          tabIndex={-1}>
                        <div className='dx-button-content'>
                            <i className='dx-icon dx-icon-edit-button-addrow fa fa-pencil-square-o'></i>
                        </div>
                    </button>;
                }
            }
            return (
                <div key={attribute.field_path} className='ref_lookup_container'>
                    <EditorDevExtremeLookup
                        dataSource={variant_datasource}
                        displayExpr="caption"
                        valueExpr="entity_id"
                        currentInstance = {edit_form_context[attribute.field_path+"array"]}
                        value={attribute_value || null}
                        skip_prop_update_function={(nested_entity_name && (nested_entity_name != ""))}
                        onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                        onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                        add_button={addButton!=''}
                        edit_button={editButton!=''}
                        readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                        placeholder={R.start_typing_to_search?R.start_typing_to_search:'Start typing to search...'}
                    />
                    {addButton}
                    {editButton}
                    {choose_entity_popover}
                </div>)
        }(attribute);
    }
    else if (attribute.entity_reference && attribute.entity_reference.is_list) {
        var temp_nested_reference_entity_data = {
            reference_entity_name: attribute.entity_reference.reference_collection,
            attribute: attribute,
            new_entity_json: attribute.entity_reference.new_entity_json
        };

        var data_list_key = attribute.field_path;
        if(attribute.last_react_key) {
            data_list_key = attribute.last_react_key;
        }
        else if(use_cached_attributes) {
            attribute.last_react_key = data_list_key + create_guid();
            data_list_key = attribute.last_react_key;
        }

        input_element = <DataListComponent grid_is_on_edit_form={true}
                                           new_entity_json={attribute.entity_reference.new_entity_json}
                                           record_count={attribute.entity_reference.record_count}
                                           app_object_code={attribute.entity_reference.reference_collection + "_default_data_list"}
                                           handle_executing={edit_form_context.props.handle_executing}
                                           app_object_handler={edit_form_context.props.app_object_handler}
                                           parent_react_entity={edit_form_context}
                                           nested_reference_entity_data={temp_nested_reference_entity_data}
                                           conditions={attribute.entity_reference.conditions || {}}
                                           nested_entity_handler={edit_form_context.nested_entity_handler}
                                           logged_in_false={edit_form_context.props.logged_in_false}
                                           is_open={attribute.expanded || attribute.is_open}
                                           key={data_list_key}
                                           set_open_state={edit_form_context.set_open_state.bind(edit_form_context, attribute)}
        />;
    }
    else if (attribute.is_nested_entity && !attribute.data_is_nested) {
        if (attribute.is_array) {
            if (!attribute_value) {
                entity_instance[attribute.field_path] = [];
                attribute_value = entity_instance[attribute.field_path];
            }
            if (attribute.select_options || attribute.lookup) {
                if (edit_form_context.state.entity_attributes.reference_entities[attribute.db_type] && edit_form_context.state.entity_attributes.reference_entities[attribute.db_type].entity_access && (edit_form_context.state.entity_attributes.reference_entities[attribute.db_type].entity_access.indexOf('c') != -1)) { //add a + sign.
                    input_element = function (local_attribute_field_change_event_args) {
                        var local_attribute = local_attribute_field_change_event_args.attribute;
                        var add_func = function () {
                            edit_form_context.setState({ checkbox_fileupload : false, currentAttribute:local_attribute});
                            edit_form_context.reference_entity_handler(local_attribute, null, null, local_attribute_field_change_event_args);
                        };
                        var addButton = '';
                        if (!(local_attribute.read_only || edit_form_context.form_is_read_only())) {
                            addButton = <button type="button"
                                                className="edit-controls-add-button dx-button"
                                                onClick={add_func.bind( edit_form_context )}
                                                style={{right : "12px", cursor:"pointer"}}
                                                tabIndex={-1}>
                                <div className='dx-button-content'>
                                    <i className='dx-icon dx-icon-edit-button-addrow'>

                                    </i>
                                </div>
                            </button>;
                        }
                        // TODO: refactoring this when save operation will return object
                        if (attribute.db_type == "file") {
                            if (!edit_form_context.state.privateFiles) {
                                edit_form_context.state.privateFiles = {};
                            }
                            if (!edit_form_context.state.privateFiles[attribute.field_path]) {
                                edit_form_context.state.privateFiles[attribute.field_path] = [];
                            }
                            if (entity_instance[local_attribute.field_path]) {
                                for (var k = 0; k < entity_instance[local_attribute.field_path].length; k++) {
                                    var exists = false;
                                    for (var p = 0; p < edit_form_context.state.privateFiles[attribute.field_path].length; p++) {
                                        if(entity_instance[local_attribute.field_path][k] == undefined)
                                        {
                                            delete entity_instance[local_attribute.field_path][k];
                                            exists = true;
                                            break;
                                        }
                                        else if(entity_instance[local_attribute.field_path][k]==edit_form_context.state.privateFiles[attribute.field_path][p])
                                        {
                                            exists = true;
                                            break;
                                        }
                                        else if(edit_form_context.state.privateFiles[attribute.field_path][p] == undefined)
                                        {   delete edit_form_context.state.privateFiles[attribute.field_path][p];
                                            exists = true;
                                            break;
                                        }
                                        else if(entity_instance[local_attribute.field_path][k].entity_id==edit_form_context.state.privateFiles[attribute.field_path][p].entity_id)
                                        {
                                            exists = true;
                                            break;
                                        }
                                        else if(entity_instance[local_attribute.field_path][k].entity_id == edit_form_context.state.privateFiles[attribute.field_path][p])
                                        {
                                            exists = true;
                                            break;
                                        }


                                        else if(entity_instance[local_attribute.field_path][k]==edit_form_context.state.privateFiles[attribute.field_path][p].entity_id)
                                        {
                                            exists = true;
                                            break;
                                        }

                                    }
                                    if (!exists) {
                                        edit_form_context.state.privateFiles[attribute.field_path].push(entity_instance[local_attribute.field_path][k]);
                                    }
                                    var existsindex =null;
                                    var elementPos = entity_instance[local_attribute.field_path].map(function(x) {return x; }).indexOf(entity_instance[local_attribute.field_path][k].entity_id);
                                    if (elementPos != -1)
                                    {
                                        existsindex = elementPos;
                                    }
                                    if (existsindex != null) {
                                        entity_instance[local_attribute.field_path].splice(existsindex, 1);
                                    }

                                }
                                if (!local_attribute.select_options){
                                    local_attribute.select_options = [];
                                }

                                for (p = 0; p < edit_form_context.state.privateFiles[attribute.field_path].length; p++) {
                                    exists = false;
                                    for (k = 0; k < local_attribute.select_options.length; k++) {
                                        if(edit_form_context.state.privateFiles[attribute.field_path][p] == undefined)
                                        {
                                            delete edit_form_context.state.privateFiles[attribute.field_path][p];
                                            exists = true;
                                            break;
                                        }
                                        else if(local_attribute.select_options[k] && local_attribute.select_options[k].entity_id == edit_form_context.state.privateFiles[attribute.field_path][p].entity_id)
                                        {
                                            local_attribute.select_options[k] = edit_form_context.state.privateFiles[attribute.field_path][p];
                                            exists = true;
                                            break;
                                        } else if (!local_attribute.select_options[k]) {
                                            delete local_attribute.select_options[k];
                                        }
                                    }
                                    if (!exists) {
                                        local_attribute.select_options.push(edit_form_context.state.privateFiles[attribute.field_path][p]);
                                    }
                                }
                            }
                        }
                        var tagbox_popover ="";
                        if(edit_form_context.state.tag_template){
                            if(edit_form_context.state.tag_template_values) {
                                tagbox_popover ="";
                                var tag_values = edit_form_context.state.tag_template_values;
                                var id_tag = attribute.field_path + '_' + tag_values._id;
                                var icon = "";
                                if (tag_values.entity == "file") {
                                    if(tag_values.hidden_fa_icon !== null) {
                                        icon = <i
                                            style={{"color":"#606060","fontSize" : "40px" ,"marginLeft" : "-15px", "marginTop" : "20px"}}
                                            className={"fa "+tag_values.hidden_fa_icon}
                                            aria-hidden="true">&nbsp;</i>;
                                    }
                                    else{
                                        icon = <i
                                            style={{"color":"#606060","fontSize" : "40px" ,"marginLeft" : "-15px", "marginTop" : "20px"}}
                                            className={"fa "+tag_values.static_icon}
                                            aria-hidden="true">&nbsp;</i>;
                                    }

                                    var description_array = [];

                                    if(tag_values.description) {
                                        var div = <div
                                            key={"tag_"+ "description"}
                                            style={{"fontSize":"11px", "marginTop": "5px"}}>
                                            {tag_values.description}
                                        </div>;
                                        description_array.push(div);
                                    }

                                    if(tag_values.file_name) {
                                        var div = <div
                                            key={"tag_"+ "file_name"}
                                            style={{"fontSize":"11px", "marginTop": "5px"}}>
                                            {tag_values.file_name}
                                        </div>;
                                        description_array.push(div);
                                    }

                                    if(tag_values.author) {
                                        var div = <div key={"tag_"+ "author"} style={{"fontSize":"11px", "marginTop": "5px"}}>
                                            {tag_values.author.caption}
                                        </div>;
                                        description_array.push(div);
                                    }

                                    if(tag_values.date_uploaded) {
                                        var tag_date = new Date(tag_values.date_uploaded);
                                        var addZero = function(i) {
                                            if (i < 10) {
                                                i = "0" + i;
                                            }
                                            return i;
                                        };

                                        var formatted_date = (tag_date.getMonth() + 1) + '/' + tag_date.getDate() + '/' +  tag_date.getFullYear() + '    ' + addZero(tag_date.getHours()) + ':' + addZero(tag_date.getMinutes());
                                        var div = <div key={"tag_"+ "date_uploaded"} style={{"fontSize":"11px", "marginTop": "5px"}}>
                                            {formatted_date}
                                        </div>;
                                        description_array.push(div);
                                    }
                                    else if(tag_values.date_created =="date_created" && !tag_values.date_uploaded)
                                    {
                                        var tag_date = new Date(tag_values.date_created);
                                        var addZero = function(i) {
                                            if (i < 10) {
                                                i = "0" + i;
                                            }
                                            return i;
                                        };

                                        var formatted_date = (tag_date.getMonth() + 1) + '/' + tag_date.getDate() + '/' +  tag_date.getFullYear() + '    ' + addZero(tag_date.getHours()) + ':' + addZero(tag_date.getMinutes());
                                        var div = <div key={"tag_"+ "date_created"} style={{"fontSize":"11px", "marginTop": "5px"}}>
                                            {formatted_date}
                                        </div>;
                                        description_array.push(div);
                                    }


                                    tagbox_popover = <div style={{"position":"absolute","left":"-9999px","visibility":"hidden"}}><div className="col-xs-12" id={"id"+id_tag}>
                                        <div className="col-xs-1">
                                            {icon}
                                        </div>
                                        <div className="col-xs-9">
                                            {description_array}
                                        </div>
                                        <div style={{"marginTop": "5px","fontSize" : "15px"}}
                                             className="col-xs-1">
                                            <a style={{"color":"#606060","cursor": "pointer"}} id={"edit_"+id_tag}><i className="fa fa-pencil-square-o" aria-hidden="true">&nbsp;</i></a>
                                            <a style={{"color":"#606060","cursor": "pointer"}} id={"download_"+id_tag}><i style={{"marginTop":"5px"}} className="fa fa-download" aria-hidden="true">&nbsp;</i></a>
                                            <a style={{"color":"#606060","cursor": "pointer"}} id={"delete_"+id_tag} ><i style={{"marginTop":"5px"}} className="fa fa-trash" aria-hidden="true">&nbsp;</i></a>
                                        </div>

                                    </div></div>;
                                }
                            }
                        }
                        var usetagtemplate = false;
                        if(edit_form_context.state.new_filelist_data != undefined && attribute.db_type == "file"){
                            local_attribute.select_options = edit_form_context.state.new_filelist_data;

                        }
                        if(attribute.db_type =="file")
                        {
                            usetagtemplate = true;
                        }
                        if(attribute.lookup && !attribute.dataSource) {
                            edit_form_context.lookup_load(attribute);

                        }
                        return (
                            <div style={{  marginRight : "-40.5px"   } }
                                 id={local_attribute.field_path}
                                 data-attribute={JSON.stringify(local_attribute)}
                                 key={attribute.field_path}>
                                <EditorDevExtremeTagBox
                                    tagbox_holder={local_attribute}
                                    dataSource={attribute.dataSource}
                                    displayExpr="caption"
                                    valueExpr="entity_id"
                                    usetagtemplate = {usetagtemplate}
                                    tagTemplate={edit_form_context.tagTemplate.bind(edit_form_context, local_attribute)}
                                    values={entity_instance[local_attribute.field_path]}
                                    onChange={edit_form_context.on_field_change.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    add_button={addButton!=''}
                                    onBlur={edit_form_context.on_field_blur.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    readOnly={(local_attribute.read_only || edit_form_context.form_is_read_only())}
                                    set_element_reference={edit_form_context.set_element_reference.bind(edit_form_context, local_attribute)}
                                />
                                {addButton}
                                {tagbox_popover}
                            </div>
                        )
                    }(field_change_event_args);
                }
                else {
                    if(attribute.lookup && !attribute.dataSource) {
                        edit_form_context.lookup_load(attribute);
                    }
                    input_element =
                        <EditorDevExtremeTagBox key={attribute.field_path}
                                                tagbox_holder={attribute}
                                                dataSource={attribute.dataSource}
                                                displayExpr="caption"
                                                valueExpr="entity_id"
                                                tagTemplate={edit_form_context.tagTemplate.bind(edit_form_context, attribute)}
                                                values={attribute_value}
                                                onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                }
            }
            else {
                return;
            }
        } else {
            attribute_value = entity_instance[attribute.field_path];
            if (!attribute.select_options) {
                field_change_event_args.attribute = attribute;
                attribute.select_options = [];
                if(attribute_value) {
                    attribute.select_options.push(attribute_value);
                }
            }
            if (attribute_value) {
                attribute_value = attribute_value.entity_id;
            }
            input_element = function (local_attribute_field_change_event_args) {
                var local_attribute = local_attribute_field_change_event_args.attribute;
                var addButton = '';
                var editButton = '';

                if (edit_form_context.state.entity_attributes.reference_entities[local_attribute.db_type] && edit_form_context.state.entity_attributes.reference_entities[local_attribute.db_type].entity_access && (edit_form_context.state.entity_attributes.reference_entities[local_attribute.db_type].entity_access.indexOf('c') != -1)) { //add a + sign.
                    var add_func = function () {
                        //Create State variables when clicked in Plus button
                        var currentFieldPath = local_attribute.field_path;
                        var currentAttribute = local_attribute;
                        edit_form_context.setState({
                            flagSaveDropdown: 1,
                            currentFieldPath: currentFieldPath,
                            currentAttribute: currentAttribute
                        });

                        edit_form_context.reference_entity_handler(local_attribute, null, null, local_attribute_field_change_event_args);
                    };
                    var edit_func = function (e) {
                        if (entity_instance[local_attribute.field_path] == null) {
                            e.preventDefault();
                        } else {
                            attribute_value = entity_instance[local_attribute.field_path].entity_id || entity_instance[local_attribute.field_path];
                            edit_form_context.edit_fun(local_attribute, attribute_value, local_attribute_field_change_event_args);
                        }
                    };

                    if (!(local_attribute.read_only || edit_form_context.form_is_read_only())) {


                        if (!(edit_form_context.state && edit_form_context.state.parent_entity_field_attribute && edit_form_context.state.parent_entity_field_attribute.custom_properties.disable_create_button)) {
                            addButton = <button type="button"
                                                className="x2 edit-controls-add-button add-button dx-button"
                                                onClick={add_func.bind( edit_form_context )}
                                                tabIndex={-1}
                            >
                                <div className='dx-button-content'>
                                    <i className='dx-icon dx-icon-edit-button-addrow fa fa-pencil-square-o'></i>
                                </div>
                            </button>;
                        }

                        editButton = <button style={{ zIndex : "100"    }} type="button"
                                             onClick={edit_func.bind( edit_form_context )}
                                             className="edit-controls-edit-button edit-button dx-button"
                                             tabIndex={-1}
                        >
                            <div className='dx-button-content'>
                                <i className='dx-icon dx-icon-edit-button-addrow fa fa-pencil-square-o'></i>
                            </div>
                        </button>;


                    }
                }
                if (attribute.field_path == "assigned_to_user") {

                    var option_array = attribute.select_options;
                    //verify if selectbox not have default values
                    //91221083
                    var options = [],
                        emptyValue = true,
                        lov = $.parseJSON('{"code": null, "value": ""}');
                    option = <option empty_value={emptyValue} key={lov.entity_id+"_"+attribute.field_path}
                                     value={lov.entity_id}>{lov.caption}</option>;
                    options.push(option);

                    for (var j = 0, l = option_array.length; j <= l; j++) {
                        var option;
                        lov = option_array[j];
                        if (!lov) {
                            continue;
                        }
                        option = <option empty_value={emptyValue} key={lov.entity_id+"_"+attribute.field_path}
                                         value={lov.entity_id}>{lov.caption}</option>;
                        options.push(option);
                    }
                    return (
                        <div style={{"position":"absolute","top":"0px","left":"-9999px","visibility":"hidden"}}>
                            <div className="col-xs-12" id={"id"+ attribute.field_path}>
                                <div style={{"textAlign":"center","marginBottom":"10px"}}>
                                    {R.assign_to_dialog ? R.assign_to_dialog : "Select User to Assign"}
                                </div>
                                <div className="form-group">
                                    <select id="assign-to-user-select" data-empty-value={emptyValue}
                                            className="form-control"
                                            key={"select_" + attribute.field_path}
                                            onChange={edit_form_context.on_field_both.bind(edit_form_context, field_change_event_args)}
                                            defaultValue={attribute_value}
                                    >
                                        {options}
                                    </select>
                                </div>
                            </div>
                        </div>
                    );
                }
                else {
                    if (attribute.lookup) {
                        if (!attribute.did_init_lookup && !attribute.dataSource) {
                            attribute.did_init_lookup = true;
                            edit_form_context.lookup_load(attribute);
                        }

                        return (
                            <div key={attribute.field_path} className='ref_lookup_container'>
                                <EditorDevExtremeLookup
                                    dataSource={attribute.dataSource}
                                    displayExpr="caption"
                                    valueExpr="entity_id"
                                    value={attribute_value|| null }
                                    skip_prop_update_function={(nested_entity_name && (nested_entity_name != ""))}
                                    onChange={edit_form_context.on_field_change.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    onBlur={edit_form_context.on_field_blur.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    add_button={addButton!=''}
                                    edit_button={editButton!=''}
                                    readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                    placeholder={R.start_typing_to_search?R.start_typing_to_search:'Start typing to search...'}
                                />
                                {addButton}
                                {editButton}
                            </div>);
                    }
                    else {
                        return (
                            <div key={attribute.field_path}>
                                <EditorDevExtremeSelectBox
                                    dataSource={ attribute.select_options }
                                    displayExpr="caption"
                                    valueExpr="entity_id"
                                    value={attribute_value||''}
                                    onChange={edit_form_context.on_field_change.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    onBlur={edit_form_context.on_field_blur.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    EditorDevExtremeTagBox={edit_form_context.on_field_blur.bind(edit_form_context, local_attribute_field_change_event_args)}
                                    add_button={addButton!=''}
                                    edit_button={editButton!=''}
                                    readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>
                                {addButton}
                                {editButton}
                            </div>);
                    }
                }
                //{editButton}
            }(field_change_event_args);
        }
    }
    else if (attribute.list_of_values) {
        var list_of_values = attribute.list_of_values;
        if (attribute.is_array) {
            if(attribute.lov_multiselect || attribute.field_path == "roles") {
                var options = [];
                for (var j = 0; j < list_of_values.length; j++) {
                    var lov = list_of_values[j];
                    var option = <option key={lov.code} data-html="true" value={lov.code}>{lov.value}&lt;div  class=&quot;hidedescription&quot;&gt;{$.trim(lov.description)} &lt;/div&gt;

                    </option>;

                    options.push(option);
                }
                input_element =
                    <div data-html="true" className="rolescls">
                        <select id={attribute.field_path}
                                data-attribute={JSON.stringify(attribute)}
                                multiple="multiple"
                                className="form-control rolesmultiple"
                                defaultValue={attribute_value}
                                key={attribute.field_path}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onChange={edit_form_context.on_field_both.bind(edit_form_context, field_change_event_args)}>
                            {options}
                        </select></div>;
            }
            else{
                input_element =
                    <EditorDevExtremeTagBox dataSource={list_of_values}
                                            displayExpr="value"
                                            valueExpr="code"
                                            tagTemplate={edit_form_context.tagTemplate.bind(edit_form_context, attribute)}
                                            values={attribute_value}
                                            key={attribute.field_path}
                                            onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                            onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                            readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
            }

        }
        else {
            if (attribute.lookup) {
                var lookup_datasource = attribute.select_options;
                if (!attribute.did_init_lookup && !attribute.dataSource) {
                    attribute.did_init_lookup = true;
                    lookup_datasource = edit_form_context.lookup_load(attribute);
                    edit_form_context.init_lookup(attribute);
                }
                input_element =   <div key={attribute.field_path} className='ref_lookup_container'>
                    <EditorDevExtremeLookup
                        dataSource={attribute.dataSource}
                        displayExpr="value"
                        valueExpr="code"
                        value={attribute_value||''}
                        skip_prop_update_function={(nested_entity_name && (nested_entity_name != ""))}
                        onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                        onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                        add_button={false}
                        edit_button={false}
                        readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                        placeholder={R.start_typing_to_search?R.start_typing_to_search:'Start typing to search...'}
                    />
                </div>;
            }
            else{
                //verify if selectbox not have default values
                //91221083
                var options = [];
                var emptyValue = false;
                var init_option_is_placeholder = (!attribute.was_clicked_once && edit_form_context.state.is_new_instance && use_cached_attributes && attribute.html_template_placeholder_text);
                var initoption;
                var lov = {code: null, value: ""};
                if(init_option_is_placeholder || attribute.required) {
                    initoption = <option disabled selected key={(lov.code===undefined?j:lov.code)+"_"+attribute.field_path}
                                         value={lov.code}>{((edit_form_context.state.is_new_instance && use_cached_attributes && attribute.html_template_placeholder_text) ? attribute.html_template_placeholder_text : lov.value)}</option>;
                    options.push(initoption);
                }
                else if(!attribute.required){
                    initoption = <option empty_value={emptyValue} key={(lov.code===undefined?j:lov.code)+"_"+attribute.field_path}
                                         value={lov.code}>{lov.value}</option>;
                    options.push(initoption);
                }

                for (var j = 0; j < list_of_values.length; j++) {
                    var option;
                    var lov = list_of_values[j];
                    option = <option empty_value={emptyValue} key={(lov.code===undefined?j:lov.code)+"_"+attribute.field_path}
                                     value={lov.code}>{lov.value}</option>;

                    options.push(option);
                }

                input_element =
                    <select data-empty-value={emptyValue} data-attribute_value={attribute_value}
                            className="form-control"
                            value={attribute_value || ""}
                            key={attribute.field_path}
                            readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                            disabled={(attribute.read_only || edit_form_context.form_is_read_only())}
                            onChange={edit_form_context.on_field_both.bind(edit_form_context, field_change_event_args)}>
                        {options}
                    </select>;
            }
        }
    }
    else if(attribute.autocomplete){

        attribute_value = entity_instance[attribute.field_path];
        input_element = function (local_attribute) {
            var autocomplete_datasource = edit_form_context.autocomplete_load(attribute);


            return (
                <div key={attribute.field_path}>
                    <EditorDevExtremeAutoComplete
                        dataSource={autocomplete_datasource}
                        displayExpr="entity_id"
                        valueExpr="entity_id"
                        value={attribute_value}
                        onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                        onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                        readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                    />
                </div>);
        }(attribute);

    }
    else {
        var type = attribute.attribute_type == null ? attribute.db_type : attribute.attribute_type,
            style={};
        switch (type) {
            case "Image":
                var resizeWidth = null;
                var resizeHeight = null;
                if (attribute.custom_properties) {
                    if (attribute.custom_properties.max_height && attribute.custom_properties.max_width) {
                        resizeWidth = attribute.custom_properties.max_width;
                        resizeHeight = attribute.custom_properties.max_height;
                    }
                }
                input_element =
                    <EditorDevExtremeImageUpload onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                 value={attribute_value}
                                                 readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                                 resizeWidth={resizeWidth}
                                                 resizeHeight={resizeHeight}
                                                 key={attribute.field_path}
                                                 onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                 selectButtonText={R.select_image}/>;
                break;
            case "File":
                if(!edit_form_context.state.file_uploaded && !entity_instance["file_name"]) {
                    input_element = <div >
                        <button type="button"
                                className="btn-gd btn btn-default"
                                onClick={edit_form_context.google_drive_api.bind(edit_form_context, attribute)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}><img
                            style={{"height" : "20px", "marginRight": "5px"}}
                            src="./assets/img/Logo_of_Google_Drive.png"/>{R.choose_from_drive}</button>
                        <EditorFileUpload
                            onFileNameChange={edit_form_context.on_file_name_change.bind(edit_form_context, attribute)}
                            onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                            fileName={entity_instance['file_name']}
                            value={entity_instance._id}
                            isNewInstance={edit_form_context.state.is_new_instance}
                            resetFileUpload={edit_form_context.state.resetFileUpload}
                            key={attribute.field_path}
                            uploadFiles_array={ edit_form_context.uploadFiles_array }
                            readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/></div>;
                }
                else if(edit_form_context.state.file_uploaded){
                    input_element = <div style={{"textDecoration ": "underline","paddingTop": "7px"}}
                                         key={attribute.field_path}
                                         onClick={edit_form_context.remove_file.bind(edit_form_context, attribute)}
                    >
                        <i className="fa fa-times" aria-hidden="true">&nbsp;</i> {R.cs_remove_file || "Remove"}
                    </div>;
                }
                else{
                    var downloadUrl = null;
                    if(edit_form_context.props._id) {
                        if(!edit_form_context.state.downloadUrl && !edit_form_context.downloadFlag  ){
                            downloadUrl = edit_form_context.download_file(edit_form_context.props._id);
                            if(downloadUrl){ edit_form_context.downloadFlag = false;}
                        }
                    }
                    downloadUrl = downloadUrl || edit_form_context.state.downloadUrl;
                    input_element = [];

                    var removebutton = <div style={{"textDecoration ": "underline","paddingTop": "7px"}}
                                            key={attribute.field_path}
                                            onClick={edit_form_context.remove_file.bind(edit_form_context, attribute)}
                    >
                        <i className="fa fa-times" aria-hidden="true">&nbsp;</i> {R.cs_remove_file || "Remove"}
                    </div>;
                    var download_button;
                    if(!edit_form_context.downloadFlag){


                        download_button = <a
                            key={attribute.field_path+"download"}
                            href={downloadUrl}
                        >
                            <i className="fa fa-download" aria-hidden="true">&nbsp;</i> {R.download}
                        </a>;
                    }else{
                    }
                    input_element.push(removebutton);
                }
                break;
            case "Html":
                input_element =
                    <EditorHtml onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                key={attribute.field_path}
                                value={attribute_value||''}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                break;
            case "YesNo":
                if(attribute.required || attribute.read_only )
                {
                    input_element =
                        <EditorDevExtremeSwitchYesNo onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                     key={attribute.field_path}
                                                     value={attribute_value}
                                                     onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                     readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                }
                else{
                    id = attribute.caption.replace(/ /g,'');
                    input_element = <div id={id} key={attribute.field_path}>
                        <button id={id+"1"}
                                type="button"
                                className="btn btn-primary"
                                onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}>Yes</button>
                        <button id={id+"2"}
                                type="button"
                                className="btn btn-primary"
                                onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}>No</button>
                        <button id={id+"3"}
                                type="button"
                                className="btn btn-primary"
                                onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}>N/A</button>
                    </div>;
                }
                break;
            case "Boolean":
                if (attribute.required || attribute.read_only) {
                    input_element = <div><label>
                        <input className="form-control checkbox-slider colored-blue"
                               type="checkbox"
                               key={attribute.field_path}
                               onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                               checked={attribute_value}
                               readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                               onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                               disabled={(attribute.read_only || edit_form_context.form_is_read_only())}/>
                        <span className="text">&nbsp;</span>
                    </label></div>;
                }
                else {
                    var id = attribute.caption;
                    id = id.replace(/ /g, '');
                    input_element = <div id={id} key={attribute.field_path}>
                        <button id={id+"1"}
                                type="button"
                                className="btn btn-primary"
                                onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}>True
                        </button>
                        <button id={id+"2"}
                                type="button"
                                className="btn btn-primary"
                                onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}>False
                        </button>
                        <button id={id+"3"}
                                type="button"
                                className="btn btn-primary"
                                onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                checked={attribute_value}
                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                disabled={(attribute.read_only || edit_form_context.form_is_read_only())}>N/A
                        </button>
                    </div>;
                }

                break;
            case "CheckBox":
                input_element =
                    <input className=" form-checkbox" type="checkbox"
                           onClick={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                           onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                           key={attribute.field_path}
                           value={attribute_value}
                           readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                           disabled={(attribute.read_only || edit_form_context.form_is_read_only())}/>;

                break;
            case "MaskedTextBox":
                var mask_data = attribute.mask_data || {};
                var mask_rules = mask_data.mask_rules;
                if (mask_rules) {
                    for (var field_name in mask_rules) {
                        mask_rules[field_name] = try_parse_regexp(mask_rules[field_name]);
                    }
                }
                if(attribute_value == null)
                {
                    attribute_value ="";
                }
                input_element =
                    <EditorDevExtremeMaskedTextBox onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                   key={attribute.field_path}
                                                   value={attribute_value||''}
                                                   readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                                   mask={mask_data.mask}
                                                   maskRules={mask_rules}
                                                   maskChar={mask_data.mask_char || "_"}
                                                   useMaskedValue={mask_data.use_masked_value}
                                                   onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                   rtlEnabled={attribute.right_to_left || false}/>;
                break;
            case "Recurrent":
                input_element =
                    <EditorRecurrenceInput onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                           value={attribute_value}
                                           readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                           key={attribute.field_path}
                                           onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                    />;
                break;
            case "MultiLineTextBox":
                var height = undefined;
                if (attribute.custom_properties && attribute.custom_properties.lines_displayed) {
                    // http://stackoverflow.com/questions/2034544/textareas-rows-and-cols-attribute-in-css
                    height = (1 + parseFloat(attribute.custom_properties.lines_displayed) * 1.42857).toString() + 'em';
                }
                input_element =
                    <EditorDevExtremeMultiLineTextBox onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                      value={attribute_value}
                                                      height={height}
                                                      readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                                      key={attribute.field_path}
                                                      onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                    />;
                break;
            case "Date":
                input_element =
                    <EditorDevExtremeDatePicker onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                key={attribute.field_path}
                                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                value={attribute_value}
                                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                break;
            case "DateTime":
                input_element =
                    <EditorDevExtremeDateTimePicker onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                    value={attribute_value}
                                                    onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                    key={attribute.field_path}
                                                    readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                break;
            case "DateTimeLocal":
                input_element =
                    <EditorDevExtremeDateTimePicker onChange={edit_form_context.on_field_change.bind(edit_form_context, attribute)}
                                                    value={attribute_value}
                                                    is_mobile={edit_form_context.props.is_mobile}
                                                    onBlur={edit_form_context.on_field_blur.bind(edit_form_context, attribute)}
                                                    key={attribute.field_path}
                                                    readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                break;
            case "Time":
                input_element =
                    <EditorDevExtremeTimePicker onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                                key={attribute.field_path}
                                                value={attribute_value}
                                                onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                                readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}/>;
                break;
            case "Section":
                input_element = <div className="form-title form_section_title"
                                     key={"QK-section-"+attribute.caption}>{attribute.caption}</div>;
                omit_caption = true;
                if(attribute_options) {
                    attribute_options.currentSection = attribute.caption;
                }
                break;
            case "currency":
                if (attribute_caption_image) {
                    style = {
                        "backgroundImage": "url(" + attribute_caption_image + ")",
                        "backgroundRepeat": "no-repeat",
                        "backgroundSize": "34px auto",
                        "textIndent": "28px"
                    };
                }
                try{
                    attribute_value = attribute_value.toFixed(2);
                }catch(e){}
                input_element = <input type="text" className="form-control" key={attribute.field_path}
                                       value={attribute_value||''}
                                       onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                       onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                       readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                       style={style}
                />;
                break;
            default:
                if (attribute_caption_image) {
                    style = {
                        "backgroundImage": "url(" + attribute_caption_image + ")",
                        "backgroundRepeat": "no-repeat",
                        "backgroundSize": "34px auto",
                        "textIndent": "28px"
                    };
                }
                input_element = <input type="text" className="form-control" key={attribute.field_path} placeholder={(edit_form_context.state.is_new_instance && use_cached_attributes && attribute.html_template_placeholder_text) ? attribute.html_template_placeholder_text : ""}
                                       value={attribute_value||''}
                                       onBlur={edit_form_context.on_field_blur.bind(edit_form_context, field_change_event_args)}
                                       onChange={edit_form_context.on_field_change.bind(edit_form_context, field_change_event_args)}
                                       readOnly={(attribute.read_only || edit_form_context.form_is_read_only())}
                                       style={style}
                />;
                break;
        }
    }
    var info_id = "info_" + attribute.field_path.replace(/:/g, '-');
    if ((attribute.field_path=='assigned_to_user') || !attribute_options){
        if(attribute_options) {
            attribute_options.row_array = null;
            attribute_options.row_name = null;
            attribute_options.prev_same_row = null;
        }
        if(use_cached_attributes) {
            //var info_id_desc = info_id + "_desc";
            if(attribute.description && (attribute.description != attribute.field_path) && !(attribute.is_nested_entity && attribute.data_is_nested)) { //skip descriptions which are unset or for controls which contain entire entities
                attribute.cached_rendered_component = (
                    <div className="tooltip_popup tooltip_popup_fade" data-title={attribute.description}>
                        {input_element}
                        <span id={info_id}> </span>
                    </div>
                );
            }
            else {
                attribute.cached_rendered_component = (
                    <span>
                        {input_element}
                        <span id={info_id}> </span>
                    </span>
                );
            }
            return attribute.cached_rendered_component;
        }
        return input_element;
    }
    else {
        var caption_label = "";
        if (!omit_caption) {
            caption_label = <label  style={{'verticalAlign': ' bottom'}} id={info_id}>{attribute.caption}</label>;
        }
        if (!same_row) {
            var ret_array = [];
            if (attribute_options && attribute_options.row_name) {
                input_div = <div  className="row"
                                  key={"QK-input-wrapper-row-name-"+attribute_options.row_name.toString()+"-"+info_id}>
                    {attribute_options.row_array}
                </div>;
                ret_array.push(input_div);
                attribute_options.row_array = null;
                attribute_options.row_name = null;
                attribute_options.prev_same_row = null;
            }

            if(attribute.required==true && attribute.read_only == false) {
                input_div = <div className="row" key={"QK-input-wrapper-"+info_id}>
                    <div className="4 col-sm-12">
                        <div className="form-group requiredcls forminput" >
                            {caption_label}
                            {input_element}
                        </div>
                    </div>
                </div>;
            }
            else{
                input_div = <div className="row" key={"QK-input-wrapper-"+info_id}>
                    <div className="3 col-sm-12">
                        <div className="form-group forminput" >
                            {caption_label}
                            {caption_label==''? <span style={{display : 'block'}}>
                                        {input_element}
                                    </span> : input_element}
                        </div>
                    </div>
                </div>;
            }
            ret_array.push(input_div);
            info_id += "_desc";
            ret_array.push(<div className="rowses" id={info_id}
                                  key={"QK-input-wrapper-info-id"+info_id}>{attribute.description}</div>);
            if(use_cached_attributes) {
                attribute.cached_rendered_component = ret_array;
            }
            return ret_array;
        }
        else {
            var ret_val;
            var rowsCount = 0;
            var modulus = 0;
            if(attribute_options) {
                if (attribute_options.prev_same_row != null && attribute_options.prev_same_row != same_row) {
                    input_div = <div className="row"
                                     key={"QK-input-wrapper-row-name-"+attribute_options.row_name.toString()+"-"+info_id}>{attribute_options.row_array}</div>;
                    if (String(attribute_options.prev_same_row).match(/^entity_record_fields_\d$/)) { //we make an exception for record fields to go to a separate object.
                        attribute_options.record_fields_box.push(input_div);
                    } else {
                        ret_val = input_div;
                    }
                    attribute_options.row_array = [];
                    attribute_options.row_name = null;
                    attribute_options.prev_same_row = null;
                }
                //same_row
                if (attribute_options.same_row_count) {
                    rowsCount = (12 / attribute_options.same_row_count | 0);
                    modulus = 12 % attribute_options.same_row_count;
                }
                if (modulus > 0) {
                    if (!edit_form_context.current_row) {
                        edit_form_context.non_string = modulus - attribute_options.type_string_row;
                        if (edit_form_context.non_string <= 0) {
                            edit_form_context.remanining_spaces_string = modulus;
                            edit_form_context.remanining_spaces_non_string = 0;

                        }
                        else {
                            edit_form_context.remanining_spaces_string = attribute_options.type_string_row;
                            edit_form_context.remanining_spaces_non_string = edit_form_context.non_string;
                        }
                        edit_form_context.current_row = attribute_options.same_row_count;
                        if (attribute.attribute_type != "String" && attribute.attribute_type != "zipcode_field" && attribute.attribute_type != "DateTime" && attribute.attribute_type != "DateTimeLocal") {
                            if (edit_form_context.remanining_spaces_non_string > 0) {
                                rowsCount++;
                                edit_form_context.remanining_spaces_non_string--;

                            }
                        }
                        else {
                            if (edit_form_context.remanining_spaces_string > 0) {
                                rowsCount++;
                                edit_form_context.remanining_spaces_string--;

                            }
                        }
                        edit_form_context.current_row--;

                    }
                    else {
                        if (attribute.attribute_type != "DateTime" && attribute.attribute_type != "DateTimeLocal" && attribute.attribute_type != "String" && attribute.attribute_type != "zipcode_field") {
                            if (edit_form_context.remanining_spaces_non_string > 0) {
                                rowsCount++;
                                edit_form_context.remanining_spaces_non_string--;

                            }
                        }
                        else {
                            if (edit_form_context.remanining_spaces_string > 0) {
                                rowsCount++;
                                edit_form_context.remanining_spaces_string--;

                            }
                        }
                        edit_form_context.current_row--;
                    }

                }
                attribute_options.prev_same_row = same_row;
            }
            if(rowsCount == 0) {
                rowsCount = 12
            }
            if(attribute.required==true && attribute.read_only == false) {
                input_div =
                    <div className={"a3 col-sm-" + rowsCount} key={"QK-row-array-"+info_id}>
                        <div className="form-group requiredcls forminput" >
                            <div className="pull-down"  >
                                {caption_label}
                                {input_element}
                            </div>
                        </div>
                    </div>;
            }
            else if(String(attribute.same_row).match(/^entity_record_fields_\d$/)){
                input_div = input_element;
            }
            else{
                if(type == "Section"){
                    input_div =
                        <div className={"a1 col-sm-" + rowsCount} key={"QK-row-array-"+info_id} >
                            <div className="form-group forminput" style={{position:"relative"}} >
                                {caption_label}
                                {input_element}
                            </div>
                        </div>;
                }else{
                    input_div =
                        <div className={"a2 col-sm-" + rowsCount} key={"QK-row-array-"+info_id}>
                            <div className="form-group forminput" >
                                <div className="pull-down" >
                                    {caption_label}
                                    {input_element}
                                </div>
                            </div>
                        </div>;

                }

            }
            info_id += "_desc";
            if(attribute_options) {
                attribute_options.row_array.push(input_div);
                attribute_options.row_array.push(<div className="prevent_margin" id={info_id}
                                                      key={"QK-row-array-info-id"+info_id}>{attribute.description}</div>);
            }
            else if(!ret_val) {
                ret_val = input_div;
            }
            if(use_cached_attributes) {
                attribute.cached_rendered_component = ret_val;
            }
            return ret_val;
        }
    }
}

function nested_entity_row_popup_function(context, data_row, is_new, app_object, entity_attributes, nested_list_entities, parent_entity_array, parent_entity_field_attribute, parent_react_entity, base_field_path) {
    context.debugLog('init_grid nested_popup_func', true);
    var constructed_app_object = {};
    constructed_app_object.code = (app_object || context.state.app_object).edit_app_object;
    constructed_app_object.type = "edit_form";
    constructed_app_object._id = data_row._id;
    constructed_app_object = $.extend({}, (app_object || context.state.app_object), constructed_app_object);
    var data = {};
    data.app_object = constructed_app_object;
    data.entity_attributes = entity_attributes || context.state.entity_attributes;
    data.nested_list_entities = nested_list_entities || context.props.nested_list_entities;
    data.workflow_states = constructed_app_object.workflow_states;
    data.entity_instance = $.extend(true, {}, data_row);
    data.parent_entity_array = parent_entity_array || context.props.parent_entity_array;
    data.parent_entity_field_attribute = parent_entity_field_attribute || context.props.parent_entity_field_attribute;
    data.parent_react_entity = parent_react_entity || context.props.parent_react_entity;
    data.is_new_instance = is_new;
    data.is_new_and_save = true;
    data.base_field_path = base_field_path;
    context.debugLog('init_grid nested_popup_func', false);
    return (context.nested_entity_handler || context.props.nested_entity_handler)(data);
}

function is_valid_month_input(month_control_string) {
    if(!month_control_string || (month_control_string == "")) {
        return false;
    }
    var year = month_control_string.substring(0, 4);
    if(isNaN(year)) {
        return false;
    }
    year = Number(year);
    return ((year > 1901) && (year < 2099));
}
