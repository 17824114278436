'use strict';
/**
 * ========================================================
 * Description: Contains helper methods, mostly for communicating
 *              with server regarding entity requests
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev  Date        Author              Task        Description
 * ========================================================
 * 1    ?           ?                   ?           Created
 * 2    02/17/2016  andrew.gay          80440094    $not now stores a string regex and $options will be attached to regex and deleted before query
 * 3    02/17/2016  andrew.gay          80440094    Reverted changes
 * 4    03/09/2016  andrew.gay          82679450    Added two functions for converting whole data with dates as strings and one string to a valid data, will expand on this and put in genral_utils (shared) to combine with tristans original works
 * 5    03/10/2016  andrew.gay          82688877    Added pre_proccessing to fix dates to range from start of day to end of day
 * 6    03/15/2016  andrew.gay          83919632    Added code to switch date to string before sending out the filter
 * 7    03/21/2016  andrew.gay          84813463    Added a fix date function to convert a date object to a date string
 * 8    03/22/2016  AMcDaniel           84922189    Fixed issue with multiple date filters (connected by "and") causing an error.
 * 9    03/24/2016  andrew.gay          790562      Removed unused function
 * 10   03/25/2016  AMcDaniel           85252548    Added app_object_code parameter to invoke_method
 * 11   03/31/2016  andrew.gay          84813463    Added fix_request_data converts date to string date
 * 12   06/02/2016  AMcDaniel           94251814    Added org_id parameter to http call for invoke_method, which is parsed from the request string.
 * 13   07/06/2016  Victor H            98304215    Date time data is no longer being set to 00:00:00 UTC when it's 00:00:00 local. Doing a direct UTC transform instead.
 * 14   07/12/2016  Victor H            98304215    Previous commit now only affects DateTime and Time fields. Date fields continue to be sent as 00:00:00 UTC
 * 15   07/13/2016  Victor H            Bug         Changed fix response data to use the attributes to identify Date fields rather than their format.
 * 16   07/15/2016  andrew.gay          101063705   No longer converting empty string to date.
 * 17   08/03/2016  AMcDaniel           100948453   Moved some functions to new file shared_model_utils.js
 * 18   09/08/2016  AMcDaniel           Bug         Fixed bug that occurs when fix_request_data gets called twice
 * 19   09/28/2016  Victor Hernandez    111681478   Fixed a bug causing filters to be incorrectly built under certain circumstances.
 * ========================================================
 **/

function server_error_action(error, callback){
    error = error || {};
    var status = parseInt(error.status);
    var message= error.message;
    var error_JSON=error.responseJSON || {};
    var context = null;
    switch (status){
        case 500 :
            if(error_JSON.logged_in==false){
                context= new MainComponent;
                console.log(error_JSON);
                context.logout_invoke_error(message);
            } else {
                if ('function' === typeof callback)
                    callback(error_JSON);
                else{
                    context = new MainComponent;
                    context.invoke_error(error_JSON);
                }
            }
            break;
        case 502:
            var context= new MainComponent;
            console.log(error_JSON);
            //context.logout_invoke_error(message);
            break;
        case 504:
            var context= new MainComponent;
            console.log(error_JSON);
            //context.logout_invoke_error(message);
            break;

    }
}

function invoke_method(entity, method, data, success, error, complete, app_object_code) {
    var request = {};
    request.entity = entity;
    request.method = method;
    request.app_object_code = app_object_code;
    var org_id = get_parameter_by_name("org_id");
    if(org_id && (org_id != "")) {
        request.org_id = org_id;
    }
    request.data = data;
    return do_authenticated_http_call({
        method: "POST",
        url: "/api/entity/invoke_method",
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify(request),
        success:success,
        error: function(err) {
            server_error_action(err, error);
        },
        complete: complete
    });
}
/*http://stackoverflow.com/questions/2144386/javascript-delete-cookie*/
function log_error(error) {
    var request = {};
    request.entity = "error";
    request.method = "log_error";
    request.data = {error:error};
    do_authenticated_http_call({
        method: "POST",
        url: "/api/entity/invoke_method",
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify(request)
    });
}

function get_app_object(app_object_code, extra, success, error, complete) {
    if (!extra) {
        extra = {};
    }
    extra.app_object_code = app_object_code;
    invoke_method("app_object", "get_by_code", extra, function(data) { success(data.data); }, error, complete);
}

function make_conditions_from_devextreme_filter(filter_arr) {
    //format1: [3]
    //[3] == att.field_path, "contains", filter_box.value
    //alternates for "contains":  "=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains"
    //format2: [[3], "and", [3], "or", [3], ...]
    var ret_val;
    ret_val = filter_arr[0];
    if(typeof ret_val == "string" || typeof ret_val == "function") {
        ret_val = parse_condition_obj(filter_arr);
    } else if((Object.prototype.toString.call(ret_val) === '[object Array]') || (Object.prototype.toString.call(ret_val) === '[object Object]')) { //an array of conditions joined by and/or
        ret_val = make_conditions_from_devextreme_filter(filter_arr[0]);
        for (var i = 1; i < filter_arr.length; i++) {
            var condition = filter_arr[i];
            if (condition) {
                if (Object.prototype.toString.call(condition) === '[object Array]') {
                    //implies AND
                    condition = parse_condition_obj(condition);
                    ret_val = add_and_condition(ret_val, condition);
                }
                else if (typeof condition == "string") {
                    if (condition == "and") {
                        i++;
                        var condition_obj = filter_arr[i];
                        if ((typeof condition_obj == "object") && (Array.isArray(condition_obj)) && Array.isArray(condition_obj[0])) {
                            condition = make_conditions_from_devextreme_filter(condition_obj);
                        }
                        else
                        {
                            condition = parse_condition_obj(condition_obj);
                        }
                        ret_val = add_and_condition(ret_val, condition);
                    }
                    else if (condition == "or") {
                        i++;
                        var condition_obj = filter_arr[i];
                        if ((typeof condition_obj == "object") && (Array.isArray(condition_obj)) && condition_obj.length == 3 && Array.isArray(condition_obj[0])) {
                            condition = make_conditions_from_devextreme_filter(condition_obj);
                        }
                        else
                        {
                            condition = parse_condition_obj(condition_obj);
                        }
                        ret_val = add_or_condition(ret_val, condition);
                    }
                }
            }
        }
    }
    return ret_val;
}

function make_sorting_conditions_from_devextreme_sort(sort_arr) {
    var ret_val = {};

    for(var i = 0; i < sort_arr.length; i++) { //usually only 1
        var field_path = sort_arr[i].selector;
        var descending = sort_arr[i].desc;
        ret_val[field_path] = (descending)? -1 : 1;
    }

    return ret_val;
}

function devextreme_sorting_conditions_changed(array_a, array_b) {
    var a_is_valid = (array_a && Array.isArray(array_a));
    var b_is_valid = (array_b && Array.isArray(array_b));
    if(a_is_valid != b_is_valid) {
        return true;
    }
    if(!a_is_valid) {
        return false;
    }
    if(array_a.length != array_b.length) {
        return true;
    }
    for(var i = 0; i < array_a.length; i++) {
        var string_a = array_a[i];
        var string_b = array_b[i];
        var a_desc = false;
        var b_desc = false;
        if(string_a && (typeof string_a == "object")) {
            a_desc = string_a.desc;
            string_a = string_a.selector;
        }
        if(string_b && (typeof string_b == "object")) {
            b_desc = string_b.desc;
            string_b = string_b.selector;
        }
        if((string_a != string_b) || (a_desc != b_desc)) {
            return true;
        }
    }
    return false;
}

var filter_conditions_mongo_equivalent_lookup = {
    "=":"$eq",
    "<>": "$ne",
    ">": "$gt",
    ">=": "$gte",
    "<": "$lt",
    "<=": "$lte"//,
    //"contains": "$in",
    //"notcontains": "$nin",
    //"startswith": "",
    //"endswith": ""
};

function try_parse_regexp(value) {
    if(value && typeof value == "string") {
        var m = value.match(/\/(.*)\/(.*)?/);
        return new RegExp(m[1], m[2] || "");
    }
    return value;
}

function parse_condition_obj(condition_obj) {
    //[3] == att.field_path, "contains", filter_box.value
    //alternates for "contains":  "=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains"
    if (!condition_obj || (typeof condition_obj != "object") || (!Array.isArray(condition_obj)) || condition_obj.length !== 3) {
        return null;
    }
    var field_path = condition_obj[0];
    if (typeof field_path == "function")
    {
        field_path = field_path();
    }
    var operator = condition_obj[1];
    var comparison_value = condition_obj[2];

    var sub_doc = {};
    switch (operator) {
        /*case "=":
        {
            sub_doc[filter_conditions_mongo_equivalent_lookup[operator]] = comparison_value;
            break;
        }
        case "<>":
        {

            break;
        }
        case ">":
        {

            break;
        }
        case ">=":
        {

            break;
        }
        case "<":
        {

            break;
        }
        case "<=":
        {

            break;
        }*/
        case "startswith":
        {
            sub_doc["$regex"] = "^" + comparison_value.replace(/ /g, '\\s');
            sub_doc["$options"] = "i";
            break;
        }

        case "endswith":
        {
            sub_doc["$regex"] = comparison_value.replace(/ /g, '\\s') + "$";
            sub_doc["$options"] = "i";
            break;
        }
        case "contains":
        {
            sub_doc["$regex"] = comparison_value.replace(/ /g, '\\s');
            sub_doc["$options"] = "i";
            break;
        }
        case "notcontains":
        {
            sub_doc["$regex"] = comparison_value.replace(/ /g, '\\s');
            sub_doc["$options"] = "i";
            sub_doc = {
                "$not": sub_doc
            };
            break;
        }
        case "exactly":
        {
            sub_doc = comparison_value.replace(/ /g, '\\s');
            break;
        }
        default:
        {
            if(comparison_value && (typeof comparison_value == "object") && (Object.prototype.toString.call(comparison_value) === '[object Date]')) { //is a Date object
                //stringify it now to make sure it is stringified properly
                var m_date = new moment(comparison_value);
                comparison_value = m_date.format().slice(0, -6);
                comparison_value = comparison_value + ".000Z";
            }
            sub_doc[filter_conditions_mongo_equivalent_lookup[operator]] = comparison_value;
            break;
        }
    }

    var ret_val = {};
    ret_val[field_path] = sub_doc;
    return ret_val;
}

function add_and_condition(ret_val, condition) {
    if(!condition) {
        return;
    }
    if(!ret_val["$and"]) {
        var old_ret = ret_val;
        ret_val = {};
        ret_val["$and"] = [old_ret];
    }
    ret_val["$and"].push(condition);
    return ret_val;
}

function add_or_condition(ret_val, condition) {
    if(!condition) {
        return;
    }
    if(!ret_val["$or"]) {
        var old_ret = ret_val;
        ret_val = {};
        ret_val["$or"] = [old_ret];
    }
    ret_val["$or"].push(condition);
    return ret_val;
}

function format_string_date(date) {
    if(typeof date == 'string') {
        var year = date.slice(0,4);
        var month = date.slice(5,7) - 1;
        var day = date.slice(8,10);
        date = new Date(year,month,day,0,0,0,0);
    }
    return date;
}

function fix_request_data(data, field_paths) {
    for(var x in data)
    {
        //recursively call on objects
        if (typeof data[x] == 'object' && !(data[x] instanceof Date)) {
            data[x] = fix_request_data(data[x], field_paths);
        }
        else
        {
            //if data is date, convert to date string object
            if (field_paths && field_paths[x] && data[x] && (typeof data[x] != "string")) {
                if(field_paths[x].attribute_type == "Date") {
                    data[x] = new Date(Date.UTC(data[x].getFullYear(),data[x].getMonth(),data[x].getDate(),0,0,0,0)).toISOString();
                } else if (field_paths[x].attribute_type == "DateTime") {
                    data[x] = new Date(Date.UTC(data[x].getFullYear(),data[x].getMonth(),data[x].getDate(),data[x].getHours(),data[x].getMinutes(),data[x].getSeconds(),data[x].getMilliseconds())).toISOString();
                }
            } /*else{
                if (data[x] instanceof Date && field_paths[x].attribute_type == "Date"){
                    data[x] = new Date(data[x]).toISOString();
                }
            }*/
        }
    }

    return data;
}

function fix_response_data(data, field_paths){
    if (!field_paths && data && data.data && data.data.entity_attributes && data.data.entity_attributes.field_paths){
        field_paths = data.data.entity_attributes.field_paths;
    }
    for(var x in data)
    {
        //recursively call on objects
        if (typeof data[x] == 'object') {
            if (field_paths && field_paths[x] && data[x] && data[x].value){
                if(field_paths[x].attribute_type == "Date") {
                    data[x].value = new Date(data[x].value.slice(0,4),data[x].value.slice(5,7),data[x].value.slice(8,10),0,0,0,0);
                } else if (field_paths[x].attribute_type == "DateTime") {
                    data[x].value = new Date(data[x].value.slice(0,4),data[x].value.slice(5,7),data[x].value.slice(8,10),
                        data[x].value.slice(11,13),data[x].value.slice(14,16),data[x].value.slice(17,20));
                }

            }
            else {
                data[x] = fix_response_data(data[x], field_paths);
            }
        } else if (data[x] && field_paths && field_paths[x]){
            if(field_paths[x].attribute_type == "Date") {
                data[x] = new Date(data[x].slice(0,4),data[x].slice(5,7) - 1,data[x].slice(8,10),0,0,0,0);
            } else if (field_paths[x].attribute_type == "DateTime") {
                data[x] = new Date(data[x].slice(0,4),data[x].slice(5,7) - 1,data[x].slice(8,10),
                    data[x].slice(11,13),data[x].slice(14,16),data[x].slice(17,20));
            }

        }
    }

    return data;
}

function get_parameter(name) {
    name = name || '';
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("&" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

