"use strict";
/**
 ==================================================================================
 Description:       desc
 Creation Date:     7/13/2016
 Author:            SebasWebcreek
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author           Task                Description
 ==================================================================================
 1      07/13/2016  SebasWebcreek          TaskNumber          Created
 2      07/27/2016  Victor Hernandez Bug                 Fixed initial zoom and position. Additionally, the map object now uses server provided GoogleDev keys.
 ==================================================================================
 */
var temp = 'AIzaSyBySfAAwes8wzncm5Ym1wBwBO-uv4pqnT8';


var EditorDevExtremeMapView = React.createClass({
    getInitialState: function () {
        var context = this;
        var get_keys = function() {
            var promise = new Promise(function(resolve, reject){
                var request ={};
                request.code="google_drive_public_keys";
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/settings/get_system_setting",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: resolve,
                    error: reject
                });
            });
            return promise;
        };

        var store_keys = function (data) {
            localStorage.setItem("developerKey", data.data.google_drive_developer_key);
            localStorage.setItem("ClientId", data.data.google_drive_browser_key);
            if (context.isMounted){
                context.init();
            }
        };

        get_keys()
            .then(store_keys);
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (this.state.element){
            try{
                var element = this.state.element.dxMap('instance');
                if (nextProps.markers != this.state.markers) {
                    this.state.markers = nextProps.markers;
                    for (var i = 0, u = this.state.markers.length; i<u;i++){
                        this.state.markers[i].onClick = this.bind_tooltips;
                    }
                    element.option("markers", this.state.markers);
                } else {

                }

            } catch(e){}
        }
    },
    componentDidMount: function(){
        this.isMounted = true;
        if (localStorage.getItem("developerKey")){
            this.init();
        }
    },
    init: function () {
        if (!this.isInit){
            this.isInit = true;
            var context = this;
            var mapMarkers = [];
            var markerprops = context.props.markers;
            if(!markerprops) {
                markerprops = mapMarkers;
            }
            var hasMarkers = markerprops.length;
            context.state.markers = context.props.markers;
            this.state.dataSource = this.props.dataSource;
            var config = {
                readOnly: this.props.readOnly,
                dataSource: this.props.dataSource,
                markers:markerprops,
                controls:true,
                zoom:hasMarkers?20:4,
                width:'100%',
                height:this.props.getHeight,
                key:{google:localStorage.getItem("developerKey")}
            };
            if (!hasMarkers){
                config.center = [36.2460287,-98.7244602]; //United States
            }
            this.state.element = $(this.domElement).dxMap(config);
            window.addEventListener("resize", this.updateDimensions);
        }
    },
    bind_tooltips: function(){
        var markers = $('.map-tooltip'), context = this;
        if (markers && context.props.markers && markers.length) {
            markers.off('click');
            markers.on('click', context.props.onItemClick);
            if(!this.markers_count || this.markers_count != markers.length){
                this.markers_count = markers.length;
                setTimeout(function(){context.bind_tooltips();}, 100);
            } else{
                this.markers_count = 0;
            }
        }
        else{
            setTimeout(function(){context.bind_tooltips();}, 100);
        }
    },
    componentWillUnmount: function(){
        $('.map-tooltip').off('click');
        window.removeEventListener("resize", this.updateDimensions);
    },
    updateDimensions: function(){
        this.state.element.dxMap('instance').repaint();
    },
    render: function () {
        var context = this;
        var className = "";
        if (this.props.add_button) {
            className = "add-button-margin";
        }
        return <input class="form-control"/>,<div className={className} ref={function(ref){context.domElement = ref}}/>;
    }
});