/**
 * ========================================================
 * Description: Modal component
 * Creation Date: 02/04/2016
 * Author: Vasil Galinovsky
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1	02/04/2016	Vasil Galinovsky	Edit form	Created
 * 2   02/11/2016  Sebastian Mansfield          Wait until modal is loaded to show itself
 * 3    07/14/16    JEscudero     100073126    Made external scrollbar disappear when modal opens, and appear when it closes.
 * ========================================================
 **/
var ModalComponent = React.createClass({
    componentWillMount: function () {
      $('html').css('overflow-y', 'hidden');
    },
    componentDidMount: function(){
        $(ReactDOM.findDOMNode(this)).on('show.bs.modal', function (e) {
            $(this).children('.modal-dialog').velocity('transition.fadeIn');
        });
        $(ReactDOM.findDOMNode(this)).modal('show');
        $(ReactDOM.findDOMNode(this)).on('hide.bs.modal', this.props.handleHideModal);
    },
    componentWillUnmount: function()
    {
        $('html').css('overflow-y', 'visible');
        $(ReactDOM.findDOMNode(this)).off('hide.bs.modal', this.props.handleHideModal);
        $(ReactDOM.findDOMNode(this)).modal('hide');
    },
    render:function()
    {
        return  <div id="modal_form" className="modal">
            <div className="modal-dialog modal-edit-form" >
                <div className="modal-content">
                        {
                            this.props.children
                        }
                </div>
            </div>
        </div>
    }
});

