/**
 * ========================================================
 * Description: A web control for a simple select box (drop-down list)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1    02/10/2016   Vasil Galinovsky   Tag Box and Lookup  Adding item on enter
 * 2    03/14/2016   andrew.gay    81380375    Fixed bug, now prevents client from sending an object with entity_id set to null
 * 3    08/14/2016   Josue N.      86972915    refresh select box WHEN element lost focus
 * ========================================================
 **/
var EditorDevExtremeSelectBox = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            this.state.element.dxSelectBox('instance').option("value", this.state.value);
        }
    },

    componentDidMount: function () {
        var context = this;
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxSelectBox({
            readOnly: this.props.readOnly,
            value: this.state.value,
            displayExpr: this.props.displayExpr,
            valueExpr: this.props.valueExpr,
            dataSource: this.props.dataSource,
            searchEnabled: true,
            showClearButton: true,
            showDropButton:true,
            onSelectionChanged: function (data){
                context.state.element.dxSelectBox('instance').repaint();
                context.props.onBlur();
            },
            onValueChanged: function (data) {
                context.state.value = data.value;
                var dataValue = {};
                if (typeof data.selectedItem != "undefined" && data.selectedItem != null) {
                    dataValue[context.props.displayExpr] = data.selectedItem[context.props.displayExpr];
                }
                if(context.state.value == null) {
                    context.props.onChange(null);
                } else {
                    dataValue[context.props.valueExpr] = context.state.value;
                    context.props.onChange(dataValue);
                }
            }
            /*onFocusOut: function (e) {
                context.state.element.dxSelectBox('instance').repaint();
                 context.props.onBlur();
            }*/
        });
        // https://www.devexpress.com/Support/Center/Question/Details/T283536
       // this.state.element.dxSelectBox("instance").find(".dx-state-focused")

        this.state.element.dxSelectBox("instance").registerKeyHandler("enter", function(e) {
            e.stopPropagation();
            if (this.option("fieldEditEnabled") && !this._$list.find(".dx-state-focused").length) {
                e.preventDefault();
                this._completeSelection()
            }
            else {
                if (this.option("opened"))
                {

                    e.preventDefault();
                    this._selectFirstItem();
                    this._completeSelection();
                }else {

                    this.option("opened") && e.preventDefault();
                    this._keyboardProcessor._childProcessors[0].process(e)
                }
            }
        });

    },
    render: function () {
        var context = this;
        var className = "";
        if (this.props.add_button) {
            className = "add-button-margin";
        }
        return <input class="form-control"/>,<div className={className} ref={function(ref){context.domElement = ref}}/>;
    }
});
