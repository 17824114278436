/**
 * ========================================================
 * Description: A web control for a boolean Y/N (similar to check-box)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1       02/06/2016   Vasil Galinovsky    File Attachment Spacing between YesNo and caption   Fixed spacing issue
 * ========================================================
 **/
var EditorDevExtremeSwitchYesNo = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            this.state.element.dxSwitch('instance').option("value", this.state.value);
        }
    },
    componentDidMount:function()
    {
        var context = this;
        this.state.value = this.props.value;
        this.state.element = $(this.refs.domElement).dxSwitch({
            readOnly: this.props.readOnly,
            value: this.state.value,
            onText: R.label_yes,
            offText: R.label_no,
            onValueChanged: function(data) {
                context.state.value = data.value;
                context.props.onChange(context.state.value);
                context.props.onBlur();
            }
        });
    },
    render: function() {
        var context = this;
        return <div ref="domElement" style={{"display":"block"}} />;
    }
});
