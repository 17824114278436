"use strict";
/**
 ==================================================================================
 Description:       Payment and registration for new users using a new origanization
 Creation Date:     4/4/2016
 Author:            Sebastian Manfield
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author           Task                Description
 ==================================================================================
 1      4/4/2016     Sebastian Mansfield         TaskNumber          Created
 2      4/5/2016     Sebastian Mansfield         Restyling and form validation
 3      4/11/16      Adam McDaniel 87632132      Changed to query server for Stripe's publishable key
 4      4/20/16      Josue Navarrete 88932312    Change position some html elements, replace label text
 5      4/26/16      Josue Navarrete 89881670    Required Fields css styles
 6      4/26/16      Victor Hernandez            Added loading screen on submit.
 7      5/12/16      Victor Hernandez            Added suggested username generation.
 8      5/13/16      Victor Hernandez            Added password strength validation.
 9      5/13/16      Victor Hernandez            Removed warning message for unavailable usernames.
 10     5/14/16      Adam McDaniel   92416260    Made Stripe-related errors disappear when Stripe fields are changed
 11     05/17/16     AMcDaniel                   Changed to allow creation without a credit card if coupon_code is valid and contains the word FREE
 12     5/18/16      Victor Hernandez            Client side validation errors for e-mail and password now appear as a popover under their respective fields.
 13     06/03/16     AMcDaniel       95086446    Changed route for requesting Stripe key
 14.   07/25/16   Sebastian Mansfield           Logged in after regitering
 ==================================================================================
 */

var PaymentRegistrationComponent = React.createClass({
    getInitialState: function () {
        this.get_secret_questions();
        this.get_tracfile_plans();
        this.get_countries();
        return {error: undefined, errorstripe: undefined};
    },
    get_countries: function () {
        var context = this;

        $.ajax({
            method: "GET",
            url: "/api/auth/get_countries",
            success: function (data) {
                context.setState({countries: data});
                $('#creditcountry').val('US');
            },
            error: function (err) {
                console.log(err);
            }
        })
    },
    get_secret_questions: function () {
        var context = this;
        $.ajax({
            method: "GET",
            url: "/api/auth/get_secret_questions",
            success: function (data) {
                context.setState({secret_questions: data});
            },
            error: function (err) {
                console.log(err);
            }
        })
    },
    componentDidMount: function () {
        var context = this;
        var imported = document.createElement('script');
        imported.src = 'https://js.stripe.com/v2/';
        document.head.appendChild(imported);
        var element = $('.registerbox *.required');
        for (var i = 0, l = element.length; i < l; i++){
            element.on('change', function(event){try{$(event.target).popover('destroy');}catch(e){}})
        }
        setTimeout(context.set_stripe_publishable_key, 1500);
    },
    set_stripe_publishable_key: function () {
        var context = this;

        $.ajax({
            method: "POST",
            url: "/api/settings/get_public_system_setting",
            dataType: 'json',
            data: {
                code: "billing_api_publishable_key"
            },
            success: function (data) {
                Stripe.setPublishableKey(data.data); // set your test public key
                $("#plans").val(get_parameter_by_name("plan_selected"));
                context.setState({plan_selected: get_parameter_by_name("plan_selected")});
            },
            error: function (err) {
                console.log(err);
            }
        });
    },
    get_tracfile_plans: function () {
        var context = this;

        $.ajax({
            method: "GET",
            url: "/api/auth/get_subscription_plans",
            success: function (data) {
                context.setState({suscription_plans: data});
            },
            error: function (err) {
                console.log(err);
            }
        })
    },
    remove_validated_class: function(element){
        element.removeClass('has-success');
        element.removeClass('has-error');
    },
    set_validated_class: function(element, valid){
        if(valid){
            element.addClass('has-success');
            element.removeClass('has-error');
        } else {
            element.removeClass('has-success');
            element.addClass('has-error');
        }
    },
    handle_submit: function (event) {
        event.preventDefault();
        var context = this;
        var element = $('.registerbox *.required');
        var el;
        for (var i = 0, l = element.length; i < l; i++){
            el = $(element[i]);
            if(!el.val()){
                el.popover({content: R.required_error, trigger:"manual focus", placement:"bottom"});
                el.popover('show');
                el.focus();
                return false;
            }
        }
        if (!this.validate_password()) {
            element = $('#password');
            element.popover({content: R.password_complexity, trigger:"manual focus", placement:"bottom"});
            element.popover('show');
            element.focus();
            return false;
        }
        element = $('#usermail');
        if(!this.validate_mail(element.val())){
            element.popover({content: R.invalid_email, trigger:"manual focus", placement:"bottom"});
            element.popover('show');
            element.focus();
            return false;
        }
        if(!this.validate_confirmed_password()){
            element = $('#confirm-password');
            element.popover({content: R.message_passwords_dont_match, trigger:"manual focus", placement:"bottom"});
            element.popover('show');
            element.focus();
            return false;
        }
        element = $('#creditnumber');
        if((!element.val() || (element.val() == "")) && context.state.coupon_code && (context.state.coupon_code.indexOf("FREE") != -1)) {
            context.send_request_to_create_first_user({});
            return;
        }
        if(!Stripe.card.validateCardNumber(element.val()))
        {
            context.setState({errorstripe: R.invalid_card_number});
            this.set_validated_class(element.parent(), false);
            element.focus();
            return;
        }
        element = $("#creditcvc");
        if(!Stripe.card.validateCVC(element.val()))
        {
            context.setState({errorstripe: R.invalid_cvc_number});
            this.set_validated_class(element.parent(), false);
            element.focus();
            return;
        }
        element = $('#creditmonth');
        var element2 = $('#credityear');
        if(!Stripe.card.validateExpiry(element.val(), element2.val()))
        {
            context.setState({errorstripe: R.invalid_expiry_date});
            this.set_validated_class(element.parent(), false);
            this.set_validated_class(element2.parent(), false);
            element.focus();
            return;
        }
        Stripe.card.createToken(event.currentTarget, function (status, response) {
            if(status != 200)
            {
                response = response.error;
                context.setState({errorstripe: response.message});
                return null;
            }
            else {
                context.send_request_to_create_first_user(response);
            }
        });
    },
    send_request_to_create_first_user: function(response) {
        var context = this;
        var request ={};
        var args = {};
        args.billing_token = response.id;
        args.org_name = context.state.orgname;
        args.coupon_code = context.state.coupon_code;
        args.plan_code = context.state.plan_selected;


        var data = {};
        data.login_name = context.state.username;
        data.password_hash = context.state.password;
        data.secret_question = context.state.secret_question;
        data.secret_question_answer_hash = context.state.secret_answer;
        data.email_address = context.state.usermail;
        data.first_name = context.state.firstname;
        data.last_name = context.state.lastname;

        context.setState({loading: true});
        request.args = args;
        request.data = data;
        request = JSON.stringify(request);

        $.ajax({
                url: "api/auth/create_first_user",
                data: request,
                dataType: 'json',
                contentType: "application/json",
                type: 'POST'
            })
            .done(function (data) {
                if (data) {
                    console.log(data);
                    if (data.success) {
                        context.setState({completed: true});
                        Notify(R.message_successfully_registered, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                        var login_token = data.login_token;
                        if (login_token !== undefined) {
                            localStorage.setItem("login_token", login_token);
                            g_login_token = login_token;

                            context.setState({success: true});
                            //return;
                        }else{
                            // we did not receive a login_token
                        }
                        context.props.on_success();
                    }
                    else if (data.message == "User added") {
                        context.setState({completed: true});
                        Notify(R.message_successfully_registered, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                        var login_token = data.login_token;
                        if (login_token !== undefined) {
                            localStorage.setItem("login_token", login_token);
                            g_login_token = login_token;

                            context.setState({success: true});
                            //return;
                        }else{
                            // we did not receive a login_token
                        }
                        context.props.on_success();
                    }
                    else {
                        context.setState({error: data.message});
                    }
                }
            })
            .fail(function (error) {
                error = error.responseJSON;
                context.setState({error: error.message});
            })
            .complete(function () {
                if (!context.state.completed) {
                    context.setState({loading: false});
                }
            });
    },
    on_orgname_change: function (event) {
        this.setState({orgname: event.target.value});
        $("#orgname").bootstrapValidator();
    },
    on_usermail_change: function (event) {
        this.setState({usermail: event.target.value});
        if (event.target) {
            if(!this.validate_mail(event.target.value)){
                this.set_validated_class($(event.target).parent(),false);
            } else {
                this.set_validated_class($(event.target).parent(),true);
                try{
                    $(event.target).popover('destroy');
                } catch(e){}
            }
        }
    },
    on_userfirstname_change: function (event) {
        var element = $("#userfirstname");
        var value = event.target.value;
        if(value.match(/([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            this.setState({firstname: value});
        element.bootstrapValidator();
    },
    on_userlastname_change: function (event) {
        var element = $('#userlastname');
        var value = event.target.value;
        if(value.match(/([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            this.setState({lastname: value});
        element.bootstrapValidator();
    },
    on_username_change: function (event) {
        this.setState({username: event.target.value});
        $("#username").bootstrapValidator();
    },
    on_password_change: function (event) {
        this.state.password = event.target.value;
        var element = $(event.target).parent();
        this.set_validated_class(element, this.validate_password() && event.target.value);
        this.validate_confirmed_password();
        this.forceUpdate();
    },
    validate_password: function (){
        if(1>=this.password_strength()){
            var element = $('#password');
            try{
                element.popover('destroy');
            }catch(e){}
            return true;
        }else{
            return false;
        }
    },
    validate_mail: function(s){
        return s.match(/^(?:[A-Z0-9_%+-]+\.?)+[A-Z0-9_%+-]+@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/gi);
    },
    on_confirmed_password_change: function (event) {
        this.state.confirmed_password = event.target.value;
        this.validate_confirmed_password();
        this.forceUpdate();
    },
    validate_confirmed_password: function (){
        var element = $('#confirm-password');
        var valid = this.state.confirmed_password == this.state.password && this.state.confirmed_password && this.validate_password();
        this.set_validated_class(element.parent(), valid);
        if (valid) {
            try {
                element.popover('destroy');
            } catch (e) {}
        }
        return valid;
    },
    on_secret_question_change: function (event) {
        $("input[required-name='secret_answer']").focus();
        this.setState({secret_question: event.target.value});
    },
    on_secret_answer_change: function (event) {
        this.setState({secret_answer: event.target.value});
    },
    on_cardnumber_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $(event.target);
        var new_value = event.target.value;
        if (!new_value.match(/^[\d\s-]{0,23}$/g)) {
            new_value = new_value.replace(/[^\d\s-]/g, '');
            new_value = new_value.substr(0,23);
            element.val(new_value);
        }
        this.remove_validated_class(element.parent());
        element.bootstrapValidator();
    },
    on_cvc_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $(event.target);
        var new_value = event.target.value;
        if (!new_value.match(/^\d{0,3}$/g)) {
            new_value = new_value.replace(/\D/g, '');
            new_value = new_value.substr(0,3);
            element.val(new_value);
        }
        this.remove_validated_class(element.parent());
        element.bootstrapValidator();
    },
    on_cardmonth_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $(event.target);
        var new_value = event.target.value;
        if (!new_value.match(/^\d{0,2}$/g)) {
            new_value = new_value.replace(/\D/g, '');
            new_value = new_value.substr(0,2);
            element.val(new_value);
        }
        this.remove_validated_class(element.parent());
        this.remove_validated_class($('#credityear').parent());
        element.bootstrapValidator();
    },
    on_cardyear_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $(event.target);
        var new_value = event.target.value;
        if (!new_value.match(/^\d{0,4}$/g)) {
            new_value = new_value.replace(/\D/g, '');
            new_value = new_value.substr(0,4);
            element.val(new_value);
        }
        this.remove_validated_class(element.parent());
        this.remove_validated_class($('#creditmonth').parent());
        element.bootstrapValidator();
    },
    on_cardname_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $(event.target);
        var new_value = event.target.value;
        if(new_value && new_value.match(/([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(new_value.substr(0, new_value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_address1_change: function (event) {
        this.setState({errorstripe: undefined});
        $("#creditaddress1").bootstrapValidator();
    },
    on_address2_change: function (event) {
        this.setState({errorstripe: undefined});
        $("#creditaddress2").bootstrapValidator();
    },
    on_cardcity_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $("#creditcity");
        var value = event.target.value;
        if(value.match(/([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_cardcountry_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $("#creditcountry");
        var value = event.target.value;
        if(value.match(/([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_cardstate_change: function (event) {
        this.setState({errorstripe: undefined});
        var element = $("#creditstate");
        var value = event.target.value;
        if(value.match(/([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_cardzip_change: function (event) {
        this.setState({errorstripe: undefined});
        $("#creditzip").bootstrapValidator();
    },
    on_plan_change: function (event) {
        this.setState({plan_selected: event.target.value});
        $("#plans").bootstrapValidator();
    },
    on_coupon_change: function (event) {
        this.setState({coupon_code: event.target.value});
        $("#coupon").bootstrapValidator();
    },
    on_name_field_blur :function (event) {
        this.set_suggested_username();
    },
    on_username_field_blur : function(){
        this.set_suggested_username();
    },
    set_suggested_username: function(){
        if (this.state.firstname && this.state.lastname) {
            var context = this;
            context.build_username(function (data) {
                if (context.state.username == data.suggested_username || context.state.username == data.alternate_username)
                    context.setState({
                        suggested_username: undefined,
                        alternate_username: undefined
                    });
                else context.setState(data);
            });
        }
    },
    build_username: function(callback){
        var request = {};
        var data = {};
        data.login_name = this.state.username;
        data.first_name=this.state.firstname;
        data.last_name = this.state.lastname;
        request.data = data;

        $.ajax({
            method: "POST",
            url: "/api/auth/get_suggested_login_name",
            dataType: "json",
            contentType:"application/json",
            data: JSON.stringify(request),
            success: function (data) {
                callback(data.data);
            },
            error: function (err) {
                console.log(err);
            }
        });
    },
    select_suggested_username: function(un){
        this.setState({username: un.target.text, suggested_username: undefined, alternate_username: undefined});
        this.on_username_field_blur();
        $('#usermail').focus();
    },
    password_strength: function(){
        var pw = this.state.password;
        //calculate password score
        var score = 0;
        if (pw){
            //length
            score += pw.length>=8?0:100;
            //Uppercase letters
            score += pw.replace(/[^A-Z]/g, "").length?0:1;
            //Lowercase letters
            score += pw.replace(/[^a-z]/g, "").length?0:1;
            //numbers
            score += pw.replace(/[\D]/g, "").length?0:1;
            //symbols
            score += pw.replace(/[\w]/g, "").length?0:1;
        }
        return score;
    },
    componentWillUnmount: function () {
        var element = $('.registerbox *.required');
        for (var i = 0, l = element.length; i < l; i++){
            element.off('change');
        }
        document.body.className = "";
    },
    render: function () {
        document.body.className = "register-body";
        var error_component = "";
        var error_component_stripe ="";
        if (this.state.error) {
            var on_close = function (context) {
                return function () {
                    context.setState({error: undefined});
                };
            }(this);
            error_component = <ErrorNotificationComponent message={this.state.error} on_close={on_close}/>;
        }
        if (this.state.errorstripe) {
            var on_close = function (context) {
                return function () {
                    context.setState({errorstripe: undefined});
                };
            }(this);
            error_component_stripe = <ErrorNotificationComponent
                message={this.state.errorstripe}
                on_close={on_close}/>;
        }


        var secret_questions = [];
        if (this.state.secret_questions) {
            for (var i = 0; i < this.state.secret_questions.length; i++) {
                var secret_question = this.state.secret_questions[i];
                secret_questions.push(<option key={secret_question.code}
                                              value={secret_question.code}>{secret_question.value}</option>);
            }
        }

        var countries = [];
        if (this.state.countries) {
            for (var i = 0; i < this.state.countries.length; i++) {
                var country = this.state.countries[i];
                countries.push(<option key={country.code.toUpperCase()}
                                       value={country.code.toUpperCase()}>{country.value}</option>);
            }
        }

        var submit_button = <input tabIndex="23" type="submit" className="btn btn-primary btn-block" value={R.label_submit}/>;
        if (this.state.loading) {
            submit_button =
                <input tabIndex="23" type="submit" disabled className="btn btn-primary btn-block" value={R.label_submit}/>;
        }

        var suscription_plans = [];
        if (this.state.suscription_plans) {

            for (var i = 0; i < this.state.suscription_plans.length; i++) {
                var suscription_plan = this.state.suscription_plans[i];
                suscription_plans.push(<option key={suscription_plan.code}
                                              value={suscription_plan.code}>{suscription_plan.value}</option>);
            }
        }
        var taken='';
        var available= '';
        if(this.state.suggested_username){
            var alternate = '';
            if(this.state.alternate_username && this.state.alternate_username!=this.state.suggested_username){
                alternate = <a href="javaScript:void(0)" onClick={this.select_suggested_username}>{this.state.alternate_username}</a>;
            }
            available = <i>{R.available_username}: <a href="javaScript:void(0)" onClick={this.select_suggested_username}>{this.state.suggested_username}</a>{alternate?', ':''}{alternate}</i>;
        }

        var require_card_info = (!this.state.coupon_code || (this.state.coupon_code.indexOf("FREE") == -1));


        if(this.state.loading){
            return(<LoadingComponent logged_in_false={this.props.logged_in_false} />);
        }else return (
            <div className="register-container animated fadeInDown" style={{"marginTop" :"5px"}}>
                <table className="registerbox">
                    <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <div className="registerbox-header01">
                                <div className="registerbox-header02">
                                    <div style={{"textAlign":"center"}}>
                                        <img src="images/logo.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="registerbox-menu-border-ribbon"></td>
                        <td>
                            <div className="registerbox-menu_right">
                                <div className="registerbox-loggedout_right">
                                    {R.registration_text}
                                </div>
                            </div>
                        </td>
                        <td className="registerbox-menu-border-ribbon"></td>
                    </tr>
                    <tr>
                        <td className="registerbox-menu-left-ribbon"></td>
                        <td>
                            <div style={{"position":"relative"}}>
                                <div className="registerbox-ribbon-shadow"></div>
                                <div className="registerbox-content01">
                                    <div className="registerbox-content02">
                                        <form role="form"  onSubmit={this.handle_submit} className="form_registration">
                                            {error_component}
                                            {taken}
                                            <br/>
                                            <br/>
                                            <div className="form-group col-sm-6 ">
                                                <input id="userfirstname"
                                                       className="form-control required"
                                                       type="text"
                                                       placeholder={R.first_name}
                                                       value={this.state.firstname}
                                                       onChange={this.on_userfirstname_change}
                                                       onBlur={this.on_name_field_blur}
                                                       tabIndex="1"
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <input id="userlastname"
                                                       className="form-control required"
                                                       type="text"
                                                       placeholder={R.last_name}
                                                       value={this.state.lastname}
                                                       onChange={this.on_userlastname_change}
                                                       onBlur={this.on_name_field_blur}
                                                       tabIndex="2"
                                                />
                                            </div>
                                            <div className="form-group col-sm-12">
                                                <input id="orgname"
                                                       className="form-control required"
                                                       type="text"
                                                       placeholder={R.organization_name}
                                                       value={this.state.orgname}
                                                       onChange={this.on_orgname_change}
                                                       tabIndex="3"
                                                />
                                            </div>
                                            <fieldset className="form-group col-sm-12" style={{padding:'0px', marginBottom:'0px'}}>
                                                <div className="form-group col-sm-6">
                                                    <input id="username"
                                                           type="text"
                                                           className="form-control required"
                                                           placeholder={R.username}
                                                           value={this.state.username}
                                                           onChange={this.on_username_change}
                                                           onBlur={this.on_username_field_blur}
                                                           tabIndex="4"
                                                    />
                                                    {available}
                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <input id="usermail"
                                                           className="form-control required"
                                                           type="text"
                                                           placeholder={R.e_mail}
                                                           value={this.state.usermail}
                                                           onChange={this.on_usermail_change}
                                                           onBlur={this.on_usermail_blur}
                                                           tabIndex="5"
                                                    />
                                                </div>
                                            </fieldset>
                                            <div className="form-group col-sm-6">
                                                <input id="password"
                                                       type="password"
                                                       className="form-control required"
                                                       placeholder={R.password}
                                                       onChange={this.on_password_change}
                                                       tabIndex="6"
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <input id="confirm-password"
                                                       type="password"
                                                       className="form-control required"
                                                       placeholder={R.confirm_password}
                                                       onChange={this.on_confirmed_password_change}
                                                       tabIndex="7"
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <select className="form-control required"
                                                        name="secret-question"
                                                        defaultValue={this.state.secret_question}
                                                        onChange={this.on_secret_question_change}
                                                        tabIndex="8"
                                                >
                                                    <option disabled key="" value="">{R.select_secret_question}</option>
                                                    {secret_questions}
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <input type="password"
                                                       name="secret_answer"
                                                       className="form-control required"
                                                       placeholder={R.secret_answer}
                                                       onChange={this.on_secret_answer_change}
                                                       tabIndex="9"
                                                />
                                            </div>
                                            <br/>&nbsp;
                                            <br/>
                                            {error_component_stripe}
                                            <div className="col-sm-12 form-group"> {R.registration_billing}</div>
                                            <br/>
                                            <br/>
                                            <div className="form-group col-sm-4" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="20"
                                                       data-stripe="number"
                                                       placeholder={R.credit_card_number}
                                                       id="creditnumber"
                                                       onChange={this.on_cardnumber_change}
                                                       tabIndex="10"
                                                />
                                            </div>
                                            <div className="form-group col-sm-2" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="cvc"
                                                       placeholder={R.cvc}
                                                       id="creditcvc"
                                                       onChange={this.on_cvc_change}
                                                       tabIndex="11"
                                                />
                                            </div>
                                            <div className="form-group col-sm-3" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       data-stripe="exp-month"
                                                       placeholder={R.exp_month}
                                                       id="creditmonth"
                                                       onChange={this.on_cardmonth_change}
                                                       tabIndex="12"
                                                />
                                            </div>
                                            <div className="form-group col-sm-3" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="exp-year"
                                                       placeholder={R.exp_year}
                                                       id="credityear"
                                                       onChange={this.on_cardyear_change}
                                                       tabIndex="13"
                                                />
                                            </div>
                                            <div>
                                                <div className="form-group col-sm-6">
                                                    <select className="form-control required"
                                                            id="plans"
                                                            name="plan"
                                                            defaultValue={this.state.plan_selected}
                                                            onChange={this.on_plan_change}
                                                            tabIndex="14"
                                                    >
                                                        <option disabled key="" value="">{R.select_your_plan}</option>
                                                        {suscription_plans}
                                                    </select>
                                                </div>
                                                <div className="form-group col-sm-6 ">
                                                    <input type="text"
                                                           id="coupon"
                                                           className="form-control"
                                                           placeholder={R.insert_coupon_code}
                                                           onChange={this.on_coupon_change}
                                                           tabIndex="15"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-12" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="name"
                                                       placeholder={R.name_credit_card}
                                                       id="creditname"
                                                       onChange={this.on_cardname_change}
                                                       tabIndex="16"
                                                />
                                            </div>
                                            <div className="form-group col-sm-12" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="address_line1"
                                                       placeholder={R.address_line_1}
                                                       id="creditaddress1"
                                                       onChange={this.on_address1_change}
                                                       tabIndex="17"
                                                />
                                            </div>
                                            <div className="form-group col-sm-12" >
                                                <input className="form-control"
                                                       type="text"
                                                       size="4"
                                                       data-stripe="address_line2"
                                                       placeholder={R.address_line_2}
                                                       id="creditaddress2"
                                                       onChange={this.on_address2_change}
                                                       tabIndex="18"
                                                />
                                            </div>
                                            <div className="form-group col-sm-3" >
                                                <select className={"form-control" + (require_card_info?" required":"")}
                                                        data-stripe="address_country"
                                                        name="country"
                                                        defaultValue="US"
                                                        id="creditcountry"
                                                        onChange={this.on_cardcountry_change}
                                                        tabIndex="19"
                                                >
                                                    {countries}
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-3" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="address_city"
                                                       placeholder={R.city}
                                                       id="creditcity"
                                                       onChange={this.on_cardcity_change}
                                                       tabIndex="20"
                                                />
                                            </div>
                                            <div className="form-group col-sm-3" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="address_state"
                                                       placeholder={R.state}
                                                       id="creditstate"
                                                       onChange={this.on_cardstate_change}
                                                       tabIndex="21"
                                                />
                                            </div>
                                            <div className="form-group col-sm-3" >
                                                <input className={"form-control" + (require_card_info?" required":"")}
                                                       type="text"
                                                       size="4"
                                                       data-stripe="address_zip"
                                                       placeholder={R.zip_code}
                                                       id="creditzip"
                                                       onChange={this.on_cardzip_change}
                                                       tabIndex="22"
                                                />
                                            </div>
                                            <div className="registerbox-submit">
                                                {submit_button}
                                            </div>
                                            <div dangerouslySetInnerHTML={{__html : R.contact_us}}
                                                tabIndex="24">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="registerbox-menu-right-ribbon">&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
});