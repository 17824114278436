"use strict";
/**
 * ==================================================================================
 * Description:       Similar in function to edit-form, but in this component, the desired layout is sent from server-side with mustache-style placeholders for the input fields
 * Creation Date:     9/2/2016
 * Author:            AMcDaniel
 * ==================================================================================
 * Revision History
 * ==================================================================================
 * Rev     Date        Author      Task        Description
 * ==================================================================================
 * 1       09/02/16    AMcDaniel   109177071   Created
 * 2       09/14/16    AMcDaniel   109177071   Added code to launch edit_forms for individual nested entity entries in array
 * 3       09/23/16    AMcDaniel   109177071   Added caching of processed html and entity attributes to prevent excessive computation
 * 4       09/26/16    AMcDaniel   109177071   Matched styling of row edit button, added a delete button
 * 5       09/26/16    AMcDaniel   109177071   Changed/Added some styling fixes
 * ==================================================================================
 */

var styles_which_need_to_be_applied_locally_for_custom_html_template = [ //Tested with/pulled from app_level_data/ship/report_templates/shipment_template_input_form.html 9-26-2016
    "#dxSelectBox {  top: 0;  display: inline-block;  width: 100%;  }",
    ".dx-texteditor-container {  width: 98%;  }",
    ".dx-texteditor-input {  padding: 0px 12px;  }",
    ".form-control {  height: 24px;  padding: 0px 12px;  }",
    ".edit-controls-edit-button {  position: relative;  display: inline-block;  left: 3px;  top: 0;  right: 0;  margin: 0;  min-width: 35px;  }",
    ".edit-controls-add-button {  position: relative;  display: inline-block;  left: 3px;  top: 0;  right: 0;  padding: 0 !important;  margin: 0;  min-width: 35px;  }",
    ".add-button-margin {  margin-right: 0 !important;  }",
    ".dx-button {  vertical-align: top;  }",
    ".ref_lookup_container {  display: flex !important;  align-items: stretch;  flex-flow: row nowrap;  justify-content: space-between;  padding-right: 3px;  }",
    "table .ref_lookup_container { height: 24px; }",
    "table .dx-button {  width: 30px;  height: 24px;  min-height: 0;  min-width: 0;  }",
    "table .dx-button .dx-button-content { padding: 0; }",
    "table .dx-placeholder:before {  padding: 0 9px 0; }",
    "table .dx-show-clear-button .dx-icon-clear {  margin-top: -13px;  margin-left: -10px;  }",
    ".dx-dropdowneditor.dx-dropdowneditor-field-clickable {  display: inline-block;  width: 100%;  }",
    "#xenforma_file_attachments {  width: 100%;  display: inline-flex;  flex-direction: row;  flex-wrap: nowrap;  }"
];

var CustomEditFormComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start) {
        if (this.debugging) {
            if (!start) {
                this.tab--;
            }
            try {
                console.log((start ? '-> ' : '<- ') + Array(this.tab + 1).join('\t') + msg + this.tab);
            } catch (e) {
                console.log((start ? '-> ' : '<- ') + e + this.tab);
            }
            if (start) {
                this.tab++;
            }
        }
    },
    getInitialState: function () {
        this.debugLog('getInitialState', true);

        this.debugLog('getInitialState', false);
        return this.props.state_data; //also see: props_data
    },
    componentWillUnmount: function () {
        document.body.className = "";
    },
    raw_markup: function(style_string) {
        return { __html: style_string };
    },
    get_div_style_object: function() {
        return {
            position: "relative",
            width: "8.5 in",
            height: "11 in"
        };
    },
    render: function () {
        document.body.className = "register-body";
        var return_code_function;
        var entity_attributes = this.props.entity_attributes || {};
        var entity_instance = this.props.entity_instance || {};
        var nested_list_entities = this.props.nested_list_entities || {};
        var edit_form_context = this.props.edit_form_context || {};
        var input_elements_by_field_path, eval_string, style_string, js_eval_code, edit_form_args_by_path;
        if (entity_attributes.cached_custom_html_args) { //invalidates on adding/removing nested list entity instances
            input_elements_by_field_path = entity_attributes.cached_custom_html_args.input_elements_by_field_path;
            edit_form_args_by_path = entity_attributes.cached_custom_html_args.edit_form_args_by_path;
            js_eval_code = entity_attributes.cached_custom_html_args.js_eval_code;
            style_string = entity_attributes.cached_custom_html_args.style_string;
        }
        else {
            input_elements_by_field_path = [];
            edit_form_args_by_path = {};
            eval_string = "return_code_function = function() {return (" + this.state.custom_html_template + ");};";
            var ret_strings = replace_string_field_occurences_with_react_controls(input_elements_by_field_path, edit_form_context, eval_string, nested_list_entities, entity_attributes, entity_instance, edit_form_args_by_path);
            eval_string = ret_strings.eval_string;
            style_string = ret_strings.style_string; //css styles throw off babel

            var jsCode = babel.transform(eval_string);
            js_eval_code = jsCode.code;
        }
        eval(js_eval_code);

        entity_attributes.cached_custom_html_args = {
            js_eval_code: js_eval_code,
            style_string: style_string,
            input_elements_by_field_path: input_elements_by_field_path,
            edit_form_args_by_path: edit_form_args_by_path
        };

        return (
            <div style={this.get_div_style_object()}>
                <style scoped dangerouslySetInnerHTML={this.raw_markup(style_string)}></style>
                {return_code_function()}
            </div>
        );
    }
});

function run_att_getter_function(field_path, input_elements_by_field_path) {
    return create_control_for_attribute(input_elements_by_field_path[field_path], true);
}

function replace_string_field_occurences_with_react_controls(input_elements_by_field_path, edit_form_context, eval_string, nested_list_entities, entity_attributes, entity_instance, edit_form_args_by_path) {
    eval_string = replace_mustache_paths_with_field_paths(input_elements_by_field_path, edit_form_context, eval_string, nested_list_entities, entity_attributes, entity_instance, null, {scope_index: 0}, null, edit_form_args_by_path);

    eval_string = eval_string.replace(/{{[\s\S]+?}}/g, ""); //remove all remaining {{*}} sections

    var ret_strings = remove_html_tags_and_extract_style(eval_string);
    var style_string = ret_strings.style_string;
    eval_string = ret_strings.eval_string;


    //Remove container tags from style section:
    style_string = style_string.replace(/<style[\s\S]*?>/g, "");
    style_string = style_string.replace(/<\/style[\s\S]*?>/g, "");
    style_string = style_string + "\r\n";
    


    //remove inline style strings because JSX can't parse them.  Make a dictionary of newly generated ids to add these styles to the header style of the file.
    eval_string = eval_string.replace(/style\S*?=\S*?"[\s\S]+?"/g, function(match_string) {
        var new_id = create_uid();
        match_string = match_string.replace(/style\S*?=\S*?"/g, "");
        match_string = match_string.substring(0, match_string.length - 1); //remove trailing "
        style_string = style_string + "#" + new_id + " {" + match_string + "}\r\n";
        return 'id="' + new_id + '"';
    });

    //change 'class' attribute to className for react:
    eval_string = eval_string.replace(/class\S*?=\S*?"[\s\S]+?"/g, function(match_string) {
        match_string = match_string.replace(/class\S*?=\S*?"/g, 'className="');
        return match_string;
    });

    style_string = style_string || "";
    for(var i = 0; i < styles_which_need_to_be_applied_locally_for_custom_html_template.length; i++) {
        style_string = style_string + " " + styles_which_need_to_be_applied_locally_for_custom_html_template[i];
    }

    return {eval_string: eval_string, style_string: style_string};
}

//Template utils:
function replace_mustache_paths_with_field_paths(input_elements_by_field_path, edit_form_context, eval_string, nested_list_entities, entity_attributes, entity_instance, base_field_path, scope_index_object, array_indexer_value, edit_form_args_by_path) {
    var scope_index = scope_index_object.scope_index;
    entity_attributes = entity_attributes || {};
    entity_attributes.field_paths = entity_attributes.field_paths || {};
    entity_attributes.nested_list_entities = entity_attributes.nested_list_entities || {};
    var current_scope = "";
    var scope_sections_to_skip = {};
    var scope_sections_to_fill_with_nested_entities = {};
    eval_string = (eval_string || "").replace(/{{[\s\S]+?}}/g, function (match_string) {
        if(current_scope == base_field_path) {
            current_scope = "";
        }
        match_string = match_string.substring(2, match_string.length - 2);
        match_string = match_string.trim();
        var has_scope = !string_is_empty_or_whitespace(current_scope);
        if (match_string[0] == "#") { //changes scope
            match_string = match_string.substring(1, match_string.length);
            current_scope = (has_scope) ? current_scope + ":" + match_string : match_string;
            var field_attribute = entity_attributes.field_paths[current_scope];
            if(field_attribute) {
                if (field_attribute.is_nested_entity && !field_attribute.data_is_nested && !field_attribute.is_array) { //This whole section should be replaced by a single reference field
                    scope_sections_to_skip[current_scope] = 1;
                    return "{{#" + field_attribute.field_path + "}}";  //preserve for replacing whole section
                }
                else if (field_attribute.is_nested_entity && field_attribute.data_is_nested && field_attribute.is_array && entity_attributes.nested_list_entities[field_attribute.db_type]) { //This whole section should be filled in by nested entity fields
                    scope_sections_to_fill_with_nested_entities[current_scope] = entity_attributes.nested_list_entities[field_attribute.db_type];
                    return "{{#" + field_attribute.field_path + "}}";  //preserve for replacing whole section
                }
            }
            return "";
        }
        else if (match_string[0] == "/") { //changes scope
            var field_attribute = entity_attributes.field_paths[current_scope];
            match_string = match_string.substring(1, match_string.length);
            current_scope = (has_scope && (current_scope.length > (match_string.length + 1))) ? current_scope.substring(0, current_scope.length - (match_string.length + 1)) : "";
            if(field_attribute) {
                if (field_attribute.is_nested_entity && !field_attribute.data_is_nested && !field_attribute.is_array) { //This whole section should be replaced by a single reference field
                    return "{{/" + field_attribute.field_path + "}}";  //preserve for replacing whole section
                }
                else if (field_attribute.is_nested_entity && field_attribute.data_is_nested && field_attribute.is_array && entity_attributes.nested_list_entities[field_attribute.db_type]) { //This whole section should be filled in by nested entity fields
                    return "{{/" + field_attribute.field_path + "}}";  //preserve for replacing whole section
                }
            }
            return "";
        }
        if (has_scope) {
            return "{{" + current_scope + ":" + match_string + "}}";
        }
        return "{{" + match_string + "}}";
    });

    for(var field_path in scope_sections_to_skip) {
        if(scope_sections_to_skip.hasOwnProperty(field_path) && scope_sections_to_skip[field_path]) {
            var pattern_match = new RegExp("{{#" + field_path + "}}[\\s\\S]*?{{/" + field_path + "}}","gm");
            eval_string = eval_string.replace(pattern_match, function(match) {
                return "{{" + field_path + "}}";
            });
        }
    }

    input_elements_by_field_path[scope_index] = input_elements_by_field_path[scope_index] || {};
    var instance_field_path = base_field_path;
    if(base_field_path && (array_indexer_value != null)) {
        instance_field_path = base_field_path + ":[" + array_indexer_value + "]";
    }
    
    for (var field_path in entity_attributes.field_paths) {
        if (entity_attributes.field_paths.hasOwnProperty(field_path) && entity_attributes.field_paths[field_path]) {
            input_elements_by_field_path[scope_index][field_path] = input_elements_by_field_path[scope_index][field_path] || {
                    edit_form_context: edit_form_context,
                    attribute: entity_attributes.field_paths[field_path],
                    entity_instance: entity_instance,
                    attribute_options: null,
                    base_field_path: instance_field_path,
                    nested_entity_name: ((scope_index > 0) ? entity_attributes.entity : null)
                };
            eval_string = eval_string.replace("{{" + ((base_field_path) ? base_field_path + ":" + field_path : field_path) + "}}", '{run_att_getter_function("' + field_path + '", input_elements_by_field_path[' + scope_index + '])}');
        }
    }

    for(var field_path in scope_sections_to_fill_with_nested_entities) {
        if(scope_sections_to_fill_with_nested_entities.hasOwnProperty(field_path) && scope_sections_to_fill_with_nested_entities[field_path]) {
            var nested_entity_array = entity_instance[field_path] || get_json_data_using_field_paths(field_path, entity_instance) || [];
            edit_form_args_by_path[field_path] = edit_form_args_by_path[field_path] || {
                    entity_attributes: scope_sections_to_fill_with_nested_entities[field_path],
                    nested_list_entities: scope_sections_to_fill_with_nested_entities[field_path].nested_list_entities,
                    app_object: (nested_list_entities[scope_sections_to_fill_with_nested_entities[field_path].entity] || {}).app_object,
                    parent_entity_array: nested_entity_array,
                    parent_entity_field_attribute: entity_attributes.field_paths[field_path],
                    base_field_path: instance_field_path
            };
            var pattern_match = new RegExp("{{#" + field_path + "}}[\\s\\S]*?{{/" + field_path + "}}", "gm");
            eval_string = eval_string.replace(pattern_match, function (match) {
                var ret_string = "";
                for (var i = 0; i < nested_entity_array.length; i++) {
                    scope_index_object.scope_index++;
                    var edit_button_string = '<span id="edit" class="dx-link fa fa-pencil-square-o inline_edit_button" onClick={nested_entity_row_popup_function.bind(edit_form_context, edit_form_context, get_json_data_using_field_paths("' + field_path + ':[' + nested_entity_array[i]._id + ']' + '", entity_instance), null, edit_form_args_by_path["' + field_path + '"].app_object, edit_form_args_by_path["' + field_path + '"].entity_attributes, edit_form_args_by_path["' + field_path + '"].nested_list_entities, edit_form_args_by_path["' + field_path + '"].parent_entity_array, edit_form_args_by_path["' + field_path + '"].parent_entity_field_attribute, edit_form_context, "' + field_path + ':[' + nested_entity_array[i]._id + ']")}></span>';
                    var delete_button_string = '<span id="delete" class="dx-link fa fa-trash-o inline_edit_button" onClick={confirm_then_delete_nested_entity.bind(null, edit_form_context, edit_form_args_by_path["' + field_path + '"].parent_entity_field_attribute, edit_form_args_by_path["' + field_path + '"].parent_entity_array, "' + nested_entity_array[i]._id + '")}></span>';
                    var match_with_edit_button = match.replace("</tr>", '<td class="inline_edit_button_holder">' + delete_button_string + edit_button_string + '</td></tr>');
                    ret_string = ret_string + replace_mustache_paths_with_field_paths(input_elements_by_field_path, edit_form_context, match_with_edit_button, nested_list_entities, scope_sections_to_fill_with_nested_entities[field_path], nested_entity_array[i], field_path, scope_index_object, nested_entity_array[i]._id, edit_form_args_by_path);
                }
                //insert a blank row:
                scope_index_object.scope_index++;
                var temp_id = create_guid();
                var match_with_edit_button = match.replace("</tr>", '<td class="inline_edit_button_holder"></td></tr>');
                ret_string = ret_string + replace_mustache_paths_with_field_paths(input_elements_by_field_path, edit_form_context, match_with_edit_button, nested_list_entities, scope_sections_to_fill_with_nested_entities[field_path], {_id: temp_id}, field_path, scope_index_object, temp_id, edit_form_args_by_path);
                return ret_string;
            });

        }
    }

    return eval_string;
}

function confirm_then_delete_nested_entity(edit_form_context, nested_entity_field_attribute, nested_entity_array, entity_id) {
    edit_form_context.confirm(R.label_delete_title, R.message_delete_entity, function (confirmation) {
        if (confirmation) {
            edit_form_context.delete_nested_entity(nested_entity_field_attribute, nested_entity_array, entity_id);
        }
    });
}