"use strict";
/**
 ==================================================================================
 Description:       Billing settings edit form to manage subscriptions and credit card information
 Creation Date:     4/7/2016
 Author:            Sebastian Mansfield
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author           Task                Description
 ==================================================================================
 1      4/7/2016     Sebastian Mansfield          TaskNumber          Created
 2      04/11/16     Adam McDaniel                87632132            Changed to query server for Stripe's publishable key
 3      05/17/16     AMcDaniel                                        Changed form to work better if user has no credit card registered
 4      06/03/16     AMcDaniel                    95086446            Changed route for requesting Stripe key
 ==================================================================================
 */

var BillingSettingsComponent = React.createClass({
    get_billing_data: function () {
        var request = {};
        request.entity = "user";
        request.method = "get_billing_info";

        var context = this;

        do_authenticated_http_call({
            method: "POST",
            url: "/api/entity/invoke_method",
            dataType: "json",
            data: request,
            success: function (data) {
                var updated_state = {};
                updated_state.billing_data = data;
                if (context.state.data) {
                    updated_state.loading = false;
                }
                context.setState(updated_state);
            },
            error: function (err) {

            },
            complete: function () {
                context.props.handle_executing(false);
            }
        })
    },
    componentDidMount: function () {
        var context = this;
        var imported = document.createElement('script');
        imported.src = 'https://js.stripe.com/v2/';
        document.head.appendChild(imported);
        setTimeout(context.set_stripe_publishable_key, 1500);
        $(window).on('resize', this.handleResize);
        this.handleResize();
    },
    componentWillUnmount: function(){
        $(window).off('resize');
    },
    componentDidUpdate: function(){
        this.handleResize();
    },
    handleResize: function(){
        this.state.windowWidth = window.innerWidth;
        this.state.windowHeight = window.innerHeight;
        var allWidgetBody=$(".widget-body"),
            parentHeight = this.state.windowHeight,
            buttonBoxHeight = $('#button-box').height(),
            headerHeight = $('.widget-header').height() + $('.navbar').height(),
            heightElement=allWidgetBody.css("height");
        var heightWindow =  (parentHeight - buttonBoxHeight - headerHeight - 30)  + "px";
        console.log(parentHeight, buttonBoxHeight, headerHeight);
        if (heightWindow != heightElement) {
            allWidgetBody.css("height" , heightWindow);
            allWidgetBody.css("overflow-y" , "auto");
        }
    },
    set_stripe_publishable_key: function () {
        var context = this;

        $.ajax({
            method: "POST",
            url: "/api/settings/get_public_system_setting",
            dataType: 'json',
            data: {
                code: "billing_api_publishable_key"
            },
            success: function (data) {
                Stripe.setPublishableKey(data.data); // set your test public key
            },
            error: function (err) {
                console.log(err);
                //var logged_in=true;try{logged_in= err.responseJSON.logged_in; if(logged_in==false) location.reload(); }catch(err){ }
            }
        });
    },
    get_countries: function () {
        var context = this;

        $.ajax({
            method: "GET",
            url: "/api/auth/get_countries",
            success: function (data) {
                context.setState({countries: data});
            },
            error: function (err) {
                console.log(err);
                //var logged_in=true;try{logged_in= err.logged_in; if(logged_in==false) location.reload(); }catch(err){ }
            }
        })
    },
    get_tracfile_plans: function () {
        var context = this;

        $.ajax({
            method: "GET",
            url: "/api/auth/get_subscription_plans",
            success: function (data) {
                context.setState({suscription_plans: data});
            },
            error: function (err) {
                console.log(err);
                //var logged_in=true;try{logged_in= err.logged_in; if(logged_in==false) location.reload(); }catch(err){ }
            },
        })
    },
    get_user_info: function () {
        var request = {};
        request.entity = "user";
        request.method = "get_info";

        var context = this;

        do_authenticated_http_call({
            method: "POST",
            url: "/api/entity/invoke_method",
            dataType: 'json',
            data: request,
            success: function (data) {
                //context.setState({data:data.data, loading:false});

                var updated_state = {};
                updated_state.data = data.data;
                if (context.state.secret_questions) {
                    context.props.handle_executing(false);
                    updated_state.loading = false;
                }
                context.setState(updated_state);
            },
            error: function (error) {
                server_error_action(error);
                if (error.responseJSON) {
                    context.setState({error: error.responseJSON});
                   // var logged_in=true;try{logged_in= error.responseJSON.logged_in; if(logged_in==false) location.reload(); }catch(err){ }
                }
            },
            complete: function () {
            }
        });
    },
    getInitialState: function () {
        this.get_user_info();
        this.get_billing_data();
        this.get_tracfile_plans();
        this.get_countries();
        var context = this;
        setTimeout(function(){
            context.props.handle_executing(true);
        }, 100);
        return {loading: true, update: {}, avatar: this.props.avatar};
    },
    on_error_close: function () {
        this.setState({error: undefined});
    },
    handle_close: function (event) {
        if (Object.keys(this.state.update).length == 0) {
            this.redirect_on_close();
            return;
        }
        var context = this;
        if (!context.confirming){
            context.confirming = true;
            return display_yes_no_dialogue(R.label_confirm, R.message_changes_discarded, function (confirmation) {
                context.confirming = false;
                if (confirmation) {
                    context.redirect_on_close();
                }
            });
        } else { return false;}
    },
    redirect_on_close: function () {
        this.props.navigation_handler("home");
    },
    handle_submit_billing: function (event) {
        event.preventDefault();
        var request = {};
        request.entity = "user";
        request.method = "update_billing_info";

        var context = this;
        if(!Stripe.card.validateCardNumber($("#creditnumber").val()))
        {
            context.setState({error: R.invalid_card_number});
            return;
        }
        if(!Stripe.card.validateCVC($("#creditcvc").val()))
        {
            context.setState({error: R.invalid_cvc_number});
            return;
        }

        if(!Stripe.card.validateExpiry($("#creditmonth").val(), $("#credityear").val()))
        {
            context.setState({error: R.invalid_expiry_date});
            return;
        }
        Stripe.card.createToken(event.currentTarget, function (status, response) {
            if(status != 200)
            {
                response = response.error;
                context.setState({error: response.message});
                return;
            }
            else {
                var idempotency_key = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for( var i=0; i < 5; i++ )
                { idempotency_key += possible.charAt(Math.floor(Math.random() * possible.length));}

                context.state.billing_data.data.card_id = response.card.id;
                context.forceUpdate();;
                request.data =  context.state.billing_data.data
                request.data={};
                request.data.billing_token =response.id;
               // request.data.idempotency_key = "";
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/entity/invoke_method",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: function (data) {
                        var avatar = context.state.avatar;
                        if (context.state.update.avatar || context.state.update.avatar == null) {
                            avatar = context.state.update.avatar;
                            context.props.update_avatar();
                        }
                        context.props.update_user_info();
                        context.setState({update: {}, error: undefined, avatar: avatar});
                        Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                        context.handle_close();
                    },
                    error: function (error) {
                        server_error_action(error);
                        if (error.responseJSON) {
                            console.log(error.responseJSON);
                            context.setState({error: error.responseJSON.message})
                            //var logged_in=true;try{logged_in= error.responseJSON.logged_in; if(logged_in==false) location.reload(); }catch(err){ }
                        }
                    },
                    complete: function () {
                        context.props.handle_executing(false);
                        context.setState({loading: false, update: {}});
                    }
                });

            }
        });

    },
    handle_submit_subscription: function (event) {
        event.preventDefault();
        var request = {};
        request.entity = "user";
        request.method = "update_billing_info";
        var context = this;
        var idempotency_key = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for( var i=0; i < 5; i++ )
        { idempotency_key += possible.charAt(Math.floor(Math.random() * possible.length));}
        context.forceUpdate();
        request.data={};
       // request.data.idempotency_key = "";
        request.data.plan_id = event.target[0].value;
            do_authenticated_http_call({
                method: "POST",
                url: "/api/entity/invoke_method",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(request),
                success: function (data) {
                    var avatar = context.state.avatar;
                    if (context.state.update.avatar || context.state.update.avatar == null) {
                        avatar = context.state.update.avatar;
                        context.props.update_avatar();
                    }
                    context.props.update_user_info();
                    context.setState({update: {}, error: undefined, avatar: avatar});
                    Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                },
                error: function (error) {
                        server_error_action(error);
                    if (error.responseJSON) {
                        console.log(error.responseJSON);
                        context.setState({error: error.responseJSON.message})
                        //var logged_in=true;try{logged_in= error.responseJSON.logged_in; if(logged_in==false) location.reload(); }catch(err){ }
                    }
                },
                complete: function () {
                    context.props.handle_executing(false);
                    context.setState({loading: false, update: {}});
                }
            });
    },
    on_cardnumber_change: function (event) {
        $("#creditnumber").bootstrapValidator();
    },
    on_cvc_change: function (event) {
        var element = $('#creditcvc');
        var new_value = element.val();
        if (!new_value.match(/^\d{0,3}$/g)) {
            new_value = new_value.replace(/\D/g, '');
            new_value = new_value.substr(0,3);
            element.val(new_value);
        }
        element.bootstrapValidator();
    },
    on_cardmonth_change: function (event) {
        var element = $('#creditmonth');
        var new_value = element.val();
        if (!new_value.match(/^\d{0,2}$/g)) {
            new_value = new_value.replace(/\D/g, '');
            new_value = new_value.substr(0,2);
            element.val(new_value);
        }
        element.bootstrapValidator();
    },
    on_cardyear_change: function (event) {
        var element = $('#credityear');
        var new_value = element.val();
        if (!new_value.match(/^\d{0,4}$/g)) {
            new_value = new_value.replace(/\D/g, '');
            new_value = new_value.substr(0,4);
            element.val(new_value);
        }
        element.bootstrapValidator();
    },
    on_cardname_change: function (event) {
        var element = $("#creditname");
        var value = event.target.value;
        if(value.match(    /([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_address1_change: function (event) {
        $("#creditaddress1").bootstrapValidator();
    },
    on_address2_change: function (event) {
        $("#creditaddress2").bootstrapValidator();
    },
    on_cardcity_change: function (event) {
        var element = $("#creditcity");
        var value = event.target.value;
        if(value.match(    /([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_cardcountry_change: function (event) {
        var element = $("#creditcountry");
        var value = event.target.value;
        if(value.match(    /([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_cardstate_change: function (event) {
        var element = $("#creditstate");
        var value = event.target.value;
        if(value.match(    /([_+,@#$%^&*();\\|<>"¿?¡!{}\[\]/])/gi)){
            element.val(value.substr(0, value.length - 1));
        } else
            element.bootstrapValidator();
    },
    on_cardzip_change: function (event) {
        $("#creditzip").bootstrapValidator();
    },
    on_plan_change: function (event) {
        this.setState({plan_selected: event.target.value});
        $("#plans").bootstrapValidator();
    },

    render: function () {

        if (this.state.loading) {
            return null;
        }
        else {
            //show modal after everything is rendered
            $("#modal_form").css( "visibility", "visible" );
            var suscription_plans = [];
            if (this.state.suscription_plans) {

                for (var i = 0; i < this.state.suscription_plans.length; i++) {
                    var suscription_plan = this.state.suscription_plans[i];
                    suscription_plans.push(<option key={suscription_plan.code}
                                                   value={suscription_plan.code}>{suscription_plan.value}</option>);
                }
            }

            var submitstyle = {
                "width": "auto",
                "marginRight": "15px"
            };

            var countries = [];
            if (this.state.countries) {
                for (var j = 0; j < this.state.countries.length; j++) {
                    var country = this.state.countries[j];
                    countries.push(<option key={country.code.toUpperCase()}
                                           value={country.code.toUpperCase()}>{country.value}</option>);
                }
            }
            var error_component = "";
            if (this.state.error) {
                error_component =
                    <ErrorNotificationComponent message={this.state.error} on_close={this.on_error_close}/>
            }
            var colorSkip='rgba(96,96,96,1)';var displayDescription='';  var marginRightskip="10px";
            var close_button = <i  style={{
                                            cursor : "pointer" ,
                                            color : colorSkip,
                                            display : displayDescription,
                                            fontSize : "14.7px",
                                            marginRight:"10px"
                                            }}
                                   id="close-button"
                                   onClick={this.handle_close}
                                   className="fa fa-times"
                                   aria-hidden="true"
                                   is-nested="true">&nbsp;</i>;

            var submit_button = <input type="submit"  style={submitstyle} className="btn btn-primary" value={R.label_submit}/>;


            return (<div className="widget">
                <div className="widget-header bordered-bottom bordered-palegreen">
                    <span className="widget-caption">
                        Billing settings
                    </span>
                    <div className="widget-buttons">
                        <span className="widget-caption">
                            {close_button}
                        </span>
                    </div>
                </div>
                <div className="widget-body">
                    <div>
                        {error_component}
                        <form id="update_subscription" role="form"
                              onSubmit={this.handle_submit_subscription}>
                            <div className="form-group col-sm-12" >
                                <div style={{"fontSize":"15px","marginTop":"10px"}}>
                                    Update Your Plan
                                </div>
                            </div>
                            <div className="form-group col-sm-12">
                                <select className="form-control"
                                        id="plans"
                                        required
                                        name="plan"
                                        defaultValue={this.state.billing_data.data.plan_id}
                                        onChange={this.on_plan_change}>
                                    <option disabled key="" value="">
                                        {R.select_your_plan}
                                    </option>
                                    {suscription_plans}
                                </select>
                            </div>
                            <div style={{"textAlign":"right"}}>
                                <input
                                    type="submit"
                                    style={submitstyle}
                                    className="btn btn-primary"
                                    value="Update plan"/>
                            </div>
                        </form>
                        <form id="update_address" role="form"
                              onSubmit={this.handle_submit_billing}>

                            <div className="form-group col-sm-12" >
                                <div style={{"fontSize":"15px","marginTop":"10px"}}>
                                    Update Payment Information
                                </div>
                            </div>
                            <div className="form-group col-sm-4" >
                                <input className="form-control"
                                       type="number"
                                       required
                                       size="20"
                                       data-stripe="number"
                                       placeholder={(this.state.billing_data.data.last_four_digits) ? "**** **** **** " + this.state.billing_data.data.last_four_digits : ""}
                                       id="creditnumber"
                                       onChange={this.on_cardnumber_change}
                                />
                            </div>
                            <div className="form-group col-sm-2" >
                                <input className="form-control"
                                       type="number"
                                       required
                                       size="4"
                                       data-stripe="cvc"
                                       placeholder={R.cvc}
                                       id="creditcvc"
                                       onChange={this.on_cvc_change}

                                />
                            </div>
                            <div className="form-group col-sm-3" >
                                <input className="form-control"
                                       required
                                       data-stripe="exp-month"
                                       placeholder={R.exp_month}
                                       id="creditmonth"
                                       onChange={this.on_cardmonth_change}
                                />
                            </div>
                            <div className="form-group col-sm-3" >
                                <input className="form-control"
                                       type="number"
                                       required
                                       size="4"
                                       data-stripe="exp-year"
                                       placeholder={R.exp_year}
                                       id="credityear"
                                       onChange={this.on_cardyear_change}
                                />
                            </div>
                            <div className="form-group col-sm-12" >
                                <input className="form-control" type="text" size="4"
                                       data-stripe="name"
                                       required
                                       defaultValue={this.state.billing_data.data.name}
                                       placeholder={R.name_credit_card}
                                       id="creditname"
                                       onChange={this.on_cardname_change}
                                />
                            </div>
                            <div className="form-group col-sm-12" >
                                <input className="form-control"
                                       type="text"
                                       defaultValue={this.state.billing_data.data.address_line1}
                                       required
                                       size="4"
                                       data-stripe="address_line1"
                                       placeholder={R.address_line_1}
                                       id="creditaddress1"
                                       onChange={this.on_address1_change}
                                />
                            </div>
                            <div className="form-group col-sm-12" >
                                <input className="form-control"
                                       type="text"
                                       size="4"
                                       data-stripe="address_line2"
                                       defaultValue={this.state.billing_data.data.address_line2}
                                       placeholder={R.address_line_2}
                                       id="creditaddress2"
                                       onChange={this.on_address2_change}
                                />
                            </div>
                            <div className="form-group col-sm-6" >
                                <select className="form-control"
                                        required
                                        data-stripe="address_country"
                                        name="country"
                                        defaultValue={this.state.billing_data.data.address_country}
                                        onChange={this.on_cardcountry_change}>
                                    <option disabled key="" value="">{R.select_country}</option>
                                    {countries}
                                </select>
                            </div>
                            <div className="form-group col-sm-6" >
                                <input className="form-control"
                                       type="text"
                                       required
                                       size="4"
                                       defaultValue={this.state.billing_data.data.address_city}
                                       data-stripe="address_city"
                                       placeholder={R.city}
                                       id="creditcity"
                                       onChange={this.on_cardcity_change}
                                />
                            </div>
                            <div className="form-group col-sm-6" >
                                <input className="form-control"
                                       type="text"
                                       defaultValue={this.state.billing_data.data.address_state}
                                       required
                                       size="4"
                                       data-stripe="address_state"
                                       placeholder={R.state}
                                       id="creditstate"
                                       onChange={this.on_cardstate_change}
                                />
                            </div>
                            <div className="form-group col-sm-6" >
                                <input className="form-control"
                                       type="text"
                                       defaultValue={this.state.billing_data.data.address_zip}
                                       required
                                       size="4"
                                       data-stripe="address_zip"
                                       placeholder={R.zip_code}
                                       id="creditzip"
                                       onChange={this.on_cardzip_change}
                                />
                            </div>

                            <div style={{"textAlign":"right"}}>
                                <input type="submit"  style={submitstyle} className="btn btn-primary" value="Update payment"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>);
        }
    }
});


