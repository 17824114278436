/**
 * ========================================================
 * Description: Component to render a dashboard, separate
 *              from Home dashboard, but mirroring much
 *              of the same functionality.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1       ?           ?           ?           Created
 * 2    05/05/16     Victor Hernandez          Small refactoring for increased JS efficiency.
 * 3    06/27/16     AMcDaniel    97216497     Added code to skip pushing to window location for app_objects
 * 4    09/09/16     AMcDaniel    109177071    Replaced makeid logic with general_utils create_guid and create_uid
 * ========================================================
 **/
var DashboardComponent = React.createClass({
    components: {},
    getInitialState: function() {
        var context = this;
        setTimeout(function() {
            context.initialize_dashboard();
        }, 0);

        return {};
    },
    componentDidUpdate: function() {

        if (Object.keys(this.components).length == 0) {
            return;
        }

        var context = this;

        var options = {
            animate: true,
            float: true,
            width: 12,
            cell_height: 80,
            vertical_margin: 10
        };

        var gridstack = $("#dashboard-dashboards").gridstack(options).data('gridstack');

        for (var key in this.components) {
            var component = this.components[key];
            var dashboard_part = component.dashboard_part;
            var component_element = $("#" + component.div_id);
            var properties = {};
            var value_field = null;
            var argument_field = null;
            var tile_html = '';
            gridstack.add_widget('' +
                '<div class="widget no-header dashboard-part">' +
                    '<div class="grid-stack-item-content widget-body">' +
                        '<div id="'+component.div_id+'">' +
                        '</div>' +
                    '</div>' +
                '</div>', 0, 0, dashboard_part.width, dashboard_part.row_number, true);

            var type = dashboard_part.visualization_type;
            switch (type) {
                case "pie_chart":
                    properties.dataSource = dashboard_part.aggregation_data;
                    properties.title = dashboard_part.caption;
                    properties.legend = {visible:false};
                    properties.animation = {enabled: true};
                    properties.resolveLabelOverlapping = "shift";
                    properties.series = [{
                        argumentField: dashboard_part.custom_properties.argumentField,
                        valueField: dashboard_part.custom_properties.valueField,
                        label: {
                            visible: true,
                            customizeText: function(arg) {
                                return arg.argumentText + " (" + arg.valueText + ")";
                            }
                        }
                    }];

                    if (dashboard_part.linked_app_object_code) {
                        properties.onPointClick = function() {
                            var app_object = {};
                            app_object.type = "data_list";
                            app_object.code = dashboard_part.linked_app_object_code;
                            return context.props.app_object_handler(app_object);
                        }
                    }

                    component_element.dxPieChart(properties);
                    break;
                case "line_chart":
                    properties.dataSource = dashboard_part.aggregation_data;
                    properties.title = dashboard_part.caption;
                    properties.legend = {verticalAlignment:"bottom", horizontalAlignment:"center", itemTextPosition:"bottom"};
                    properties.animation = {enabled: true};
                    properties.resolveLabelOverlapping = "shift";
                    properties.commonSeriesSettings = {argumentField:dashboard_part.custom_properties.argumentField, type:"line"};
                    properties.series = [{
                        valueField: dashboard_part.custom_properties.valueField,
                        name: dashboard_part.custom_properties.name_caption
                    }];

                    if (dashboard_part.linked_app_object_code) {
                        //var context = this;
                        properties.onPointClick = function() {
                            var app_object = {};
                            app_object.type = "data_list";
                            app_object.code = dashboard_part.linked_app_object_code;
                            return context.props.app_object_handler(app_object);
                        }
                    }

                    component_element.dxChart(properties);
                    break;
                case "bar_chart":
                    //var properties = {};
                    properties.dataSource = dashboard_part.aggregation_data;
                    properties.title = dashboard_part.caption;
                    properties.animation = {enabled: true};
                    properties.series = [{
                        argumentField: dashboard_part.custom_properties.argumentField,
                        valueField: dashboard_part.custom_properties.valueField,
                        name: dashboard_part.custom_properties.name_caption,
                        type: "bar",
                        color: dashboard_part.custom_properties.color
                    }];

                    if (dashboard_part.linked_app_object_code) {
                        //var context = this;
                        properties.onPointClick = function(event) {
                            var app_object = {};
                            app_object.type = "data_list";
                            app_object.code = dashboard_part.linked_app_object_code;
                            return context.props.app_object_handler(app_object);
                        }
                    }

                    component_element.dxChart(properties);
                    break;
                case "ranked_list":
                    value_field = dashboard_part.custom_properties.valueField;
                    argument_field = dashboard_part.custom_properties.argumentField;
                    var ol = '<ol class="dashboard-ranked-list-ol">';
                    for (var i = 0; i < aggregation_data.length; i++) {
                        ol += '<li>' + aggregation_data[i][value_field] + ' - ' + aggregation_data[i][argument_field] + '</li>';
                    }
                    ol += '</ol>';
                    tile_html = "<div>";
                    tile_html += "<span class='dashboard-part-tile-title' title='" + dashboard_part.custom_properties.name_caption + "'>" + dashboard_part.custom_properties.name_caption + "</span>";
                    tile_html += "<div class='bg-white no-padding'>";
                    tile_html += ol;
                    tile_html += "</div></div>";
                    jquery_element.html(tile_html);
                    if (dashboard_part.linked_app_object_code) {
                        $("#" + component.div_id + " .dashboard-part-tile-body").click(function (e) {
                            var app_object = {};
                            app_object.type = "data_list";
                            app_object.code = dashboard_part.linked_app_object_code;
                            return context.props.app_object_handler(app_object);
                        });
                    }
                    break;
                case "tile":
                    value_field = dashboard_part.custom_properties.valueField;
                    var data_point = aggregation_data[0][value_field];

                    argument_field = dashboard_part.custom_properties.argumentField;
                    var argument_name = aggregation_data[0][argument_field];

                    var data_point_color;
                    if (dashboard_part.custom_properties && dashboard_part.custom_properties.data_point_color) {
                        data_point_color = dashboard_part.custom_properties.data_point_color;
                    }
                    else {
                        data_point_color = "black";
                    }
                    var fontSize = 4;
                    if (data_point.toString().length <= 6) {
                        fontSize = (12 - data_point.toString().length);
                    } else if (data_point.toString().length <= 8) {
                        fontSize = 6;
                    }
                    else if (data_point.toString().length <= 10) {
                        fontSize = 5;
                    }
                    tile_html = "<div class=''>";
                    tile_html += "<div>";
                    tile_html += "<span class='dashboard-part-tile-title' title='" + argument_name + "'>" + argument_name + "</span>";
                    tile_html += "<div class='bg-white no-padding'>";

                    tile_html += "<h1 style='color:" + data_point_color + ";font-size:" + fontSize + "vw' class='dashboard-part-tile-body'>" + data_point + "</h1>";

                    tile_html += "</div></div></div>";

                    jquery_element.html(tile_html);

                    if (dashboard_part.linked_app_object_code) {
                        $("#" + component.div_id + " .dashboard-part-tile-body").click(function (e) {
                            var app_object = {};
                            app_object.type = "data_list";
                            app_object.code = dashboard_part.linked_app_object_code;
                            return context.props.app_object_handler(app_object);
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    },
    initialize_dashboard: function() {
        var context = this;

        var success = function(data) {
            context.setState({app_object:data.app_object});

            if(!(context.props && context.props.skip_location_push)) {
                push_href_location(data.app_object.name + " - " + (R.client_application_title || "Xenforma"), "/dashboard?code=" + data.app_object.code);
            }
        };

        var error = function(err) {

            this.setState({error:err.message});
            //var logged_in=true;try{logged_in= err.logged_in; if(logged_in==false) location.reload(); }catch(err){ }

        };

        get_app_object(this.props.app_object_code, {}, success, error);
    },
    render: function() {
        this.components = {};
        for (var i = 0; this.state.app_object && i < this.state.app_object.dashboard_parts.length; i++) {
            var dashboard_part = this.state.app_object.dashboard_parts[i];
            var div_id = create_uid();
            this.components[div_id] = {};
            this.components[div_id].div_id = div_id;
            this.components[div_id].dashboard_part = dashboard_part;
        }

        var error_component = "";
        if (this.state.error) {
            error_component = <div><ErrorNotificationComponent message={this.state.error} on_close={this.on_error_close}/><br /></div>
        }

        return (
            <div className="form-group">
                {error_component}
                <div id="dashboard-dashboards" className="grid-stack"></div>
            </div>
        );
    }
});
