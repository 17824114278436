/**
 * ========================================================
 * Description: web control for picking a date (no time)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author               Task        Description
 * ========================================================
 * 1       ?           ?                    ?           Created
 * 2       03/21/16    andrew.gay           84813463    Fixes date to be a string on value changed
 * 3       03/34/16    andrew.gay           790562      Changed to use the same fix_response_data function
 * 4       03/31/16    andrew.gay           84813463    Temp change, will come back and remove when refactoring
 * 5       03/31/16    andrew.gay           84813463    Removed conversion
 * 6       07/01/16    Victor Hernandez     98735752    Style changes for date and date time components, this settings are now handled by devExtreme
 * 7       14/07/16    Victor Hernandez     99726451    Field validation clears value if there's an error
 * ========================================================
 **/
var EditorDevExtremeDatePicker = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            this.state.element.dxDateBox('instance').option("value", this.state.value);
        }
    },
    componentDidMount: function () {
        var context = this;
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxDateBox({
            type: "date",
            readOnly: this.props.readOnly,
            value: this.state.value,
            width: 'auto',
            onValueChanged: function (data) {
                context.state.value = data.value;
                context.props.onChange(context.state.value);
            },
            onChange: function (e) {
                var element =$(context.domElement).find('input');
                var value = element[0].value;
                if(moment(new Date(value)).isValid() && context.state.element.dxDateBox('instance').option('isValid')){
                    context.state.value = value;
                } else {
                    element[0].value = '';
                    context.state.value = '';
                    context.props.onChange(context.state.value);
                }
            },
            onFocusOut: function () {
                context.props.onBlur();
            }
        });
    },
    render: function () {
        var context = this;
        return <div style={{width:"auto"}} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    }
});
