"use strict";
/**
 * ==================================================================================
 * Description:       desc
 * Creation Date:     8/3/2016
 * Author:            AMcDaniel
 * ==================================================================================
 * Revision History
 * ==================================================================================
 * Rev     Date        Author      Task        Description
 * ==================================================================================
 * 1       08/03/16    AMcDaniel   100948453   Created convert_results_to_calendar_fields method to be shared with server and client-side.
 * 2       08/15/16    AMcDaniel   104457614   Added code to parse out elements of stored RRULE which are causing parsing errors on client-side calendar widget
 * 3       08/15/16    AMcDaniel   104457614   Added temporary code to fail RRULE parsing so that calendar screen remains usable
 * 4       08/17/16    Victor H    104457614   Removed temporary code to fail RRULE parsing. Added a startDate default value so the calendar can display the recurrent events.
 * 5       08/17/16    AMcDaniel   104457614   Small change for readability
 * ==================================================================================
 */


function convert_results_to_calendar_fields(calendar_fields_array, entity_inst_array, attributes, for_client_side) {
    var ret_val = [];
    var entity_instance, value;
    var caption_fields = attributes.caption_fields;

    for (var i = 0, u = entity_inst_array.length; i<u;i++){
        entity_instance = entity_inst_array[i];
        for (var j = 0, v = calendar_fields_array.length; j < v; j++){
            var single_set_of_calendar_fields = calendar_fields_array[j];
            value = {
                record:entity_instance,
                resources:[entity_instance[single_set_of_calendar_fields.resources_field] || 'none'],
                text:single_set_of_calendar_fields.caption_field?entity_instance[single_set_of_calendar_fields.caption_field]:make_friendly_caption(caption_fields, entity_instance, attributes.attributes) + ' ' + attributes.field_paths[single_set_of_calendar_fields.start_datetime_field].caption,
                allDay:false
            };
            if (entity_instance[single_set_of_calendar_fields.recurrence_field]){
                value.recurrenceRule = entity_instance[single_set_of_calendar_fields.recurrence_field];
                if(for_client_side && value.recurrenceRule) {
                    value.recurrenceRule = fix_recurrence_rule_format(value.recurrenceRule);
                }
                value.startDate = entity_instance[single_set_of_calendar_fields.start_datetime_field];
            }
            else{
                value.startDate = entity_instance[single_set_of_calendar_fields.start_datetime_field];
                value.endDate = entity_instance[single_set_of_calendar_fields.end_datetime_field];
                if (!value.endDate){
                    value.endDate = new Date(value.startDate.getTime() + 1800000);
                }
            }
            ret_val.push(value);
        }
    }
    return ret_val;
}


function get_attribute_by_field_path(attribute_arr, field_path) {
    if(!attribute_arr || (attribute_arr.length == 0)) {
        return null;
    }
    for(var i = 0; i < attribute_arr.length; i++) {
        var attribute = attribute_arr[i];
        if(attribute && attribute.field_path && (attribute.field_path == field_path)) {
            return attribute;
        }
    }
}

function get_caption_for_single_field(attribute, field_value) {
    if(field_value != null) {
        if (typeof field_value == "object") {
            if (field_value.caption) { //use caption if defined
                field_value = field_value.caption;
            }
            else {
                field_value = null;
            }
        }
        else if (attribute) {
            if(attribute.list_of_values && Array.isArray(attribute.list_of_values)) {
                var index = attribute.list_of_values.map(function(kv) {return kv.code}).indexOf(field_value);
                field_value = attribute.list_of_values[index].value;
            }
            else if (attribute.db_type == "Date") {
                field_value = new Date(field_value).toDateString();
            }
        }
    }
    return field_value;
}

function make_friendly_caption(caption_fields, entity_values, attribute_arr) {
    var ret_val = "";
    for(var i = 0; i < caption_fields.length; i++) {
        var complex_path = caption_fields[i];
        var field_value = entity_values[complex_path];
        if(field_value != null) {
            var attribute = get_attribute_by_field_path(attribute_arr, complex_path);
            field_value = get_caption_for_single_field(attribute, field_value);
            if (field_value != null) {
                ret_val = ((ret_val) ? ret_val + " " + field_value : field_value);
            }
        }
    }
    return ret_val;
}

/**
 * Currently, the calendar does not function if the RRULE value is preceeded by "RRULE:" or if it has a BYSETPOS value specified
 * @param rrule_string
 * @returns {*|string}
 */
function fix_recurrence_rule_format(rrule_string) {
    if(rrule_string) {
        var ret_string = remove_rrule(rrule_string);
        var index_of_bysetpos = ret_string.indexOf(";BYSETPOS=1");
        if (index_of_bysetpos != -1) {
            ret_string = ret_string.substring(0, index_of_bysetpos) + ret_string.substring(index_of_bysetpos + ";BYSETPOS=1".length);
        }
        return ret_string;
    }
}


/**
 * Small helper function to remove the "RRULE:" from the rrule string
 * @param rrule_string
 * @returns {*|string}
 */
function remove_rrule(rrule_string) {
    if(rrule_string && (rrule_string.indexOf("RRULE:") == 0)) {
        rrule_string = rrule_string.substring(6);
    }
    return rrule_string;
}


if(typeof exports == "undefined")
{
    var exports;
}
if(!exports) { //intended for eventually sharing code with client-side
    exports = {};
}

exports.convert_results_to_calendar_fields = convert_results_to_calendar_fields;