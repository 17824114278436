"use strict";
/**
 ==================================================================================
 Description:       dx Lookup field
 Creation Date:     4/27/2016
 Author:            Sebastian Mansfield
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author                  Task        Description
 ==================================================================================
 1     04/27/16     Sebastian Mansfield     TaskNumber  Created
 2     06/23/16     Victor Hernandez        98405810    Added flags to attributes and lookup dataSources to prevent unnecesary requests being made. Also updated the way lookups trigger the onBlur event for a more predictable behaviour.
 3     07/06/16     Victor Hernandez        100097523   Fixed a small bug that prevented proper updating of the component.
 4.    07/27/16     Sebastian Mansfield                 Added tab an enter functionality to lookups
 5     08/01/16     Victor Hernandez        98992528    Fixed a bug causing lookups to crash when an item has an empty caption.
 6     08/02/16     Victor Hernandez        103785614   Changed tab and enter functionality so that it selects the highlighted option instead of the first on the list.
 7     08/15/16     AMcDaniel               105538698   Added has_pressed_any_key flag to prevent running update logic when value was not intended to be changed (click in, click out)
 8     08/16/16     AMcDaniel               106187888   Limited has_pressed_any_key flag to data_list editing
 9     08/22/16     AMcDaniel               105538698   Fixed a bug in data_list with tab-out resetting field to the first element of the results
 10    08/25/16     Victor Hernandez        106363509   Fixed a bug causing lookups to crash the page when certain characters were used as a search parameter.
 11    08/29/16     AMcDaniel               107627148   Implemented rough algorithm for content-based sizing. Fixed an intermittent crash.
 12    08/30/16     AMcDaniel               107627148   Improved previously mentioned algorithm
 13    09/14/16     AMcDaniel               109177071   Added ability to turn off certain sections of this code that were misbehaving
 14    09/28/16     AMcDaniel               112941853   Added onDisposing property
 ==================================================================================
 */

var EditorDevExtremeLookup = React.createClass({
    getInitialState: function () {
        return {has_pressed_any_key: !(this.props && this.props.is_in_grid_edit)};
    },
    componentWillReceiveProps: function (nextProps) {
        if (this.state.element && !this.props.skip_prop_update_function){
            try{
                var element = this.state.element;
                if (nextProps.value != this.state.value) {
                    this.state.value = nextProps.value;
                    element.option("value", nextProps.value);
                } else {
                    element.repaint();
                }
            } catch(e){
                console.log(e);
            }
        }
    },
    componentDidMount: function () {
        var context = this;
        context.val = 0;
        context.selection = 0;
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxSelectBox({
            readOnly: this.props.readOnly,
            value: this.state.value,
            displayExpr: this.props.displayExpr,
            valueExpr: this.props.valueExpr,
            dataSource: this.props.dataSource,
            placeholder: this.props.placeholder,
            minSearchLength :1,
            searchEnabled: true,
            showClearButton: true,
            showDropButton:false,
            showDataBeforeSearch:false,
            deferRendering:true,
            searchTimeout:250,
            onDisposing: this.props.onDisposing,
            onContentReady : function (ev) {
                var list = $(ev.component._$list).find('.dx-scrollview-content').children();
                if (list.length) {
                    var css_list = [];
                    for(var i = 0; i < list.length; i++) {
                        var list_item = list[i];
                        if(list_item && list_item.innerHTML && list_item.innerHTML.length) {
                            var o = $('<div>' + list_item.innerHTML + '</div>') //find the width of this DOM element
                                .css({'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden'})
                                .appendTo($('body')),
                                w = o.width();
                            o.remove();
                            //or:
                            /*this.hidden_canvas_context = this.hidden_canvas_context || (document.createElement("canvas").getContext("2d"));
                            this.hidden_canvas_context.font = font;
                            var w = this.hidden_canvas_context.measureText(list_item.innerText).width;*/
                            css_list.push(w || (list_item.innerText.length * 10));
                        }
                    }
                    var max_width = css_list.reduce(function(max_length, current_value) {if(current_value && (current_value > max_length)) {return current_value;} return max_length;}, 0);
                    setTimeout(function() {
                        try {
                            ev.component.content().parent().width(max_width + 25); //Add 25 to projected width, just to prevent an accidental cutoff
                        }
                        catch(ex) {

                        }
                    });
                }
            },
            onKeyDown: function(args){
                context.state.has_pressed_any_key = true;
                if (!context.props.skip_prop_update_function) {
                    var event = args.jQueryEvent,
                        list = $(args.component._$list).find('.dx-scrollview-content').children();
                    if ((event.key !== "Tab" && event.key !== "Enter") || context.props.is_in_grid_edit) {
                        return;
                    }

                    var selectBox = args.component;
                    var items = selectBox.option("items");
                    if (items.length && selectBox.option("text") && selectBox.option("text") !== selectBox.option("value")) {
                        for (var i = 0, u = list.length; i < u; i++) {
                            if (list[i].id) {
                                if (items[i][context.props.valueExpr] != selectBox.option('value')) {
                                    selectBox.option("value", items[i][context.props.valueExpr]);
                                    console.log(items[i]);
                                    return;
                                }
                            }
                        }
                        if (list.length) {
                            selectBox.option("value", items[0][context.props.valueExpr]);
                        }
                    }
                }
            },
            itemTemplate: function(itemData) {
                var searchString = context.state.element.option('text');
                if(searchString) {
                    searchString = searchString.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
                }
                else {
                    searchString = "";
                }
                return itemData[context.props.displayExpr]?itemData[context.props.displayExpr].replace(new RegExp(searchString, 'gi'), '<b>' + searchString + '</b>'):'';
            },
            onValueChanged: function (data) {
                if (data.value == "")
                    console.log(data);
                var value = data.value;
                context.state.value = value;
                var dataValue = {};
                if (typeof data.selectedItem != "undefined" && data.selectedItem != null) {
                    dataValue = data.selectedItem;
                }
                if (context.props.currentInstance) {
                    var currentInstance = context.props.currentInstance;
                    for (var i = 0; i < currentInstance.length; i++) {
                        if (currentInstance[i].entity_id == value) {
                            if (currentInstance[i].entity_name) {
                                dataValue["entity_name"] = currentInstance[i].entity_name;
                                break;
                            }
                        }
                    }
                }
                if (value == null) {
                    if(context.state.has_pressed_any_key) {
                        context.props.onChange(null);
                    }
                }
                else {
                    context.state.has_pressed_any_key = true;
                    dataValue[context.props.valueExpr] = value;
                    if (!dataValue) {
                        this.dataSource.searchValue(value);
                        this.dataSource.searchExpr(data.valueExpr);
                        this.dataSource.searchOperation('exactly');
                        this.dataSource.load().done(function (dsData) {
                            var valueObject = find_by_field(data.valueExpr, value, dsData);
                            if (valueObject) {
                                data.itemData = valueObject;
                                data.selectedItem = valueObject;
                                context.props.onChange(valueObject);
                            }
                        })
                    } else {
                        if (dataValue.code) {
                            context.props.onChange(dataValue.code);
                        }
                        else {
                            context.props.onChange(dataValue);
                        }

                    }
                }

                if ('function' == typeof context.props.onBlur){
                    context.props.onBlur(data);
                }
                context.state.element.focus();
            }
        }).dxSelectBox('instance');
    },
    render: function () {
        var context = this;
        var className = "";
        if (this.props.add_button) {
            className = "add-button-margin";
        }else{
            if (this.props.edit_button) {
                className = "add-less-button-margin";
            }

        }

        /*if (this.props.edit_button) {
         className = "add-less-button-margin";
         }*/
        return <input class="form-control"/>,<div id='dxSelectBox' className={className} ref={function(ref){context.domElement = ref}}/>;
    }
});
