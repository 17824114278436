/**
 * ========================================================
 * Description: A web control for picking a Date with a Time
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author             Task        Description
 * ========================================================
 * 1       ?           ?                  ?           Created
 * 2     07/01/16    Victor Hernandez     98735752    Style changes for date and date time components, this settings are now handled by devExtreme
 * 3     07/01/16    Victor Hernandez     98735752    DateTime pickers are now shown as two separate objects.
 * 4     07/05/16    Victor Hernandez     99829501    Width tweak.
 * 5     07/06/16    Victor Hernandez     98304215    Null value handling fix.
 * 6     14/07/16    Victor Hernandez     99726451    Field validation clears value if there's an error
 * 7     15/07/16    JEscudero            100103248   Solved bug with bad formatted date.
 * 8     21/07/16    Victor Hernandez     Bug         Using a more robust clearing for invalid values.
 * ========================================================
 **/
var EditorDevExtremeDateTimePicker = React.createClass({
    width_buffer: 3,
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.value != this.state.value) {
            this.state.value = new Date(nextProps.value);
            this.state.dateElement.dxDateBox('instance').option("value", this.state.value);
            this.state.timeElement.dxDateBox('instance').option("value", this.state.value);
        }
    },
    onChange: function (data){
        var value = data.value?new Date(data.value):null,
            old_value = new Date(data.previousValue),
            context = this,
            val2,
            timeElement = context.state.timeElement.dxDateBox('instance'),
            dateElement = context.state.dateElement.dxDateBox('instance');
        if (value){
            if (data.component.option('format') == 'date'){
                val2 = context.state.timeElement.dxDateBox('instance').option('value');
                val2 = new Date(val2);
                value.setHours(val2.getHours());
                value.setMinutes(val2.getMinutes());
                value.setSeconds(val2.getSeconds());
                value.setMilliseconds(val2.getMilliseconds());
            }
            else {
                val2 = context.state.dateElement.dxDateBox('instance').option('value');
                val2 = new Date(val2);
                value.setFullYear(val2.getFullYear(), val2.getMonth(), val2.getDate());
            }
        }
        if(moment(value).isValid() && timeElement.option('isValid') && dateElement.option('isValid')){
            context.setState({value:value});
            if (value.toString() != old_value.toString()) {
                context.props.onChange(value);
            }
        } else {
            context.setState({value:null});
            if (data.component.option('format') == 'date'){
                context.state.timeElement.dxDateBox('instance').reset();
            } else{
                context.state.dateElement.dxDateBox('instance').reset();
            }
            context.props.onChange(null);
        }
    },
    onBlur: function (){
        this.props.onBlur();
    },
    componentDidMount: function () {
        this.state.value = this.props.value;
        this.state.dateElement = $(this.domElement).children('#date').dxDateBox({
            type: "date",
            readOnly: this.props.readOnly,
            value: this.state.value == null ? null : new Date(this.state.value),
            onValueChanged:this.onChange,
            onFocusOut: this.onBlur
        });
        this.state.timeElement = $(this.domElement).children('#time').dxDateBox({
            type: "time",
            readOnly: this.props.readOnly,
            value: this.state.value == null ? null : new Date(this.state.value),
            onValueChanged:this.onChange,
            onFocusOut: this.onBlur
            });
    },
    componentDidUpdate: function(){
        var time = $(this.state.timeElement),
            timeWidth = time.width(),
            parentWidth = time.parent().width();
        $(this.state.dateElement).dxDateBox('instance').option('width', parentWidth-timeWidth-this.width_buffer);
    },
    render: function () {
        var context = this;
        return <div ref={function(ref){context.domElement = ref}}>
            <div id="date" style={{display:"inline-block"}} ></div>
            <div id="time" style={{display:"inline-block"}} ></div>
        </div>;
    }
});
