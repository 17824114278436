"use strict";
/**
 ==================================================================================
 Description:       desc
 Creation Date:     6/8/2016
 Author:            SebasWebcreek
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author           Task                Description
 ==================================================================================
 1      6/8/2016     SebasWebcreek          TaskNumber          Created
 2      06/22/16     Sebastian Mansfield     97409797    Added template for autocomplete
 ==================================================================================
 */

var EditorDevExtremeAutoComplete = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        var element = this.state.element.dxAutocomplete('instance');
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            element.option("value", this.state.value);
        } else {
            //element.repaint();
        }
    },
    componentDidMount: function () {
        var context = this;
        var template = function (itemData) {
            return '<div>' +
                itemData.caption + '</div>';
        };
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxAutocomplete({
            readOnly: this.props.readOnly,
            value: this.state.value,
            displayValue: this.props.displayExpr,
            valueExpr: this.props.valueExpr,
            dataSource: this.props.dataSource,
            focusStateEnabled:true,
            itemTemplate : template,
            minSearchLength :1,
            onValueChanged: function (data) {
                context.state.value = data.value;
                if(context.state.value == null) {
                    context.props.onChange(null);
                } else {
                    context.props.onChange(data.value);
                }
            },
            onFocusOut: function () {
                context.props.onBlur();
            }
        });
    },
    render: function () {
        var context = this;
        var className = "";
        return <input class="form-control"/>,<div className={className} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    }
});
