"use strict";
/**
 ==================================================================================
 Description:       Tree View DevExtreme
 Creation Date:     6/24/2016
 Author:            Sebastian Mansfield
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author           Task                Description
 ==================================================================================
 1      6/24/2016     Sebastian Mansfield          TaskNumber          Created
 ==================================================================================
 */


var EditorDevExtremeTreeView = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        
        if (this.state.element){
            try{
                var element = this.state.element.dxTreeView('instance');
                if (nextProps.value != this.state.value) {
                    this.state.value = nextProps.value;
                    element.option("value", this.state.value);
                } else {
                    
                }
                if(nextProps.dataSource != this.state.dataSource)
                {
                    console.log('9');
                    this.state.element.dxTreeView('option', 'dataSource', nextProps.dataSource);

                }
            } catch(e){}
        }
    },
    componentDidMount: function () {
        var context = this;
        this.state.value = this.props.value;
        this.state.dataSource = this.props.dataSource;
        this.state.element = $(this.domElement).dxTreeView({
            readOnly: this.props.readOnly,
            value: this.state.value,
            dataSource: this.props.dataSource,
            onItemClick : this.props.onItemClick,
            showCheckBoxesMode: "none",
            onItemSelectionChanged: function (data) {

            }
        });
    },

    render: function () {
        var context = this;
        var className = "";
        if (this.props.add_button) {
            className = "add-button-margin";
        }
        return <input class="form-control"/>,<div className={className} ref={function(ref){context.domElement = ref}}/>;
    }
});
