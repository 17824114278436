/**
 * ========================================================
 * Description: Component to render an edit form for an
 *              entity.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author           Task        Description
 * ========================================================
 * 1    02/04/16    Vasil Galinovsky    Edit form   Added support for popup edit
 * 2    02/05/16    Vasil Galinovsky                Nested Edit Form Buttons    Only buttons from the current edit form are displayed
 * 3    02/05/16    Vasil Galinovsky    Edit form   Fixed missing button on nested edit form
 * 4    02/05/16    Vasil Galinovsky    Edit form   Removed file upload popup
 * 5    02/05/16    Vasil Galinovsky                Adding an entity reference in edit form should auto-place in tagbox    Added private files support for tagbox
 * 6    02/08/16    Vasil Galinovsky                File uploads try 'get_file' when newly created    Added is new instance flag to file upload editor
 * 7    02/10/16    Vasil Galinovsky                Errors cannot be "Closed"
 * 8    02/10/16    Vasil Galinovsky                Improve the Look of Comment form and make it consistent with other forms
 * 9    02/11/16    AMcDaniel           79528531    Handled file upload error message by removing setState to avoid redundant getDownloadUrl request.
 * 10   02/12/16    Vasil Galinovsky                Saving Nested Entity
 * 11   02/15/16    Vasil Galinovsky                B: Firefox cannot type a comment for Rejected Contracts    Replaced popup with new implementation
 * 12   02/11/16    AMcDaniel           Bug         Handled file upload skipping confirm message by adding a context override to current_navigation_listener because it (+ context) was being replaced by file's popup edit form.
 * 13   02/09/16    Sebastian Mansfield             User can now scroll after modal is closed in edit form,
 * 14   02/09/16    Sebastian Mansfield             Notes now check if the user has made a change before showing discard error
 * 15   02/11/16    Sebastian Mansfield             Wait until modal is loaded to show itself
 * 16   02/12/16    Sebastian Mansfield             Added 3 state checkbox and booleans, check for not requiered fields,send null values in 3 state fields
 * 17   02/16/16    Sebastian Mansfield             Added loading mask
 * 18   02/18/16    Sebastian Mansfield             Added on click functionality to file tags, Error handling for file tags, / edit form for files
 * 19   02/18/16    Sebastian Mansfield             Added prompt if user is trying to refresh page added error handling for images
 * 20   02/19/16    Sebastian Mansfield             Fixed file tags redundancies, and conflicts with undefined tags prevented same tags from being processed as new
 * 21   02/22/16    Sebastian Mansfield             Fixed error that made private files not attachable to edit_forms
 * 22   02/25/16    Sebastian Mansfield             FIxed error where private files were not editable
 * 23   02/25/16    Sebastian Mansfield             User can now edit recently added public and private files
 * 24   02/26/16    Sebastian Mansfield             user can now errase files using the delete button from the edit file form
 * 25   02/26/16    Sebastian Mansfield             User can no longer add the same file twice fixed white file in tag box bug after changes are being made
 * 26   02/29/16    Sebastian Mansfield             The file tag box now updates after description chanhge was made
 * 27   02/29/16    AMcDaniel           81984145    Made changes to nested entity upsert submits (for entities in arrays) to use the new format of only sending the changes.
 * 28   03/3/16     Sebastian Mansfield             Required field captions will appear red
 * 29   03/04/16    Sebastian Mansfield             Change the way delete data is sent for nested entities
 * 30   03/07/16    Sebastian Mansfield             Requiered input field will be light yellow removed version 28
 * 31   03/09/16    Sebastian Mansfield             Changed fields for roles to show role descriptions
 * 32   03/09/16    Vasil Galinovsky                17.8.CC.1 Automatically set invalid recurrent fields to null before sending to server
 * 33   03/10/16    Sebastian Mansfield             Fixed conflict with files and nested entities
 * 34   03/09/16    andrew.gay          82679450    Added a function to the success function that fixes dates that are strings before anything else can
 * 35   03/18/16    AMcDaniel           76793959    Added logic to execute_business_rule call (in on_field_blur) for setting parent_entity.
 * 36   03/21/16    andrew.gay          84813463    Added fix_response_data to on_blur to convert strings to dates before invoking
 * 37   03/24/16    andrew.gay          790562      Added fix_response_data to success function
 * 38   03/24/16    andrew.gay          790562      Added fix_response_data to success function
 * 39   03/24/16    Josue Navart        80519455    Dropdown functionality by creating a new element now auto-select
 * 40   03/24/16    AMcDaniel           80519455    Added update_from_response method to react class. Tied it to handle_submit for directly nested entities (att.data_is_nested==true).
 * 41   03/25/16    AMcDaniel           85252548    Added app_object_code parameter to invoke_method for handle_submit (non-nested) and tied it to context.props.return_app_object_code
 * 42   03/28/16    Josue Navarrte      85528185    Added Attribute "note_type" to "comment-footer" div, only display comments-footer when is a comment note_type
 * 43   03/28/16    Josue Navarrete     85475246    Fixed error when save information to form_edit, detect if selectNewItemDropdown function is undefind before to call a funtion selectNewItemDropdown
 * 44   03/30/16    Sebastian Mansfield             Refactored nested and referenced entity field change
 * 45   03/30/16    Sebastian Manfield              Referenced entities will send now only the id
 * 46   03/30/16    Josue Navarrete     85652962    Removed the call to server, 'get_by_code', created state 'not_get_by_code' like a flag, and update_state_form_edit function for create state
 * 47   03/31/16    andrew.gay          84813463    Added fix_response_data to all success functions, will refactor and remove redudent conversions soon.
 * 48   03/31/16    Sebastian Mansfield             new nested entities can now be created on new entities
 * 49   03/31/16    Josue Navarrete     84230669    Create functionality to delete private files attachments, in particular cases, and fixed bug select_option  when save button is pressed
 * 50   03/31/16    Adam McDaniel                   Fix for nested entity upserts on new edit forms.
 * 51   04/01/16    Sebastian Mansfield             Fixed bug with deleting and updating nested entitites
 * 52   04/04/16    Sebastian Mansfield             Added descriptions to editform
 * 53   04/04/16    Victor Hernandez    86512296    Added tooltips to controls onFocus.
 * 54   04/04/16    andrew.gay          86454146    Added a fix response to prevent third part from converting our dates to strings for us (As they will move the time and we do not want this)
 * 55   04/04/16    Victor Hernandez    86513444    Fixed a bug causing empty tooltip baloons to appear when no tooltip text was available.
 * 56   04/04/16    Josue Navarrete                 Verify if selectbox not have default values put empty option
 * 57   08/04/16    Josue Navarrete     86972915    Created edit_button funcionality and change position to button next to add_button
 * 58   11/04/16    Josue Navarrete     87734365    Form Edit float action buttons, Data Grids internals with new call to action Show Hide
 * 59   14/04/16    Josue Navarrete     87810584    Hide Parent content, only show children to all screen when navegate to instances
 * 58   14/04/16    Sebastian Mansfield             Added tagbox template and popover
 * 60   14/04/16    Josue Navarrete     88285102    Toggle Description to Grids, new Button on top of Form Edit
 * 61   14/04/16    Josue Navarrete     87735390    Detect when error response is session expired and redirect to login
 * 62   15/04/16    Josue Navarrete     88355389    skyp_init count length of rows of datagrids, fixed styles description buttons
 * 63   14/04/16    Sebastian Mansfield             Removed old functionlaity for file attachments
 * 64   14/04/16    Sebastian Mansfield             Added tag tamplate
 * 65   14/04/16    Sebastian Mansfield             Added popover for tags
 * 65   18/04/16    Sebastian Mansfield             Removed by nd fix note popover
 * 66   18/04/16    Sebastian Mansfield             Added pointer for icons in popover changed color
 * 67   19/04/16    Josue Navarreete                Changes for sprint 20 Xenforma client
 * 68   20/04/16    Victor Hernandez                Fixed a bug where fields in a nested entity that had the same field path as a field in its parent entity would also update the parent entity field.
 * 69   22/04/16    Victor Hernandez                Added format validation for email fields.
 * 70   25/04/16    Victor Hernandez                Removed loading screen on form close.
 * 71   26/04/16    Sebastian Mansfield             Fixed nested entities in new entitities
 * 72   26/04/16    Victor Hernandez                Error state now get cleared on field change.
 * 73   27/04/16    Josue Navarrete     89683850    on_field_change added float protection
 * 74   02/05/16    Victor Hernandez                Lookup fix. It now displays the initial value properly.
 * 75   03/05/16    Josue Navarrete                 Restore error messages bug fixed styles edit and add_buttons
 * 76   05/04/16    Adam McDaniel                   Removed custom_properties.conditions from read_with_nested_permissions call, and added request.field_path.  Left 2 placeholders in code for is_new flag.
 * 77   05/05/16    Josue Navarrete     87755345    Fixed size of buttons,buttons responsive with mobile and fixed navigation form edit bug.
 * 78   09/05/16    Josue Navarrete     91221083    Method when value of LOV is null replace this value of empty before send to server
 * 79   10/05/16    Josue Navarrete     91803825    B: When trigger a Warning message, the form edit lost the style
 * 80   05/11/16    AMcDaniel           91997373    Changed any references to removed file app_objects (file_minified_edit_form) and changed to use default app_object (file_default_edit_form)
 * 81   05/12/16    Josue Navarrete     91976665    When user roles is changed delete localStorage home and get home layout
 * 82   05/12/16    Josue Navarrete     92889529    Set Focus on first input when open a  new form edit
 * 83   05/24/16    MCasanova                       Fixed focus_first_input: function, realated with iexplorer focus/click first input.
 * 84   05/30/16    MCasanova                       Truncate text in edit title form for mobile.
 * 85   05/31/16    Victor Hernandez                Resize will not trigger on mobile when soft keyboard is triggered.
 * 86   06/09/16    MCasanova                       adding attribute.attribute_type != "zipcode_field"  to rowsCount calculator.
 * 87   06/21/16    andrew.gay          97417148    Nested entities now run business rules on their parent field
 * 88   06/22/16    MCasanova                       Improve/change jquery.css style by pure css and modify height of checkboxes.
 * 89   06/22/16    Smansfield          97416919    Removed click if first input its not a string.
 * 89   06/22/16    Smansfield          97998026    Lov are not showing last value.
 * 90   06/22/16    AMcDaniel           97609452    Added check for flag in upsert response: If new_entity_flag is true, use response.data to populate a new edit form.
 * 91   06/22/16    AMcDaniel           97609452    Added force_grid_refresh_on_close parameter to initialize_edit_form and (corresponding) force_grid_refresh to state, to force refresh of the data_list even if edit form is cancelled.
 * 92   06/22/16    Victor Hernandez    97862606    Fixed a bug where on slow computers/connections variant entity fields would fail to load correctly
 * 93   06/22/16    Victor Hernandez    97923907    Added cancel button to variant reference popover, changed it's displayed text to "Ok" and refactored it's button generation procedure to not rely on jquery binding methods.
 * 94   06/22/16    Victor Hernandez    97862606    Made variant reference entity fields update their captions by using the form caption builder.
 * 95   06/23/16    Victor Hernandez    98014023    Eliminated ghost buttons appearing in the workflow area of edit forms.
 * 96   06/23/16    Mcasanova           97885763    adding a new conditional state.is_reference_nest for toast.
 * 96   06/23/16    Victor Hernandez    98112862    Forced currency fields to have 2 decimals.
 * 97   06/23/16    Victor Hernandez    98000532    Added a failsafe when the server sends an object instead of a string as an error message.
 * 98   06/23/16    AMcDaniel           96812150    Added check for attribute.entity_reference.is_list which allows for ref data_lists on edit_form grids.
 * 99   06/23/16    Victor Hernandez    98405810    Added flags to attributes and lookup datasources to prevent unnecesary requests being made. Also updated the way lookups trigger the onBlur event for a more predictable behaviour.
 * 100  06/23/16    Victor Hernandez    97989173    Added a keyup event handler to catch when the user presses "Esc" and attempt to close the form properly.
 * 101  06/27/16    Victor Hernandez                Added a placeholder for all lookup fields prompting the user to type something to search.
 * 102  06/27/16    Victor Hernandez    98384064    Implemented is_focus prop for the datagrid to auto focus its add button on mount and implemented is_open prop/state.
 * 103  06/27/16    AMcDaniel           97216497    Added code to skip pushing to window location for app_objects
 * 104  06/28/16    Victor Hernandez    98384064    Refactored the way the editform handles scrolling back to it's original position. Also fixed a small bug where entity_record_fields would take an extra render to display.
 * 105  06/28/16    Mcasanova           97419710    Using custom_properties.disable_create_button and query to determine if create button should not be displayed
 * 106  06/28/16    AMcDaniel           97216497    Changed captions and descriptions to pull from app_objects first, then entity_attributes. Added option to custom_properties for autoexpand_description
 * 107  06/29/16    Mcasanova           98391522    line 3532 adding contitional and verify if the field is required to display or not  disable blank space. *need to be improve
 * 108  06/29/16    Victor Hernandez    98728555    Partial commit fixing workflow buttons.
 * 109  06/29/16    Victor Hernandez    98728555    Implemented cyclic insert.
 * 110  06/29/16    Victor Hernandez    98859504    Now sending null for whole attribute when clearing a reference entity. Also fixes 99084905.
 * 111  06/29/16    Mcasanova                       Changing warning component by div content only for IE
 * 112  07/01/16    JEscudero           Fix GDrive  Updated google_drive_api function to also work on IE. Now it opens via a window pop-up
 * 113  07/04/16    Mcasanova           99542130    Adding an event in handle submit to display errors when save on edit entity.
 * 114  07/04/16    Mcasanova           99536077    line 1323 adding validation to this.props && this.props.data different from undefined
 * 115  07/05/16    Mcasanova           99841292    line 1171  modify this.state.entity_instance['file_name'] change object by string object.name
 * 116  07/06/16    JEscudero           99942365    Now delete button appears only if user has delete permission.
 * 117  07/06/16    JEscudero           99942365    Now all buttons appear when they are supposed to based on user permissions.
 * 118  07/08/16    Mcasanova           100122606   line 415 adding timeout focus, blur event only for Iexplorer.
 * 119  07/08/16    Mcasanova           98761971    line 235 Increase the display loader timeout.
 * 120  07/08/16    Mcasanova               ?       css override ,fix align home icon.
 * 121  07/12/16    JEscudero           93483486    Now localstorage exists when the google picker window is opened. Added load screen as well.
 * 122  07/12/16    JEscudero           99910560    Fixed the created by and last updated by entity attribute. It now displays the correct dates.
 * 123  07/13/16    Victor H            Bug         Sending attribute data to fix_response_data where needed so that it can differentiate Date fields and format them accordingly.
 * 124  07/14/16    Victor H            100270475   Fixed error message not showing on IE when business rules returned an error.
 * 125  07/14/16    JEscudero           98993235    Implemented the duplication system. Made another invoke_method gather the duplicated information, and inserted in a new instance to have a new _id.
 * 126  07/15/16    Victor H            101368007   Fixed a bug causing nested entities to fail rendering workflow, save and delete buttons.
 * 127  07/15/16    Victor H            98384064    Fixed a bug causing referenced entities to fail saving their scroll position when opening.
 * 128  07/18/16    JEscudero           98993235    When duplicating, the widget-caption now says new + entity_name, instead of the previous entity_instance name.
 * 129  07/18/16    Victor H            101370560   Fixed a bug causing tagboxes to not update properly.
 * 130  07/18/16    JEscudero           98993235    Fixed a bug that when duplicating in IE11 the fields were empty.
 * 131  07/18/16    Victor H            100137728   Fixed a bug causing referenced tagboxes to fail to display the proper tag captions.
 * 132  07/18/16    Victor H            100137728   Making sure no empty initial values get pushed into the values array.
 * 133  07/19/16    JEscudero           101528639   Fixed a bug that caused the "new" keyword remain when craeting a new instance and editing it's caption.
 * 134  07/19/16    Victor H            101955658   First input will not be focused if the focus is already on another form element.
 * 135  07/19/16    Victor H            Bug         Fixed a bug causing nested grid records to crash when saving.
 * 136  07/20/16    Sebastian M                     Added empty option for assigned to user
 * 137  07/20/16    Victor Hernandez    98384064    Fixed a bug causing the scroll position not being restored under certain circumstances.
 * 138  07/20/16    Victor Hernandez    100097523   Fixed a bug causing file popovers to display unexpected behaviours when interacting with the tags in quick succession.
 * 139  07/20/16    JEscudero           98993235    Now when duplicating, the private entity_instances are not considered.
 * 140  07/21/16    Victor Hernandez    100947647   Fixed tagbox not updating properly after adding a new file.
 * 141  07/21/16    JEscudero           Bug         Fixed a problem that caused an isntance caption not to update in real time while duplicating an instance.
 * 142  07/22/16    Victor Hernandez    100097523   Fixed a bug causing tags in tagboxes not rendering properly under certain circumstances.
 * 143  07/22/16    JEscudero           102256673   Made delete button not appear if app_object.custom_properties.disable_delete exists.
 * 144  07/22/16    Victor Hernandez    100097523   Fixed a bug causing some lookup fields to have their datasources rebuilt when returning from a nested edit form.
 * 145  07/22/16    Victor Hernandez    Bug         Fixed a bug causing loading tag divs to appear for each lookup field.
 * 146  07/22/16    Victor Hernandez    Bug         Fixed a bug causing loading tag div to appear when clicking on a file icon but no popoup.
 * 147  07/24/16    JEscudero           98993235    Created a function for the duplication system (Moved from componentdidmount). This fixed the problem that caused the inputs to be empty.
 * 148  07/25/16    Victor Hernandez    100097523   Fixed a bug causing file tagbox popover icons to not work when there are other tagbox fields on the form.
 * 149  07/25/16    Victor Hernandez    98384064    Fixed a bug causing nested edit forms to fail saving their own scroll position.
 * 150  07/25/16    Victor Hernandez    102631843   Fixed a bug causing lookup fields to refresh the DOM when loading data.
 * 151  07/25/16    Sebastian Mansfield             Fixed a bug in error scrolling
 * 152  07/25/16    Victor Hernandez    101955658   Added a short delay on first_focus function for IE.
 * 153  07/26/16    Victor Hernandez    101995806   Choosing a file in a file picker will now clear error states.
 * 154  07/26/16    JEscudero           98993235    Made the keyword 'new' appear in the correct circumstances in the editform caption.
 * 155  07/26/16    Victor Hernandez    102931086   File attachments now appear as soon as the form is rendered.
 * 156  07/27/16    Sebastian Mansfield             Fixed lookup functionality
 * 157  07/27/16    Victor Hernandez Bug            Fixed file downloads not working on IE.
 * 158  07/27/16    JEscudero           102862835   When there is an error, the widget-body will be scrolled up so the user can see it.
 * 159  07/27/16    Sebastian Mansfield             Fixed lookup functionality
 * 160  07/27/16    AMcDaniel           103304209   Changed code for file summary to use a new route (file.get_file_summary_data)
 * 161  07/27/16    AMcDaniel           103304209   Revert commit 1008 to restore file attachment functionality
 * 162  07/28/16    JEscudero           95064749    If expanded flag is set to true, then nested entities will be expanded.
 * 163  07/28/16    JEscudero           102862835   Quickfix to the scroll system when there's a error. For Firefox.
 * 164  07/28/16    JEscudero           102454769   Lookups fields are not required to be filled (are automatically) when duplicating, therefore made them not yellow.
 * 165  07/28/16    Victor Hernandez    Bug         Fixed a bug causing some referenced fields mark the form as modified unnecessarily.
 * 166  07/28/16    Victor Hernandez    102476707   Hiding all description popovers when opening a nested edit_form.
 * 167  07/29/16    Victor Hernandez    103608819   Fixed a bug causing the user lit to not display on the assign to popup
 * 168  07/29/16    Victor Hernandez    103432252   Fixed a bug causing edit forms to scroll to the top when deleting an item from a nested grid.
 * 169  08/01/16    Sebastian Mansfield             Execute business rules will now run when editing a nested entity with the appropiate flag.
 * 170  08/01/16    JEscudero           103358905   Made the Escape button close the "Yes_No" Modal (confirmation) if it is open.
 * 171  08/01/16    Victor Hernandez    102862835   Fixed a bug preventing the form to scroll to the top when an error is displayed under certain circumstances.
 * 172  08/01/16    Victor Hernandez    102672403   Fixed a bug causing a focus loop on referenced entities on internet explorer.
 * 173  08/01/16    Victor Hernandez    103993144   Fixed a bug causing some popovers to stay visible after closing an edit form.
 * 174  08/02/16    Victor Hernandez    103432252   Fixed a bug causing the screen to scroll to the top when deleting a record from a nested datagrid.
 * 175  08/02/16    Victor Hernandez    103358905   Redid the logic for a more consistent functionality.
 * 176  08/03/16    Victor Hernandez    Bug         Fixed a bug causing focus to behave unexpectedly on dropdown elements.
 * 177  08/03/16    Sebastian Mansfield             Refactored to adjust to new version
 * 178  08/03/16    Sebastian Mansfield             Prevent workflow buttons on certain permissions
 * 179  08/10/16    Sebastian Mansfield             Fixed business rules when editing a nested grid
 * 180  08/10/16    Sebastian Mansfield             Lookups not working with the correct permissions
 * 181  08/10/16    AMcDaniel           105367057   Fixed crash that happens when roles field comes as null
 * 182  08/10/16    Sebastian Mansfield             Fix X button in file attachments
 * 183  08/17/16    Victor Hernandez    105537854   Fixed a bug causing google files to display "undefined" on the file tag after saving the entity.
 * 184  08/18/16    AMcDaniel           106675266   Added code to run custom_form_attributes_function when creating a new entity
 * 185  08/24/16    Victor Hernandez    106681298   Fixed a bug causing the user to be unable to scroll up after adding a note under certain cirumstances.
 * 186  08/25/16    Victor Hernandez    104247219   Fixed a bug causing the confirmation popup to immediately disappear when attempting to exit the form by pressing the Esc key on IE11
 * 187  08/25/16    Victor Hernandez    103358905   Refactored confirmation dialog management for a more robust behaviour.
 * 188  08/29/16    Victor Hernandez    100633662   Delete button on file tags will not display unless the user has 'u' permissions.
 * 189  08/30/16    Victor Hernandez    Bug         Fixed a bug where the scroll would get stuck at the top when returning from a referenced entity.
 * 190  08/31/16    AMcDaniel           108576209   Fixed/Implemented app_object_code parameter for invoke_method call for upsert of nested entity
 * 191  08/31/16    Victor Hernandez    108576209   Fixed a bug causing nested entities to fail executing business rules when edited.
 * 192  08/31/16    Victor Hernandez    108576209   Deleting a record from a nested grid now calls execute_business rules.
 * 193  08/31/16    Victor Hernandez    108804411   Fixed a bug causing referenced datagrids to refresh themselves using the wrong method.
 * 194  09/02/16    Victor Hernandez    108842231   New forms will now gain focus on its first String type field instead of their first input String field.
 * 195  09/08/16    AMcDaniel           Bug         Fixed bug that occurs when deleting nested entities
 * 196  09/09/16    AMcDaniel           109177071   Added rough support for custom_properties.custom_html_template, Changed a lot of logic to use path'd get/set methods to allow new templates to directly set/track changed fields in nested entity arrays
 * 197  09/14/16    AMcDaniel           109177071   Added more support for custom_properties.custom_html, esp nested entity reference and LOV fields (Still needs more work)
 * 198  09/14/16    AMcDaniel           109177071   Fixed some problems with using general_utils.set_json_data_using_field_paths
 * 199  09/14/16    AMcDaniel           109177071   Added some fallback code for focus_first_input for html templates, also stopped it from endlessly looking when it fails.
 * 200  09/16/16    AMcDaniel           109177071   Added support for updating nested entity values from the topmost entity during execute_business_rule response.  Also added an _id check as a safeguard.
 * 201  09/23/16    AMcDaniel           109177071   Added caching of processed html and entity attributes for custom_html_template to prevent excessive computation. Added attribute.cached_rendered_component and .last_react_key properties. Fixed nested popup delete.
 * 202  09/27/16    AMcDaniel           112239580   Bug fix - Changed on_field_blur success method to use entity_attributes to determine whether to overwrite the update_fields value.
 * 203  09/28/16    AMcDaniel           109177071   Changed/Added some styling fixes.  Added placeholders to attributes (currently for html templates only) in the new field html_template_placeholder_text
 * 204  09/29/16    AMcDaniel           111612696   Fixed a bug with file attachment uploads
 * 205  10/02/16    AMcDaniel           Bug         Fixed a bug with upserting nested entity lists with new records
 * 206  10/02/16    AMcDaniel                       Added a save notification when upserting nested entity lists with existing records
 * 207  03/06/17    AMcDaniel           132638246   Changed post-workflow success logic to quit after context.handle_close is called
 * ========================================================
 **/

var EditFormComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        var context = this;
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + e + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    getInitialState: function () {
        this.debugLog('getInitialState', true);
        /*
        if (!this._setState){
            this._setState = this.setState;
            this.setState = function(){
                try{throw new Error('ef_setState')}catch(e){console.log(e)}
                return this._setState.apply(this, arguments);
            };
        }
        if(!this._forceUpdate){
            this._forceUpdate = this.forceUpdate;
            this.forceUpdate = function(){
                try{throw new Error('ef_forceUpdate')}catch(e){console.log(e)}
                return this._forceUpdate.apply(this, arguments);
            };
        }
        */
        var state_object = this.initialize_edit_form();
        if (state_object) {
            return state_object;
        }

        var context = this;
        setTimeout(function(){
            context.display_loader();
        }, 100);
        this.debugLog('getInitialState', false);
        return {update_fields: {}};
    },
    shouldResize: function(){
        this.debugLog('shouldResize', true);
        var isMobile = !!(/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent)), retval;
        if (!this.state.windowWidth) this.state.windowWidth = 1;
        if (!this.state.windowHeight) this.state.windowHeight = 1;
        var change = window.innerWidth/this.state.windowWidth -1;
        var isChangingOrientation = (change>0?change:(change*-1))>0.01;
        retval = !isMobile||isChangingOrientation;
        if (retval) {
            this.state.windowWidth= window.innerWidth ;
            this.state.windowHeight= window.innerHeight;
        }
        this.debugLog('shouldResize', false);
        return retval;
    },
    updateDimensions: function (e){
        this.debugLog('updateDimentions', true);
        this.handleResize(e);
        this.debugLog('updateDimentions', false);
    },
    handleKeyUp: function (e) {
        var context = this,
            close = true,
            noButton = $('.dx-dialog.dx-overlay.dx-popup [aria-label="No"]');
        if (e.keyCode == 27 || e.code === "Escape"){
            if (context.confirming && ((new Date() - context.confirming) > 500) && noButton.length){
                noButton.trigger('click');
                close = false;
            }
            if( context.pop_up){
                context.pop_up = false;
            }
            if (close){
                context.handle_close();
            }
        }
    },
    handleResize: function(e) {
        this.debugLog('handleResize', true);
        if (this.shouldResize()) {
            this.hidden_parent_style();
            this.forceUpdate();
        }
        this.debugLog('handleResize', false);
    },
    componentWillUpdate: function (){
        this.focusedElement = $(':focus');
        var select_box = this.focusedElement.parents('#dxSelectBox');
        if (select_box.length){
            this.focusedElement = select_box.dxSelectBox('instance');
        }
    },
    componentDidUpdate: function () {
        this.debugLog('componentDidUpdate', true);
        var retval = this.custom_post_render_logic(),
            context = this;
        if (!!document.documentMode){
            $( ".btn-primary" ).click(function() {
                if (context.state && context.state.error){
                    $( ".ie-warning" ).css("display","block");
                }
            });
        }
        if (this.focusedElement){
            this.focusedElement.focus();
        }
        this.debugLog('componentDidUpdate', false);
        return retval;
    },
    componentDidMount: function () {
        this.debugLog('componentDidMount', true);
        var context = this;
        this.state.windowWidth = window.innerWidth;
        this.state.windowHeight = window.innerHeight;
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('keyup', this.handleKeyUp);
        this.recovery_parent_style();
        this.display_loader();
        this.debugLog('componentDidMount', false);
        $('.dx-texteditor-input').css({  height : '40px;'});//Needs To improve
        if(context.state.entity_attributes && context.state.entity_attributes.custom_form_attributes_function){
            context.on_field_blur("custom_form_attributes_function",context.state.entity_attributes.custom_form_attributes_function);
        }
        return this.custom_post_render_logic();
    },
    display_loader: function(){
        this.debugLog('display_loader', true);
        var context = this;
        if (context.props.handle_executing&&(!context.state.parent_entity_field_attribute||context.state.is_reference_nest)){
            context.props.handle_executing(true);
            setTimeout(function(){
                context.props.handle_executing(false);
            },7000);
        }
        this.debugLog('display_loader', false);
    },
    get_form_object: function(){
        var id = this.state.app_object_code || this.props.app_object_code || this.props.data.app_object_code || this.props.data.app_object.code;
        return id?$('#edit_form[data-object="' + id + '"]'):$('#edit_form');
    },
    focus_first_input: function(){
        this.debugLog('focus_first_input', true);
        var attributes = this.state.entity_attributes.attributes,
            attribute,
            context = this,
            counter = 0,
            is_ie = !!document.documentMode;
        for (var i = 0, u = attributes.length; i<u;i++){
            if (attributes[i].form_visible && attributes[i].db_type == "String"){
                attribute = attributes[i];
                break;
            }
        }
        var checkExistsFunction = function() {
            var form = context.get_form_object(),
                element = attribute ? $('#info_' + attribute.field_path).parent().first() : form.find('input').first(),
                current_focus = form.find('*:focus');
            if (element.find('input').length) { //some "String" fields may be represented as html select boxes.
                element = element.find('input').first();
            }
            else if (attribute) {
                element = element.find('select').first()
            }
            if (element.length != 0 && element.is(':visible') && !element.is(':focus') && $('.loading-container').length == 0 && (!current_focus.length || (is_ie && counter < 1))) {
                element.focus();
            } else {
                if (element.is(':focus') || current_focus.length) {
                    clearInterval(checkExist);
                }
                else if (counter == 2) {
                    if (attribute) {
                        attribute = null;
                        checkExistsFunction();
                    }
                    else {
                        clearInterval(checkExist);
                    }
                }
            }
            counter++;
        };
        var checkExist = setInterval(checkExistsFunction, 500);
        this.debugLog('focus_first_input', false);
    },
    recovery_parent_style: function(){
        this.debugLog('recovery_parent_style', true);
        var context=this;
        setTimeout(function(){
            context.hidden_parent_style();
        },1300);
        this.debugLog('recovery_parent_style', false);
    },
    google_drive_api_load:function(attribute){
        var isIE = !!document.documentMode;
        if (isIE){
            setTimeout(function(){
                $("div.picker:nth-child(2) > iframe:nth-child(1)" ).focusin(function(txt) {
                });
            }, 3500);
        }
    },
    google_drive_api:function(attribute){
        this.debugLog('google_drive_api'. true);
        var context = this;
        var picker_window;
        var get_keys = function() {
            var promise = new Promise(function(resolve, reject){
                var request ={};
                request.code="google_drive_public_keys";
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/settings/get_system_setting",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: resolve,
                    error: reject
                });
            });
            return promise;
        };

        var store_keys = function (data) {
            localStorage.setItem("developerKey", data.data.google_drive_developer_key);
            localStorage.setItem("ClientId", data.data.google_drive_browser_key);
            picker_window = window.open('driver.html', 'picker', "height=500,width=900");
        };

        get_keys()
            .then(store_keys);

        function storage_handler(evt)
        {
            if(evt.key == "url" && evt.newValue != 'nothing'){
                context.props.handle_executing(true);
                var doc = JSON.parse(localStorage.getItem("doc"));
                var url = localStorage.getItem("url");
                context.on_field_change(attribute, true);
                attribute = context.state.entity_attributes.field_paths["drive_url"];
                context.on_field_change(attribute, url);
                context.on_file_name_change(attribute, doc);
                context.setState({file_uploaded:true});
                picker_window.close();
                context.props.handle_executing(false);
            }
        }
        window.addEventListener('storage', storage_handler, false);
        this.debugLog('google_drive_api', false);
        this.google_drive_api_load();
    },
    remove_file:function(attribute){
        this.debugLog('remove_file', true);
        var context = this;
        context.setState({resetFileUpload:true});
        context.on_field_change(attribute, false);
        attribute = context.state.entity_attributes.field_paths["drive_url"];
        context.on_field_change(attribute);
        context.on_file_name_change(attribute);
        context.setState({file_uploaded:false});
        this.debugLog('remove_file', false);
    },
    download_file:function(fileId){
        this.debugLog('download_file', true);
        var context = this, retval = null;
        if(context.state.entity_instance["drive_url"])
        {
            var error = function (data) {
            };
            if (context.state.downloadUrl != context.state.entity_instance["drive_url"]){
                context.setState({downloadUrl: context.state.entity_instance["drive_url"]});
            }
            retval = context.state.entity_instance["drive_url"].value;
        }
        else{
            var getFileCompleted = function (data) {
                if (context.state.downloadUrl != data.data.url){
                    context.setState({downloadUrl: data.data.url});
                }
            };
            var error = function (data) {
            };
            context.downloadFlag = true;
            invoke_method('file', 'get_file', {file_id: fileId}, getFileCompleted, error, function(){context.downloadFlag=false;context.forceUpdate()});
        }

        this.debugLog('download_file', false);
        return retval;
    },
    get_height: function(parentStyle){
        var isModal = this.props.isModal || this.props.parentIsModal || $(this).parents('.modal').length > 0;
        var retval = "auto", navbar = $('.navbar');
        if (parentStyle!="none"){
            var buttonBoxHeight = $('#workflow-button-box').height();
            var parentHeight = $(window).height() - (isModal?0:navbar.height());
            var headerHeight = 80;
            retval = parentHeight - buttonBoxHeight - headerHeight + "px";
        }
        return retval
    },
    hidden_parent_style: function(){
        this.debugLog('hidden_parent_style', true);
        var element = $(".widget-body[data-resize='resize_element']").last();
        element.css("height" , this.get_height('block'));
        this.debugLog('hidden_parent_style', false);
    },
    align_bottom: function (){
        var rows = $('.row'), maxHeight, thisHeight;
        rows.each(function(){
            maxHeight = 0;
            $(this).children().each(function(){
                thisHeight = $(this).height();
                if (maxHeight < thisHeight){
                    maxHeight = thisHeight;
                }
            });
            $(this).children(':not(.dx-popover)').each(function(){
                if($(this).hasClass('prevent_margin')){
                }else{
                    if($(this).parent().height() >= 90 ) {
                        $(this).css('margin-top', maxHeight - $(this).height());
                    }
                }
            });

        });
    },
    focus_function: function(limit, timeout){
        this.debugLog('focus_function', true);
        var context = this,
            isLookup = this.state.currentAttribute.lookup,
            element = isLookup?$("#info_"+this.state.currentAttribute.field_path).parent().find("#dxSelectBox").dxSelectBox('instance'):$("#info_"+this.state.currentAttribute.field_path).parent().find(".dx-texteditor-input");
        if(new Date() - timeout > limit) {
            this.debugLog('focus_function', false);
            return;
        }
        if (element && element.length && $(element[0]).is(':visible')) {
            if ($(element[0]).is(':focus')) {
                this.debugLog('focus_function', false);
                return;
            } else {
                var isIE = !!document.documentMode;
                if (isIE){
                    setTimeout(function(){
                        $(element[0]).focus();
                        $(element[0]).blur();
                    }, 1000);
                }else{
                    $(element[0]).focus();
                }
            }
        }
        else if(isLookup){
            if(element) {
                element.focus();
            }
            this.debugLog('focus_function', false);
            return;
        }
        setTimeout(function(){context.focus_function(limit, timeout?timeout:new Date());}, 200);
        this.debugLog('focus_function', false);
    },
    custom_post_render_logic: function () {
        this.debugLog('custom_post_render_logic', true);
        var context = this;
        $('.date-picker').datepicker();
        //ie error display
        var isIE = !!document.documentMode;
        if (isIE){
            $( ".dx-icon" ).click(function() {
                $( ".ie-warning" ).css("display","none");
            });
            $( ".ie-close" ).click(function() {
                $( ".ie-warning" ).css("display","none");
            });
            $( ".btn-primary" ).click(function() {
                if (context.state && context.state.error){
                    $( ".ie-warning" ).css("display","block");
                }
            });
        }
        //popup tooltips
        for (var i = 0; this.state.entity_attributes && i < this.state.entity_attributes.attributes.length; i++) {
            var attribute = this.state.entity_attributes.attributes[i];
            if (!attribute.form_visible) {
                continue;
            }

            var info_id = "#info_" + attribute.field_path.replace(/:/g, '-');

            var field_path = info_id + ' + *';
            if($(info_id + '_desc').text()){
                var popover_desc = $(info_id + "_desc").dxPopover({
                    target: info_id,
                    position: "top",
                    width: 300
                }).dxPopover("instance");
                var popover_control = $(info_id + "_desc").dxPopover({
                    target: field_path,
                    position: "top",
                    width: 300
                }).dxPopover("instance");

                var appear = function (popover) {
                    return function () {
                        popover.show();
                    }
                };
                var disappear = function (popover) {
                    return function () {
                        popover.hide();
                    }
                };
                field_path += ':not(div), ' + field_path + ' *:not(button)';
                $(info_id).hover(appear(popover_desc), disappear(popover_desc));
                $(field_path).focusin(appear(popover_control));
                $(field_path).focusout(disappear(popover_control));
            }
        }
        //error scroll to top
        if (this.state.error && !context.state.scrolled) {
            var element = context.get_form_object().find('.widget-body');
            element.scrollTop(0);
            context.setState({scrolled : true});
        }

        $('.fachange_history').removeClass('fachange_history').addClass( "fa fa-pencil-square-o" );
        $('.facomment').removeClass('fachange_history').addClass( "fa fa-comment-o" );
        $('.comment-footer[note_type!="comment"]').css("display" , "none");
        $('.faworkflow').removeClass('fachange_history').addClass( "fa fa-check-circle-o" );
        $('.fasystem_event').removeClass('fachange_history').addClass( "fa fa-asterisk" );

        //discard changes confirmation dialog
        window.onbeforeunload = function (evt) {
            if (context.state.modified) {
                var message = R.message_changes_discarded;
                if (typeof evt == 'undefined') {
                    evt = window.event;
                }
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            }
        };

        //multiselect object instantiation
        try{
            $('.rolesmultiple').each(function(){
                var element = $(this);
                element.multiselect({
                    enableHTML:true,
                    onChange: function(option, checked, select,value) {
                        var roles_attribute= $.parseJSON(element.attr('data-attribute'));
                        context.state.entity_instance[roles_attribute.field_path] = context.state.entity_instance[roles_attribute.field_path] || [];
                        var elementPos = context.state.entity_instance[roles_attribute.field_path].map(function(x) {return x; }).indexOf($(option).val());
                        if(elementPos == -1)
                        {
                            context.state.entity_instance[roles_attribute.field_path].push($(option).val());
                        }
                        if (elementPos != -1)
                        {
                            context.state.entity_instance[roles_attribute.field_path].splice(elementPos, 1);
                        }
                        context.on_field_change(roles_attribute,context.state.entity_instance[roles_attribute.field_path])
                    },
                    buttonTitle: function(options, select) {
                        return ;
                    }
                });
            });
        }
        catch(error){
        }

        // Detect when an option is new, call event on_field_change
        if( context.state.flagSaveDropdown==2 ){
            context.state.flagSaveDropdown=0;
            var currentAttribute = context.state.currentAttribute;
            if(currentAttribute.select_options || currentAttribute.dataSource){
                setTimeout(function(){ //timeout zero to have this run on a different thread and not stall main thread with polling loop.
                    currentAttribute=context.state.currentAttribute;
                    var newKey=context.state.newKey, newCaption=context.state.newCaption;
                    var optionToChange = null;
                    var done = function(){
                        context.setState({newKey:''});
                        if(currentAttribute.db_type!='file'){
                            context.focus_function(4000);
                        }
                    };
                    if(currentAttribute.dataSource &&
                        "function" == typeof currentAttribute.dataSource.load &&
                        currentAttribute.db_type!="file" &&
                        newKey &&
                        (!context.state.entity_instance[currentAttribute.field_path] ||
                        newKey != context.state.entity_instance[currentAttribute.field_path].entity_id)){
                        if (newCaption) {
                            currentAttribute.dataSource.searchValue(newCaption);
                            currentAttribute.dataSource.searchExpr('caption');
                        }
                        currentAttribute.dataSource.load().done(function(data){
                            if (currentAttribute.select_options){
                                for (var i = 0, u = currentAttribute.select_options.length; i<u;i++){
                                    optionToChange = find_by_field('entity_id', currentAttribute.select_options[i].entity_id, data);
                                    if (!optionToChange){
                                        data.push(currentAttribute.select_options[i]);
                                    }
                                }
                            }

                            currentAttribute.select_options = data;
                            optionToChange = find_by_field('entity_id', newKey, data);
                            context.on_field_change(currentAttribute.field_change_event_args || currentAttribute, optionToChange);
                            context.on_field_blur(currentAttribute.field_change_event_args || currentAttribute);
                            done();
                            return data;
                        });
                    }
                    else {
                        if (newKey && newKey == context.state.entity_instance[currentAttribute.field_path].entity_id){
                            if (newCaption!=context.state.entity_instance[currentAttribute.field_path].caption){
                                if (newCaption) {
                                    currentAttribute.dataSource.searchValue(newCaption);
                                    currentAttribute.dataSource.searchExpr('caption');
                                }
                                currentAttribute.dataSource.load().done(function(data){
                                    context.state.entity_instance[currentAttribute.field_path].caption = newCaption;
                                    if (currentAttribute.select_options){
                                        for (var i = 0, u = currentAttribute.select_options.length; i<u;i++){
                                            optionToChange = find_by_field('entity_id', currentAttribute.select_options[i].entity_id, data);
                                            if (!optionToChange){
                                                data.push(currentAttribute.select_options[i]);
                                            }
                                        }
                                    }
                                    currentAttribute.select_options = data;
                                    done();
                                    return data;
                                });
                            }
                        } else {
                            if(currentAttribute.dataSource){
                                currentAttribute.dataSource.load().done(function(){
                                    done();
                                })
                            }
                            done();
                        }
                    }
                },0)
            }
        }

        this.hidden_parent_style();

        this.align_bottom();

        if (this.recover_scroll){
            this.restoreScroll = true;
            this.restore_scroll_position();
        }

        this.debugLog('custom_post_render_logic', false);
    },
    componentWillUnmount: function () {
        this.debugLog('componentWillUnmount', true);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('keyup', this.handleKeyUp);
        if (this.state.modified) {
            this.state.modified = false;
        }
        this.debugLog('componentWillUnmount', false);
    },
    initialize_edit_form: function (new_entity_json, force_grid_refresh_on_close) {
        this.debugLog('initialize_edit_form', true);
        var context = this;
        var given_data = this.props.data;
        var is_new_instance = false;
        var success = function (data) {
            fix_response_data(data, data.data.entity_attributes.field_paths);
            data = data.data;
            data = $.extend({}, given_data, data);
            data.is_new_instance = is_new_instance;
            if (is_new_instance) {
                context.populate_update_fields(data.entity_attributes, data.values);
            }
            if (data.app_object && data.app_object.workflow_states) {
                data.workflow_states = data.app_object.workflow_states;
                delete data.app_object.workflow_states;
            }
            if (data.values) {
                data.entity_instance = data.values;
                delete data.values;
            }
            data.entity_instance = data.entity_instance || {};
            if(new_entity_json || context.props.new_entity_json) {
                $.extend(data.entity_instance, new_entity_json || context.props.new_entity_json);
                data.update_fields = data.update_fields || {};
                $.extend(data.update_fields, (new_entity_json || context.props.new_entity_json));
            }
            var caption = (data.app_object.name && (data.app_object.name.trim() != "")) ? data.app_object.name : data.entity_attributes.caption_singular;
            if (data.entity_attributes.caption_fields && data.entity_attributes.caption_fields.length > 0) {
                data.caption_function = function (entity_data) {
                    return make_friendly_caption(data.entity_attributes.caption_fields, entity_data, data.entity_attributes.attributes);
                };
                if (!is_new_instance) {
                    caption = data.caption_function(data.entity_attributes.attributes);
                }
            }
            data.init = true;
            data.force_grid_refresh = force_grid_refresh_on_close;
            if(data.app_object.custom_properties && data.app_object.custom_properties.custom_html_template) {
                data.custom_html_template = data.app_object.custom_properties.custom_html_template;
            }
            context.setState(data);
            var _id;
            _id = request._id;
            _id = _id ? "&_id=" + _id : "";
            if (!data.parent_react_entity && !context.props.isModal && !context.props.skip_location_push) {
                push_href_location(caption + " - " + (R.client_application_title || "Xenforma"), "/edit_form?code=" + (data.app_object_code || data.app_object.code) + _id  );
            }

            if(context.state.entity_attributes && context.state.entity_attributes.custom_form_attributes_function)
            {
                context.on_field_blur("custom_form_attributes_function",context.state.entity_attributes.custom_form_attributes_function);
            }

            context.hidden_parent_style();
            /* end to load data */
            setTimeout(function(){
                context.post_init();
            }, 1);
            if (context.props.duplicate_id) {
                context.init_duplication();
            }
        };

        var error = function (err) {
            context.hidden_parent_style();
            context.setState({error: err.message});
            server_error_action(err);
        };

        if (given_data && !given_data.is_reference_nest) { //nested edit_form

            if (given_data.is_new_instance) {
                is_new_instance = true;
            }
            else {
                given_data.update_fields = {};
                given_data.init = true;
                setTimeout(function(){
                    context.post_init(given_data.entity_attributes);
                }, 1);
                if (given_data.entity_attributes.caption_fields && given_data.entity_attributes.caption_fields.length > 0) {
                    given_data.caption_function = function (entity_data) {
                        return make_friendly_caption(given_data.entity_attributes.caption_fields, entity_data, given_data.entity_attributes.attributes);
                    };
                }
                return given_data;
            }
        }
        else { //top-level edit_form
            current_navigation_listener = function (callback, optional_context_override) {
                if(optional_context_override) {
                    context = optional_context_override;
                }
                if (context.state.modified) {
                    return context.confirm(R.label_confirm, R.message_changes_discarded, function (confirmation) {
                        if (confirmation) {
                            return callback(true);
                        }
                        else {
                            return callback(false);
                        }
                    });
                }
                else {
                    return callback(true);
                }
            };
        }

        var request = {
            app_object_code: this.props.app_object_code || this.props.data.app_object_code || this.props.data.app_object.code,
            separate_values: true
        };
        if (!new_entity_json && this.props._id) {
            request._id = this.props._id;
        }
        else if (this.state && this.state.is_new_instance != undefined) {
            request._id = this.state._id;
            is_new_instance = this.state.is_new_instance;
        }
        else {
            is_new_instance = true;
        }

        /*Task/85652962 flag */
        var not_get_by_code;
        try {
            if( (typeof this.state.not_get_by_code != "undefined" ) ){
                not_get_by_code = this.state.not_get_by_code ;
            }
        }catch(err) {}
        if(!not_get_by_code){
            invoke_method("app_object", "get_by_code", request, success, error);
        }

        $(document).on('hidden.bs.modal', '#recurrencemodal', function () {
            $('body').addClass('modal-open');
        });
        this.debugLog('initialize_edit_form', false);
    },
    init_duplication: function(){
        var context = this;
        var request = {
            app_object_code: context.props.app_object_code || context.props.data.app_object_code || context.props.data.app_object.code,
            separate_values: true
        };
        request._id = context.props.duplicate_id;
        var success = function (data) {
            fix_response_data(data, data.data.entity_attributes.field_paths);
            data = data.data;
            var look_up_field;
            //Checks for lookup fields.
            for (var i in data.entity_attributes.attributes) {
                if (data.entity_attributes.attributes[i].primary_key && data.entity_attributes.attributes[i].primary_key == true) {
                    look_up_field = data.entity_attributes.attributes[i].field_path;
                    data.entity_attributes.attributes[i].required = false;
                }
            }
            //Deletes data that is not going to be duplicated.
            if (data.values) {
                for (var i in data.values) {
                    if (i == look_up_field || i == '_id' || i == 'entity_created_by' ||
                        i == 'entity_created_on' || i == 'entity_last_updated_by' || i == 'entity_last_updated_on' ||
                        i == 'invoice_number' || i == 'entity_name' || i == 'assigned_to_private_flag' ||
                        i == 'assigned_to_user' || i == 'following_users' || i == 'following_flag' || i == 'workflow_status') {
                        delete data.values[i];
                    }
                }
                data.values.xenforma_notes = '';
                data.entity_instance = data.values;
                delete data.values;
            }
            data.app_object.name = data.entity_attributes.caption_singular;
            context.setState(data);
            context.setState({update_fields: data.entity_instance});
            context.hidden_parent_style();
            /* end to load data */
            context.post_init();
        };

        var error = function (err) {
            context.hidden_parent_style();
            context.setState({error: err.message});
            server_error_action(err);
        };
        invoke_method("app_object", "get_by_code", request, success, error);
    },
    post_init: function (entity_attributes) {
        this.debugLog('post_init', true);

        var context = this;

        if (!entity_attributes) {
            entity_attributes = this.state.entity_attributes;
        }
        for (var i = 0; entity_attributes && i < entity_attributes.attributes.length; i++) {
            var attribute = entity_attributes.attributes[i];
            if(attribute.field_path == "assigned_to_user")
            {
                this.refresh_reference_grid(attribute);
            }
            if (!attribute.form_visible) {
                continue;
            }
            if (attribute.is_nested_entity && attribute.data_is_nested == false && attribute.is_array) {
                this.refresh_reference_grid(attribute);
            }
            else if(attribute.lookup && !attribute.is_array){
                this.init_lookup(attribute);
            }
            else if(attribute.entity_reference_variant){
                this.init_variant(attribute);
            }
        }
        //show modal after everything is rendered
        $("#modal_form").css( "visibility", "visible" );
        $("#modal_form").css( "overflow", "hidden" );
        if(!context.props.duplicate_id){ if(context.props.handle_executing) context.props.handle_executing(false);}
        if (this.state.is_new_instance) {
            this.focus_first_input();
        }
        this.debugLog('post_init', false);
    },
    refresh_reference_grid: function (attribute, callback) {
        this.debugLog('refresh_reference_grid', true);
        var context = this;
        var entity = attribute.db_type;
        var request = {};
        if (attribute.entity_reference && attribute.entity_reference.is_list){
            attribute.refresh_on_load = callback;
        }
        else{
            request.app_object_code = this.props.app_object_code || this.props.data.app_object_code || this.props.data.app_object.code;
            request.parent_entity_name = attribute.parent_entity_name;
            request.field_path = attribute.field_path;
            request.is_new = context.state.is_new_instance;

            var success = function (attribute) {
                return function (data) {
                    fix_response_data(data);
                    data = data.data;
                    if (!attribute.select_options) {
                        attribute.select_options = [];
                    }
                    var optionToChange;
                    if (attribute.select_options){
                        for (var i = 0, u = attribute.select_options.length; i<u;i++){
                            optionToChange = find_by_field('entity_id', attribute.select_options[i].entity_id, data);
                            if (!optionToChange){
                                data.push(attribute.select_options[i]);
                            }
                        }
                    }
                    attribute.select_options = data;
                    if (['assigned_to_user'].indexOf(attribute.field_path != -1)){
                        context.forceUpdate();
                    }
                    if (callback) {
                        callback(data);
                    }
                }
            }(attribute);

            var error = function (error) {
                context.setState({error: error.message});
                server_error_action(error);
            };
            invoke_method(entity, "read_with_nested_permissions", request, success, error);
        }
        this.debugLog('refresh_reference_grid', false);
    },
    init_lookup: function (attribute) {
        this.debugLog('init_lookup', true);
        var initial_value = this.state.entity_instance[attribute.field_path];
        var context = this;
        if (!attribute.select_options) {
            attribute.select_options = [];
        }
        if (initial_value) {
            attribute.select_options.push(initial_value);
        }

        context.setState({init_lookup: true});
        this.debugLog('init_lookup', false);
    },
    init_variant: function (attribute) {
        this.debugLog('init_variant', true);
        var initial_value = this.state.entity_instance[attribute.field_path];
        var context = this;
        context.state.init_variant = true;
        if (!attribute.select_options) {
            attribute.select_options = [];
        }
        if (initial_value){
            attribute.select_options.push(initial_value);
        }
        context.setState({init_variant: true});
        this.debugLog('init_variant', false);
    },
    variant_load:function(attribute){
        this.debugLog('variant_load', true);
        var context = this;
        if (attribute.dataSource){
            this.debugLog('variant_load', false);
            return attribute.dataSource;
        }
        var dataSource = new DevExpress.data.DataSource({
            paginate:true,
            load: function (loadOptions) {
                var d = new $.Deferred();
                var params = {};
                var context = this;
                //Getting filter options
                if (loadOptions.filter)  {
                    params.filter = JSON.stringify(loadOptions.filter);
                }
                //Getting sort options
                if (loadOptions.sort)  {
                    params.sort = JSON.stringify(loadOptions.sort);
                }
                //Getting group options
                if (loadOptions.group)  {
                    params.group = JSON.stringify(loadOptions.group);
                }
                //skip and take are used for paging
                params.skip = loadOptions.skip; //A number of records that should be skipped
                params.take = loadOptions.take; //A number of records that should be taken

                //If the select expression is specified
                if (loadOptions.select)  {
                    params.select= JSON.stringify(loadOptions.select);
                }

                //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup

                if (loadOptions.searchValue)  {
                    params.searchValue=loadOptions.searchValue;
                    params.searchOperation= loadOptions.searchOperation;
                    params.searchExpr= loadOptions.searchExpr;
                }

                var request = {};
                request.data={};
                request.data.entity_reference_category = attribute.entity_reference_variant.entity_reference_category;
                request.data.skip = loadOptions.skip; //A number of records that should be skipped
                request.data.limit = loadOptions.take; //A number of records that should be taken
                if(loadOptions.searchValue) {
                    request.data.conditions={};
                    request.data.conditions.$regex = loadOptions.searchValue;
                    request.data.conditions.$options = "i";
                }
                var success = function (attribute) {
                    return function (data) {
                        fix_response_data(data);
                        data = data.data;
                        context[attribute.field_path+"array"] = data;
                        d.resolve(data);
                    }
                }(attribute);

                var error = function (error) {
                    context.setState({error: error.message});
                    server_error_action(error);
                    d.fail(error);
                };

                if (!context.isLoading){
                    context.isLoading = true;
                    do_authenticated_http_call({
                        method: "POST",
                        url: "/api/entity/variant_reference_entity_lookup",
                        contentType: "application/json",
                        dataType: 'json',
                        data: JSON.stringify(request),
                        success: success,
                        error: error,
                        complete: function(){context.isLoading = false;}
                    });
                }
                return d.promise();
            },
            byKey: function (key){
                var retval;
                if (key){
                    var items = attribute.dataSource.items();
                    retval = find_by_field('entity_id',key, items);
                    if (!retval){ retval = find_by_field('entity_id',key, attribute.select_options);}
                }
                if (!retval){
                    if (!key){
                        retval = context.state.entity_instance[attribute.field_path];
                    }else{
                        if ((context.state.entity_instance[attribute.field_path] &&
                            key == context.state.entity_instance[attribute.field_path].entity_id)){
                            retval = context.state.entity_instance[attribute.field_path];
                        }
                    }
                }
                return retval;
            }
        });
        attribute.dataSource = dataSource;
        this.debugLog('variant_load', false);
        return dataSource
    },
    autocomplete_load:function(attribute){
        this.debugLog('autocomplete_load', true);
        var context = this;
        if (attribute.dataSource){
            this.debugLog('autocomplete_load', false);
            return attribute.dataSource;
        }
        var dataSource = new DevExpress.data.DataSource({
            paginate:true,
            load: function (loadOptions) {
                var d = new $.Deferred();
                var params = {};
                //Getting filter options
                if (loadOptions.filter)  {
                    params.filter = JSON.stringify(loadOptions.filter);
                }
                //Getting sort options
                if (loadOptions.sort)  {
                    params.sort = JSON.stringify(loadOptions.sort);
                }
                //Getting group options
                if (loadOptions.group)  {
                    params.group = JSON.stringify(loadOptions.group);
                }
                //skip and take are used for paging
                params.skip = loadOptions.skip; //A number of records that should be skipped
                params.take = loadOptions.take; //A number of records that should be taken

                //If the select expression is specified
                if (loadOptions.select)  {
                    params.select= JSON.stringify(loadOptions.select);
                }

                //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup


                params = [];
                var request = {}
                request.data={};
                request.data.entity = context.state.entity_attributes.entity;
                request.data.field_path = attribute.field_path;
                request.data.skip = loadOptions.skip; //A number of records that should be skipped
                request.data.limit = loadOptions.take; //A number of records that should be taken
                if(loadOptions.searchValue) {
                    request.data.conditions={};
                    request.data.conditions.$regex = loadOptions.searchValue;
                    request.data.conditions.$options = "i";
                    params.push(loadOptions.searchExpr);
                    params.push(loadOptions.searchOperation);
                    params.push(loadOptions.searchValue);

                    // request.data.conditions = make_conditions_from_devextreme_filter(params);
                }
                var success = function (attribute) {
                    return function (data) {
                        fix_response_data(data);
                        data = data.data;
                        d.resolve(data);
                    }
                }(attribute);

                var error = function (error) {
                    context.setState({error: error.message});
                    server_error_action(error);
                };

                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/entity/autocomplete_lookup",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: success,
                    error: error
                });
                return d.promise();
            },
            byKey: function (key){
                var retval;
                if (key){
                    var items = attribute.dataSource.items();
                    retval = find_by_field('entity_id',key, items);
                    if (!retval){ retval = find_by_field('entity_id',key, attribute.select_options);}
                }
                if (!retval){
                    if (!key){
                        retval = context.state.entity_instance[attribute.field_path];
                    }else{
                        if ((context.state.entity_instance[attribute.field_path] &&
                            key == context.state.entity_instance[attribute.field_path].entity_id)){
                            retval = context.state.entity_instance[attribute.field_path];
                        }
                    }
                }
                return retval;
            }
        });
        attribute.dataSource = dataSource;
        this.debugLog('autocomplete_load', false);
        return dataSource
    },
    lookup_load:function(attribute) {
        this.debugLog('lookup_load', true);
        var context = this;
        if (attribute.dataSource){
            this.debugLog('lookup_load', false);
            return attribute.dataSource;
        }
        var entity = attribute.db_type;
        var dataSource = new DevExpress.data.DataSource({
            paginate:true,
            load: function (loadOptions) {
                var d = new $.Deferred();
                var ds = attribute.dataSource;
                if (ds.isLoadingFlag) {
                    d.reject(new Error("DataSource is busy..."))
                }
                else {
                    ds.isLoadingFlag = true;
                    var params = {};
                    //Getting filter options
                    if (loadOptions.filter) {
                        params.filter = JSON.stringify(loadOptions.filter);
                    }
                    //Getting sort options
                    if (loadOptions.sort) {
                        params.sort = JSON.stringify(loadOptions.sort);
                    }
                    //Getting group options
                    if (loadOptions.group) {
                        params.group = JSON.stringify(loadOptions.group);
                    }
                    //skip and take are used for paging
                    params.skip = loadOptions.skip; //A number of records that should be skipped
                    params.take = loadOptions.take; //A number of records that should be taken

                    //If the select expression is specified
                    if (loadOptions.select) {
                        params.select = JSON.stringify(loadOptions.select);
                    }

                    //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup

                    if (loadOptions.searchValue) {

                        params.searchValue = loadOptions.searchValue;
                        params.searchOperation = loadOptions.searchOperation;
                        params.searchExpr = loadOptions.searchExpr;

                    }

                    params = [];
                    var request = {};
                    request.app_object_code = context.props.app_object_code || context.props.data.app_object_code || context.props.data.app_object.code;
                    request.parent_entity_name = attribute.parent_entity_name;
                    request.field_path = attribute.field_path;
                    request.is_new = context.state.is_new_instance;
                    request.skip = loadOptions.skip; //A number of records that should be skipped
                    request.limit = loadOptions.take; //A number of records that should be taken
                    if (loadOptions.searchValue && (!attribute.is_array)) {
                        params.push(loadOptions.searchExpr);
                        params.push(loadOptions.searchOperation);
                        params.push(loadOptions.searchValue);
                        request.conditions = make_conditions_from_devextreme_filter(params);
                    }
                    var success = function (attribute) {
                        return function (data) {
                            if (data.data.length < 11 && dataSource.items().length == 0) {
                                dataSource.paginate(false);
                            }
                            else {
                                dataSource.paginate(true);
                            }
                            fix_response_data(data);
                            data = data.data;
                            var optionToChange;
                            /*merge existing records with incoming*/
                            if (attribute.select_options && !loadOptions.searchValue){ //unless it's a search.
                                for (var i = 0, u = attribute.select_options.length; i<u;i++){
                                    optionToChange = find_by_field('entity_id', attribute.select_options[i].entity_id, data);
                                    if (!optionToChange){
                                        data.push(attribute.select_options[i]);
                                    }
                                }
                            }
                            /**/
                            attribute.select_options = data;

                            d.resolve(data);
                        }
                    }(attribute);

                    var error = function (error) {
                        context.setState({error: error.message});
                        server_error_action(error);

                    };
                    invoke_method(entity, "read_with_nested_permissions", request, success, error, function(){
                        ds.isLoadingFlag = false;
                    });
                }
                return d.promise();
            },
            byKey: function (key){
                var retval;
                if (key){
                    var items = attribute.dataSource.items();
                    retval = find_by_field('entity_id',key, items);
                    if (!retval){ retval = find_by_field('entity_id',key, attribute.select_options);}
                }
                if (!retval){
                    if (!key){
                        retval = context.state.entity_instance[attribute.field_path];
                    }else{
                        if ((context.state.entity_instance[attribute.field_path] &&
                            key == context.state.entity_instance[attribute.field_path].entity_id)){
                            retval = context.state.entity_instance[attribute.field_path];
                        }
                    }
                }
                return retval;
            },
            loadError: function (key){
                console.log(key);
            }
        });
        attribute.dataSource = dataSource;
        this.debugLog('lookup_load', false);
        return dataSource;
    },
    populate_update_fields: function (entity_attributes, entity_instance_data, out_val) {
        this.debugLog('populate_update_fields', true);
        if (!out_val) {
            out_val = this.state.update_fields || {};
        }

        for (var i = 0; i < entity_attributes.attributes.length; i++) {
            var attribute = entity_attributes.attributes[i];
            if (attribute.form_visible) {
                var attribute_value;

                attribute_value = entity_instance_data[attribute.field_path];

                //if(attribute_value && (typeof attribute_value == "object")) {
                //    out_val[attribute.field_path] = ($.extend(true, {}, { value: attribute_value })).value;
                //}
                //else {
                    out_val[attribute.field_path] = attribute_value;
                //}
            }
        }
        this.debugLog('populate_update_fields', false);
        return out_val;
    },
    on_file_name_change: function (attribute, file) {
        this.debugLog('on_file_name_change', true);
        if (file) {
            this.state.update_fields['file_name'] = file.name;
            this.state.entity_instance['file_name'] = file.name;
        }
        else {
            this.state.update_fields['file_name'] = null;
            this.state.entity_instance['file_name'] = null;
        }
        if(attribute.field_path != "drive_url")
        {
            this.setState({
                fileToUpload: file,
                file_uploaded:true,
                error:undefined
            });
        }
        this.debugLog('on_file_name_change', false);
    },
    on_file_upload_end: function () {
        this.debugLog('on_file_upload_end', true);
        /*End to upload file */
        /* 84230669/add this file to function uploadFiles_array*/
        try{
            if( (typeof this.state.uploadFiles_array != 'undefined' ) ){

                this.state.uploadFiles_array( this.state.entity_instance._id , this.state.checkbox_fileupload );
            }

        }catch(err){  }
        this.state.update_fields['date_uploaded'] = new Date();
        this.state.entity_instance['date_uploaded'] = new Date();
        this.state.update_fields['uploaded'] = true;
        this.state.entity_instance['uploaded'] = true;
        this.state.fileToUpload = undefined;
        this.state.uploadFileModal = false;
        this.state.areButtonsDisabled = false;
        this.state.resetFileUpload = false;
        this.forceUpdate();
        this.handle_submit();
        this.debugLog('on_file_upload_end', false);
    },
    on_file_upload_cancel: function (event) {
        this.debugLog('on_file_upload_cancel', true);
        this.setState({uploadFileModal: false, areButtonsDisabled: false});
        this.debugLog('on_file_upload_cancel', false);
    },
    on_field_both: function (attribute,event) {
        this.on_field_change(attribute, event);
        this.on_field_blur(attribute, event);
    },
    on_field_change: function (field_change_event_args, event) {
        this.debugLog('on_field_change', true);
        field_change_event_args = field_change_event_args || {};
        var attribute, base_field_path;
        if(field_change_event_args.attribute) {
            attribute = field_change_event_args.attribute;
            base_field_path = field_change_event_args.base_field_path;
        }
        else {
            attribute = field_change_event_args;
        }
        attribute.was_clicked_once = true;
        attribute.cached_rendered_component = null;
        attribute.last_react_key = null;
        var full_field_path;
        if(base_field_path && (base_field_path != "")) {
            full_field_path = base_field_path + ":" + attribute.field_path;
        }
        else {
            full_field_path = attribute.field_path;
        }
        var isReferenced = attribute.is_nested_entity && !attribute.data_is_nested && !attribute.is_array,
            previousValue = get_json_data_using_field_paths(full_field_path, this.state.entity_instance),
            bothEmpty = !previousValue && !event,
            invalidate_html_template_cache = false;

        if (isReferenced && (bothEmpty || (previousValue && event && previousValue.entity_id && event.entity_id && (previousValue.entity_id == event.entity_id)))){
            this.debugLog('on_field_change', false);
            return
        }
        var previous_state = this.state.modified;
        if (!this.state.modified) {
            this.state.modified = true
        }
        this.state.error = undefined;
        if (attribute) {
            var new_value;
            var type = attribute.attribute_type == null ? attribute.db_type : attribute.attribute_type;
            if (event == null) {
                new_value = event; // event is data
            }
            else if (event.target) {
                switch (type) {
                    case "Boolean":
                        if(event.target.type=="button") {
                            $('#'+event.target.id.slice(0, -1)).find("button").addClass("btn-primary");
                            $('#'+event.target.id).removeClass("btn-primary");

                            if(event.target.innerText=="True" || event.target.innerText=="Yes") {
                                new_value = true;
                            }
                            else if(event.target.innerText=="False" || event.target.innerText=="No"){
                                new_value = false;
                            }
                            else{
                                new_value = null;
                            }
                        }
                        else{
                            new_value = event.target.checked;
                        }
                        break;
                    case "CheckBox":
                        var cb = event.target;
                        if (!cb.readOnly && cb.checked){
                            cb.readOnly=false;
                            new_value = true;

                        }
                        else if (!cb.readOnly && !cb.checked){
                            cb.readOnly=true;
                            new_value = false;

                        }
                        else if(cb.readOnly && cb.checked){
                            cb.readOnly=false;
                            cb.checked="";
                            cb.indeterminate=true;
                            new_value = null;
                        }
                        break;
                    case "Number":
                        if (!event.target.value.match(/^-?\d*(\.\d*)?$/g)) {
                            new_value = event.target.value.substr(0, event.target.value.length - 1);
                            if (!new_value.match(/^-?\d*(\.\d*)?$/g)) {
                                new_value = '';
                            }
                            this.state.modified = previous_state;
                        }
                        else
                            new_value = event.target.value;
                        break;
                    case "whole_number":

                        new_value = event.target.value;
                        if( isNaN(event.target.value) ||  (this.getType(event.target.value)=='float' || event.target.value.indexOf('.')!=-1)  ){
                            var element_substr=event.target.value.substr(0, event.target.value.length - 1);
                            new_value = isNaN(event.target.value) ? element_substr :  Math.round(element_substr);
                            if(this.getType(event.target.value) != 'string') {
                                this.state.modified = previous_state;
                            }
                        }
                        if(this.getType(event.target.value)=='string') new_value='';
                        new_value = $.trim(new_value);
                        if(isNaN(new_value))  new_value=0;
                        break;
                    case "positive_number":
                        if (!event.target.value.match(/^\d*(\.\d*)?$/g)) {
                            new_value = event.target.value.substr(0, event.target.value.length - 1);
                            if (!new_value.match(/^\d*(\.\d*)?$/g)) {
                                new_value = '';
                            }
                            this.state.modified = previous_state;
                        }
                        else
                            new_value = event.target.value;
                        break;
                    case "currency":
                        if (!event.target.value.match(/^-?\d*(\.\d*)?$/g)) {
                            new_value = event.target.value.substr(0, event.target.value.length - 1);
                            if (!new_value.match(/^-?\d*(\.\d*)?$/g)) {
                                new_value = '';
                            }
                            this.state.modified = previous_state;
                        }
                        else{
                            new_value = event.target.value;
                        }
                        break;
                    default:
                        new_value = event.target.value;
                        break;
                }
            }
            else if (event.component) { // Data coming from entity reference lookup
                new_value = event.component._valuesData; // Data is coming from DevExtreme component
            }
            else {
                new_value = event; // event is data
            }
            var update_fields_value = get_json_data_using_field_paths(full_field_path, this.state.update_fields);
            if(attribute.data_is_nested && update_fields_value != undefined) {
                if (attribute.field_path == "public" &&
                    this.props && this.props.data && this.props.data.app_object_code != undefined &&
                    this.props.data.app_object_code == "file_default_edit_form") {
                    this.setState({checkbox_fileupload: new_value});
                }
                if (attribute.is_nested_entity == false) {
                    invalidate_html_template_cache = set_json_data_using_field_paths(full_field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                }
                else {
                    if (this.state.is_new_instance) {
                        invalidate_html_template_cache = set_json_data_using_field_paths(full_field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                    }
                    else if (Array.isArray(update_fields_value)) {
                        if (update_fields_value.length) { //for adding new vlues:  The newest added value is generally at the top of the list
                            var entity_id;
                            if(new_value && new_value[0] && new_value[0]._id) {
                                entity_id = new_value[0]._id;
                            }
                            if(find_index_by_id(update_fields_value, entity_id) == null) {
                                update_fields_value.push(new_value[0]);
                            }
                        }
                        else {
                            add_to_list(update_fields_value, new_value);
                        }
                    }
                }
            }
            else{
                if(!attribute.data_is_nested) {
                    try{if(new_value.entity_id==''){new_value = null}}catch(e){}
                    if (!attribute.is_array || Array.isArray(new_value)){
                        if (attribute.db_type == 'file'){
                            if (this.state.popover) {
                                this.state.popover.hide();
                            }
                            if (attribute.loading){
                                if (this.state.popover_loading) {
                                    this.state.popover_loading.hide();
                                }
                                attribute.cancel_loading = true;
                            }
                        }
                        invalidate_html_template_cache = set_json_data_using_field_paths(full_field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                    }
                }
                else if(!attribute.is_nested_entity) {
                    invalidate_html_template_cache = set_json_data_using_field_paths(full_field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                }
            }
            if (attribute.revalidate_on_field_changed) {
                attribute.modified = true;
            }
        }
        if(invalidate_html_template_cache && this.state.entity_attributes) {
            this.state.entity_attributes.cached_custom_html_args = null;
        }
        this.forceUpdate();
        this.debugLog('on_field_change', false);
    },
    on_field_blur: function (field_change_event_args, event) {
        this.debugLog('on_field_blur', true);
        field_change_event_args = field_change_event_args || {};
        var attribute, base_field_path, nested_entity_name;
        if(field_change_event_args.attribute) {
            attribute = field_change_event_args.attribute;
            base_field_path = field_change_event_args.base_field_path;
            nested_entity_name = field_change_event_args.nested_entity_name;
        }
        else {
            attribute = field_change_event_args;
        }
        var full_field_path;
        if(base_field_path && (base_field_path != "")) {
            full_field_path = base_field_path + ":" + attribute.field_path;
        }
        else {
            full_field_path = attribute.field_path;
        }
        var context = this;
        var custom_attribute = "";
        var attribute_value;
        var entity_name = this.state.entity_attributes.entity;
        attribute.cached_rendered_component = null;
        if ((attribute.revalidate_on_field_changed && attribute.modified) || attribute == "custom_form_attributes_function" ) {
            if(attribute != "custom_form_attributes_function" ) {
                delete attribute.modified;
                attribute_value = get_json_data_using_field_paths(full_field_path, this.state.entity_instance);
                if (attribute_value && attribute.attribute_type == "email") {
                    if (!attribute_value.match(/^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/gi)) {
                        this.setState({error: R.invalid_email});
                    }
                }
            }
            else {
                custom_attribute = attribute;
                attribute = {};
            }
            if (!this.state.error&&!this.state.submitting&& !attribute.validating){
                attribute.validating = true;
                var request = {};
                request.field_path = full_field_path;
                request.entity_instance = {};
                if(custom_attribute != "custom_form_attributes_function" ) {
                    if (attribute.validation_requires_full_entity) {
                        request.entity_instance = $.extend(true, {}, this.state.entity_instance);
                        context.handle_deleted_records(request.entity_instance, true);
                        if (this.state.parent_react_entity && this.state.parent_entity_field_attribute && this.state.parent_entity_field_attribute.data_is_nested) {
                            request.parent_entity = this.get_top_parent_entity();
                        }
                    }
                    else {
                        if(nested_entity_name) {
                            entity_name = nested_entity_name;
                            request.entity_instance[attribute.field_path] = attribute_value;
                            request.field_path = attribute.field_path;
                        }
                        else {
                            request.entity_instance[full_field_path] = attribute_value;
                        }
                    }
                }
                var success = function (data) {
                    var invalidate_html_template_cache = false;
                    fix_response_data(data, context.state.entity_attributes.field_paths);
                    if (data.success) {
                        var updated_view_data = data.updated_view_data;
                        var entity_attributes = context.state.entity_attributes;
                        var modify_state = false;

                        if(updated_view_data && updated_view_data._id && updated_view_data._id.value && (updated_view_data._id.value == context.state.entity_instance._id)) { //indicates that these fields are intended for this entity
                            for (var field_path in updated_view_data) {
                                var entity_attribute_update = updated_view_data[field_path];
                                var entity_attribute = get_attribute_for_field(entity_attributes, field_path);
                                var entity_attribute_from_field_path = entity_attributes.field_paths[field_path]; //Unfortunately, at the moment, entity attributes stored in .field_paths are Not a shared reference with those in .attributes
                                for (var field_attribute in entity_attribute_update) {
                                    if (field_attribute == "value") {
                                        var matching_attribute = entity_attribute || entity_attribute_from_field_path || {};
                                        if(matching_attribute.is_array && matching_attribute.is_nested_entity && matching_attribute.data_is_nested) {
                                            //Don't set to update_fields because this could erase changes we need to keep (i.e. removals)
                                        }
                                        else {
                                            invalidate_html_template_cache = set_json_data_using_field_paths(field_path, context.state.update_fields, entity_attribute_update[field_attribute], true);
                                        }
                                        invalidate_html_template_cache = set_json_data_using_field_paths(field_path, context.state.entity_instance, entity_attribute_update[field_attribute], true, true, null, context.state.is_new_instance) || invalidate_html_template_cache;
                                        modify_state = true;
                                        if (entity_attribute) {
                                            entity_attribute.cached_rendered_component = null;
                                            entity_attribute.last_react_key = null;
                                        }
                                        if (entity_attribute_from_field_path) { //Unfortunately, at the moment, entity attributes stored in .field_paths are Not a shared reference with those in .attributes
                                            entity_attribute_from_field_path.cached_rendered_component = null;
                                            entity_attribute_from_field_path.last_react_key = null;
                                        }
                                    }
                                    else if (field_attribute == "errors") {
                                        if (entity_attribute_update.errors && entity_attribute_update.errors.length > 0) {
                                            context.state.error = entity_attribute_update.errors.join(' || ');
                                            if ("object" === typeof context.state.error) {
                                                context.state.error = "Database Error";
                                            }
                                            modify_state = true;
                                        }
                                    }
                                    else {
                                        if (entity_attribute) {
                                            entity_attribute.cached_rendered_component = null;
                                            entity_attribute[field_attribute] = entity_attribute_update[field_attribute];
                                            modify_state = true;
                                        }
                                        if (entity_attribute_from_field_path) { //Unfortunately, at the moment, entity attributes stored in .field_paths are Not a shared reference with those in .attributes
                                            entity_attribute_from_field_path.cached_rendered_component = null;
                                            entity_attribute_from_field_path[field_attribute] = entity_attribute_update[field_attribute];
                                            modify_state = true;
                                        }
                                    }
                                }
                            }
                        }
                        if (modify_state) {
                            if(invalidate_html_template_cache && context.state.entity_attributes) {
                                context.state.entity_attributes.cached_custom_html_args = null;
                            }
                            context.forceUpdate();
                        }
                    }
                };
                var error = function (err) {
                    context.setState({error: err.message});
                    server_error_action(err);
                };
                var final_request = $.extend(true, {}, request);
                if(custom_attribute != "custom_form_attributes_function" ) {
                    var field_paths = context.state.entity_attributes.field_paths;
                    fix_request_data(final_request, field_paths);
                }
                else{
                    final_request.entity_instance =context.state.entity_instance;
                    final_request.custom_form_attributes_function = event;
                    final_request.parent_entity = {};
                    var parent_state = context.props.data.parent_react_entity.state;
                    final_request.parent_entity._id = parent_state.entity_instance._id;
                    final_request.parent_entity.entity_name = parent_state.app_object.entity;
                    final_request.parent_entity.field_path = context.props.data.parent_entity_field_attribute.field_path+":"+"["+context.state.entity_instance._id+"]:"
                }
                final_request.entity_instance._id = context.state.entity_instance._id;
                invoke_method(entity_name, "execute_business_rule", final_request, success, error, function(){attribute.validating = false;});
            }
        }
        this.debugLog('on_field_blur', false);
    },
    getType : function (input) {
        this.debugLog('getType', true);
        var m = (/[\d]+(\.[\d]+)?/).exec(input), retval = 'string';
        if (m) {
            // Check if there is a decimal place
            if (m[1]) { retval = 'float'; }
            else { retval = 'int'; }
        }
        this.debugLog('getType', false);
        return retval;
    },
    get_field_path_from_parent_entity: function() {
        this.debugLog('get_field_path_from_parent_entity', true);
        var field_path;
        if(this.state && this.state.parent_entity_field_attribute && this.state.parent_entity_field_attribute.field_path) {
            field_path = this.state.parent_entity_field_attribute.field_path;
            if(this.state.parent_entity_array) { //Means this entity is a member of an array.  Should always be true if it has a parent entity.
                field_path = field_path + ":[" + this.state.entity_instance._id + "]:";
            }
        }
        this.debugLog('get_field_path_from_parent_entity', false);
        return field_path;
    },
    get_top_parent_entity: function() {
        this.debugLog('get_top_parent_entity', true);
        var parent_entity_info = this.get_parent_entity_helper();
        if(!parent_entity_info) {
            this.debugLog('get_top_parent_entity', false);
            return parent_entity_info;
        }
        var top_parent_entity = parent_entity_info;
        var field_path = parent_entity_info.field_path;
        while(top_parent_entity.parent_entity) {
            top_parent_entity = top_parent_entity.parent_entity;
            field_path = top_parent_entity.field_path + field_path;
        }

        top_parent_entity.field_path = field_path;
        this.debugLog('get_top_parent_entity', false);
        return top_parent_entity;
    },
    get_parent_entity_helper: function() {
        this.debugLog('get_parent_entity_helper', true);
        if(!this.state || !this.state.parent_react_entity || !this.state.parent_react_entity.state || !this.state.parent_react_entity.state.entity_instance || !this.state.parent_react_entity.state.entity_instance._id) {
            this.debugLog('get_parent_entity_helper', false);
            return undefined;
        }
        var parent_parent_entity;
        if(this.state.parent_react_entity.get_parent_entity_helper) {
            parent_parent_entity = this.state.parent_react_entity.get_parent_entity_helper();
        }
        this.debugLog('get_parent_entity_helper', false);
        return {
            _id: this.state.parent_react_entity.state.entity_instance._id,
            entity_name: this.state.parent_react_entity.state.entity_attributes.entity,
            parent_entity: parent_parent_entity,
            field_path: this.get_field_path_from_parent_entity()
        };
    },
    delete_nested_entity: function (nested_entity_field_attribute, nested_entity_array, entity_id) {
        this.debugLog('delete_nested_entity', true);
        var nested_entity_index = find_index_by_id(nested_entity_array, entity_id),
            elementPos = nested_entity_array.map(function(x) {return x.entity_id; }).indexOf(entity_id),
            delta;
        if(elementPos == -1)
        {
            elementPos = nested_entity_array.map(function(x) {return x; }).indexOf(entity_id);
        }
        if (elementPos != -1)
        {
            nested_entity_index = elementPos;
        }
        if (nested_entity_index != null) {
            delta = nested_entity_array.splice(nested_entity_index, 1)[0];
            if(this.state.entity_attributes) {
                this.state.entity_attributes.cached_custom_html_args = null; //will have to redraw to reflect new size of list
            }
        }
        if(!nested_entity_field_attribute.data_is_nested) {
            return this.on_field_change(nested_entity_field_attribute, nested_entity_array);
        }
        else{
            delta.change_type = "removal";
            //nested_entity_array.splice(nested_entity_index, 0, delta);
            if (!this.state.update_fields){
                this.state.update_fields = {};
            }
            if (!this.state.update_fields[nested_entity_field_attribute.field_path]){
                this.state.update_fields[nested_entity_field_attribute.field_path] = [];
            }
            var deleted_records_array_index = find_index_by_id(this.state.update_fields[nested_entity_field_attribute.field_path], entity_id);
            if (deleted_records_array_index == null){
                if(!this.state.is_new_instance) {
                    this.state.update_fields[nested_entity_field_attribute.field_path].push(delta);
                }
            }
            else if(this.state.is_new_instance) { //just remove it from the update fields
                this.state.update_fields[nested_entity_field_attribute.field_path].splice(deleted_records_array_index, 1);
            }
            else {
                this.state.update_fields[nested_entity_field_attribute.field_path][deleted_records_array_index].change_type = "removal";
            }
            nested_entity_field_attribute.modified = true;
            this.on_field_blur(nested_entity_field_attribute);
        }
        this.debugLog('delete_nested_entity', false);
    },
    update_from_response: function (entity_inst_deltas, optional_parent_field_path, startingPoint) {
        this.debugLog('update_from_response', true);

        var field_attributes = this.state.entity_attributes.attributes;
        for(var i = 0; i < field_attributes.length; i++) {
            //iterate through data for nested entity arrays
            var field_att = field_attributes[i];
            var field_name = field_att.field_path;
            if (field_att && field_att.is_nested_entity && field_att.data_is_nested && field_att.is_array) { //is a directly nested array
                if (entity_inst_deltas[field_name] && (entity_inst_deltas[field_name].length > 0)) {
                    for (var k = 0; k < entity_inst_deltas[field_name].length; k++) {
                        this.upsert_nested_entity(field_att, this.state.entity_instance[field_name], entity_inst_deltas[field_name][k]);
                    }
                }
                else if (entity_inst_deltas && (field_name == optional_parent_field_path)) {
                    this.upsert_nested_entity(field_att, this.state.entity_instance[field_name], entity_inst_deltas);
                }
            }
            else if (entity_inst_deltas.hasOwnProperty(field_name) && !this.state.entity_instance.hasOwnProperty(optional_parent_field_path)) {
                if (field_att.is_array && !Array.isArray(entity_inst_deltas[field_name])) {
                    if (this.state.entity_instance[field_name] && Array.isArray(this.state.entity_instance[field_name])) {
                        this.state.entity_instance[field_name].push(entity_inst_deltas[field_name]);
                    }
                    else {
                        this.state.entity_instance[field_name] = [entity_inst_deltas[field_name]];
                    }
                }
                else {
                    this.state.entity_instance[field_name] = entity_inst_deltas[field_name];
                }
                if (this.state.is_new_instance) {
                    this.on_field_change(field_att, entity_inst_deltas[field_name]);
                }
            }
        }

        this.close_nested_entity_view(null, startingPoint);
        this.forceUpdate();
        this.debugLog('update_from_response', false);
    },
    upsert_nested_entity: function (nested_entity_field_attribute, nested_entity_array, entity_inst_deltas) {
        this.debugLog('upsert_nested_entity', true);
        var entity_id = entity_inst_deltas._id;
        var has_change = true;
        var change_type;
        delete nested_entity_field_attribute.cached_rendered_component;
        delete nested_entity_field_attribute.last_react_key;
        if(this.state.entity_attributes && this.state.entity_attributes.field_paths && this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path] && this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path].cached_rendered_component) {
            delete this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path].cached_rendered_component;
            delete this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path].last_react_key;
        }
        var current_change_array = this.state.update_fields[nested_entity_field_attribute.field_path] || [];
        var nested_entity_index = find_index_by_id(nested_entity_array, entity_id);
        var current_change_array_index = find_index_by_id(current_change_array, entity_id);
        if (nested_entity_index != null) {
            if (nested_entity_array[nested_entity_index].change_type == "removal") {
                change_type = "removal";
                if (current_change_array_index != null) {
                    if(this.state.is_new_instance) {
                        //remove from update_fields
                        current_change_array.splice(current_change_array_index, 1);
                    }
                    else {
                        current_change_array[current_change_array_index] = {_id: entity_id, change_type: "removal"};
                    }
                }
                else {
                    if(!this.state.is_new_instance) {
                        current_change_array.push({_id: entity_id, change_type: "removal"});
                    }
                }
            }
            else {
                change_type = "modified";
                has_change = false;
                for (var field_name in entity_inst_deltas) {
                    if (field_name != "_id") {
                        has_change = true;
                        break;
                    }
                }
                $.extend(nested_entity_array[nested_entity_index], entity_inst_deltas);
                //Updates have been changed to happen instantly, so no need to track in update_fields, unless it's a new entity
                if(this.state.is_new_instance) {
                    current_change_array[current_change_array_index] = nested_entity_array[nested_entity_index];
                }
            }
        }
        else {
            change_type = "created";
            if (nested_entity_array) {
                nested_entity_array.unshift(entity_inst_deltas);
            }
            //Inserts have been changed to happen instantly, so no need to track in update_fields, unless it's a new entity
            if(this.state.is_new_instance) {
                current_change_array.push(entity_inst_deltas);
            }
        }
        if (has_change) {
            if (!this.state.update_fields[nested_entity_field_attribute.field_path]){
                this.state.update_fields[nested_entity_field_attribute.field_path] = [];
            }
            entity_inst_deltas.change_type = change_type;
            var deleted_records_array = this.state.update_fields[nested_entity_field_attribute.field_path];
            for (var i = 0; i < current_change_array.length; i++) { //don't let nested_entity_array get out of sync with current_change_array after updates
                if (current_change_array[i] && current_change_array[i]._id) {
                    entity_id = current_change_array[i]._id;
                    if ((current_change_array[i].change_type == "removal")) {
                        var deleted_entry = find_by_field('_id', entity_id, deleted_records_array);
                        if (!deleted_entry) {
                            deleted_records_array.push(current_change_array[i]);
                        }
                        else {
                            deleted_entry.change_type = "removal";
                        }
                    }
                }
            }

            this.on_field_change(nested_entity_field_attribute, nested_entity_array);
            set_json_data_using_field_paths(nested_entity_field_attribute.field_path, this.state.update_fields, current_change_array, true); //overrides on_field_change default behavior
            this.on_field_blur(nested_entity_field_attribute);
        }
        this.debugLog('upsert_nested_entity', false);
    },
    close_nested_entity_view: function (added_id, startingPoint) {
        this.debugLog('close_nested_entity_view', true);
        this.recover_scroll = true;
        var newEntity = this.state.child_entity_state_data;
        delete this.state.child_entity_state_data;
        delete this.state.reference_entity_id;

        if(this.state.individual_attributes_per_entity && newEntity && newEntity.base_field_path) {
            var local_entity_attributes = this.state.individual_attributes_per_entity[newEntity.base_field_path];
            if(local_entity_attributes) {
                for(var local_field_path in local_entity_attributes) {
                    if(local_entity_attributes.hasOwnProperty(local_field_path) && local_entity_attributes[local_field_path] && local_entity_attributes[local_field_path].cached_rendered_component) {
                        delete local_entity_attributes[local_field_path].cached_rendered_component; //force redraw of this attribute
                    }
                }
            }
        }

        var context = this, i;
        if(added_id) {
            if (!context.state.modified) {
                context.state.modified = true;
            }
        }
        if (this.state.nested_reference_entity_data) {
            var attribute = this.state.nested_reference_entity_data.attribute;
            if (attribute.entity_reference_variant){
                context.variant_ref_add_button(attribute);
            } else {
                if (!context.state.grid_is_on_edit_form) {
                    context.refresh_reference_grid(attribute, function (data_list) {
                        if (data_list &&
                            typeof context.state.entity_instance[attribute.field_path] != "undefined" &&
                            context.state.entity_instance[attribute.field_path] != null) {
                            for (i = 0; i < context.state.entity_instance[attribute.field_path].length; i++) {
                                if (typeof context.state.entity_instance[attribute.field_path][i] == "object") {
                                    context.state.entity_instance[attribute.field_path][i] = context.state.entity_instance[attribute.field_path][i]["entity_id"];
                                }
                            }
                            if (Array.isArray(context.state.entity_instance[attribute.field_path])) {
                                if (context.state.privateFiles &&
                                    context.state.privateFiles[attribute.field_path] &&
                                    attribute.db_type == "file") {
                                    for (i = 0; i < context.state.privateFiles[attribute.field_path].length; i++) {
                                        var exists = false;
                                        for (var j = 0; j < data_list.length; j++) {
                                            if (context.state.privateFiles[attribute.field_path][i] == undefined) {
                                                exists = true;
                                                delete context.state.privateFiles[attribute.field_path][i];
                                                break;
                                            }
                                            else if (context.state.privateFiles[attribute.field_path][i].entity_id == undefined) {
                                                exists = true;
                                                delete context.state.privateFiles[attribute.field_path][i];
                                                break;
                                            }
                                            else if (context.state.privateFiles[attribute.field_path][i] == data_list[j].entity_id) {
                                                exists = true;
                                                break;
                                            }
                                            else {
                                                if (data_list[j].entity_id == context.state.privateFiles[attribute.field_path][i].entity_id) {
                                                    exists = true;
                                                    break;
                                                }
                                            }

                                        }
                                        if (!exists) {
                                            data_list.push(context.state.privateFiles[attribute.field_path][i]);
                                            context.state.new_filelist_data = data_list;
                                        }
                                        context.state.new_filelist_data = data_list;
                                    }
                                }
                                var elementPos = context.state.entity_instance[attribute.field_path].map(function (x) {
                                    return x;
                                }).indexOf(added_id);
                                if (elementPos == -1) {
                                    for (var i = 0; i < data_list.length; i++) {
                                        if (data_list[i].entity_id == added_id) {
                                            context.state.entity_instance[attribute.field_path].push(added_id);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
            delete this.state.nested_reference_entity_data;
        }
        this.state.areButtonsDisabled = false;
        this.setState(this.state);
        /*End to upsert*/
        if(context.state.tag_template){
            context.setState({tag_template: false});
        }
        context.recovery_parent_style();
        if (startingPoint){
            this.nested_entity_handler(newEntity);
        } else{
            if (this.props.handle_executing) {
                this.props.handle_executing(false);
            }
        }
        this.debugLog('close_nested_entity_view', false);
    },
    handle_close: function (event, fromAction) {
        this.debugLog('handle_close', true);
        this.close_all_tooltip_popovers();
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (this.state.areButtonsDisabled){
            this.debugLog('handle_close', false);
            return;
        }

        if (this.props.handle_executing) {
            this.props.handle_executing(false);
        }
        var context = this;
        if($('.dx-dialog.dx-overlay.dx-popup').length && context.confirming){
            context.pop_up = true;
        }
        var close_func;
        if (context.props.data && context.props.data.parent_react_entity) {
            context.recovery_parent_style();
            //If changes have been made prompt the user
            if (context.state.modified && (context.state.update_fields.content != "" || context.state.update_fields.subject != null )) {
                context.debugLog('handle_close', false);
                return context.confirm(R.label_confirm, R.message_changes_discarded, function (confirmation) {
                    if (confirmation) {
                        return context.props.data.parent_react_entity.close_nested_entity_view();
                    }
                });
            }
            else {
                context.debugLog('handle_close', false);
                return context.props.data.parent_react_entity.close_nested_entity_view();
            }
        }
        else {
            close_func = function () {
                current_navigation_listener = undefined;
                if (context.props.isModal) {
                    if (event) {// action comes from button
                        var success;
                        var error;
                        /* 84230669/ list of files uploaded ,trying for delete file when instance  is closed */
                        try {
                            if ((typeof context.state.filesUploaded != 'undefined' )) {
                                for(var i=0; i<context.state.filesUploaded.length; i++ ){
                                    var currentNewFileUpload=context.state.filesUploaded[i]._id;
                                    var is_public=context.state.filesUploaded[i].is_public;
                                    if( is_public=="false" ){
                                        var data = {_id: currentNewFileUpload};
                                        invoke_method("file", "delete", data, success, error);
                                    }
                                }
                            }
                        }catch (err){
                        }
                        context.props.navigation_handler("close", context.state.force_grid_refresh);
                    }
                    else {
                        if (fromAction) {
                            context.props.navigation_handler("workflow_action", context.state.force_grid_refresh);
                        }
                        else {
                            context.props.navigation_handler(context.state.entity_instance, context.state.force_grid_refresh);
                        }
                    }
                    return;
                }
                var ie11andabove = navigator.userAgent.indexOf('Trident') != -1 && navigator.userAgent.indexOf('MSIE') == -1;
                var ie10andbelow = navigator.userAgent.indexOf('MSIE') != -1;
                var history_length = (ie11andabove||ie10andbelow)?3:2;
                if (window.history.length && (window.history.length > history_length) && context.props.app_object_code != "user_invite") {
                    context.debugLog('handle_close', false);
                    window.history.back();
                }
                else {
                    context.debugLog('handle_close', false);
                    context.props.navigation_handler("home");
                }
            }
        }
        if (current_navigation_listener) {
            return current_navigation_listener(function (result) {
                if (result) {
                    context.debugLog('handle_close', false);
                    return close_func();
                }
            }, context);
        }
        else {
            context.debugLog('handle_close', false);
            return close_func();
        }
    },
    tagTemplate: function(local_attribute, itemData,itemElement){
        this.debugLog('tagTemplate', true);
        var context = this, retval;
        if(local_attribute && local_attribute.db_type =="file") {
            var deleteb_handler = function (target) {
                if (target && target.className == "dx-tag-remove-button") {
                    var entity_id = target.id;
                    context.setState({tag_template: false});
                    if(context.state.popover){
                        context.state.popover.hide();
                    }
                }
                else {

                var entity_id = context.state.tag_template_values._id;
                context.setState({tag_template: false});
                context.state.popover.hide();
            }
                var elementPos = context.state.entity_instance[local_attribute.field_path].map(function(x) {return x; }).indexOf(entity_id);
                if (elementPos != -1)
                {
                    context.state.entity_instance[local_attribute.field_path].splice(elementPos, 1);
                    context.forceUpdate();
                }
                else{
                    elementPos = context.state.entity_instance[local_attribute.field_path].map(function(x) {return x.entity_id; }).indexOf(entity_id);
                    if (elementPos != -1)
                    {
                        context.state.entity_instance[local_attribute.field_path].splice(elementPos, 1);
                        context.forceUpdate();
                    }
                }
                context.on_field_change(local_attribute, context.state.entity_instance[local_attribute.field_path]);

            };
            var editt_handler = function () {
                var entity_id =context.state.tag_template_values._id;
                context.setState({reference_entity_id: entity_id, currentAttribute:local_attribute});
                context.setState({tag_template: false});
                context.forceUpdate();
                context.reference_entity_handler(local_attribute,entity_id);
                context.state.popover.hide();
            };
            var downloadt_handler = function () {

                var entity_id =context.state.tag_template_values._id;
                context.setState({tag_template: false});
                context.state.popover.hide();
                var d_data = {
                    file_id: entity_id
                };
                var download_success = function(response){
                    function saveFile(url) {
                        // Get file name from url.
                        var filename = context.state.tag_template_values.file_name;
                        var xhr = new XMLHttpRequest();
                        xhr.onload = function() {
                            window.saveAs(xhr.response, filename);
                        };
                        xhr.open('GET', url);
                        xhr.responseType = 'blob';
                        xhr.send();
                    }
                    saveFile(response.data.url);
                };
                var download_error = function(error){
                    context.setState({error: error.message});
                    server_error_action(error);
                };
                if(context.state.tag_template_values.drive_url){
                    if(context.state.tag_template_values.drive_url){
                        window.open(context.state.tag_template_values.drive_url);
                    }
                    else{

                        invoke_method("file", "get_file", d_data, download_success, download_error);
                    }
                }
                else {
                    invoke_method("file", "get_file", d_data, download_success, download_error);
                }
            };
            var Filetaghandler = function (event) {
                local_attribute.loading = true;
                if(event.target.className == "dx-tag-remove-button")
                {
                   deleteb_handler(event.target);
                    return;
                }
                event.preventDefault();
                event.stopPropagation();
                context.setState({tag_popover_loading: false, tag_template: false});
                $("#" + local_attribute.field_path + " .dx-tagbox").dxTagBox("instance").close();
                if (context.state.popover_loading){
                    context.state.popover_loading.hide();
                }
                context.searching_file = true;
                var request = {
                    _id: itemData.entity_id
                };


                var success = function (response) {
                    if (!local_attribute.cancel_loading){
                        var id_tag = local_attribute.field_path + '_' + response.data.values._id;
                        context.searching_file = false;
                        response.data.values.entity = "file";
                        context.setState({tag_template: true});
                        context.setState({tag_template_values: response.data.values});
                        context.open_popover(id_tag);
                        setTimeout(function(){
                            var element = $("#edit_" + local_attribute.field_path + '_' + itemData.entity_id);
                            element.unbind('click');
                            element.bind('click', editt_handler);
                            element = $("#download_" + local_attribute.field_path + '_' + itemData.entity_id);
                            element.unbind('click');
                            element.bind('click', downloadt_handler);
                            element = $("#delete_" + local_attribute.field_path + '_' + itemData.entity_id);
                            element.unbind('click');
                            element.bind('click', deleteb_handler);
                            $("#" + local_attribute.field_path).find(".dx-tagbox").dxTagBox("instance").close();
                            if (context.state.popover_loading){
                                context.state.popover_loading.hide();
                            }
                        }, 1000);
                    }
                };

                var error = function (error) {
                    context.searching_file = false;
                    context.setState({error: error.message});
                    server_error_action(error);
                };
                if (!local_attribute.cancel_loading){
                    context.open_popover_loading($(event.target).parents('.dx-tag-content').attr('id'));
                    invoke_method("file", "get_file_summary_data", request, success, error, function(){
                        local_attribute.cancel_loading = false;
                        local_attribute.loading = false;
                    });
                }
            };
            var icon = itemData.fa_icon || 'fa-file';
            setTimeout(function(){
                var element = $("#" + local_attribute.field_path + '_' + itemData.entity_id);
                element.unbind('click');
                element.bind('click', Filetaghandler);
            }, 1000);
            var remove_button = !(context.form_is_read_only() || local_attribute.read_only)?'<div id="'+itemData.entity_id+'"class="dx-tag-remove-button"></div>':'';
            retval = '<div id="' + local_attribute.field_path + '_' + itemData.entity_id + '" class="dx-tag-content" ><span><div style="text-de coration: underline;margin: -5px;padding: 5px;>' +
                '<i style="text-decoration: none;margin-right:10px;" class="fa ' + icon + '" aria-hidden="true"></i>' +
                itemData.caption + '</div></span>' + remove_button + '</div>';
        }
        else{
            if (itemData.entity_id){
                retval = '<div id="'+itemData.entity_id+'">' +
                    '<i style=" style="color:#606060;margin-right:5px;" class="fa '+itemData.fa_icon+'" aria-hidden="true"></i>' +
                    itemData.caption + '</div>';
            } else retval = 'tag';
        }

        this.debugLog('tagTemplate', false);
        return retval;
    },
    open_popover:function(id_tag,callback) {
        this.debugLog('open_popover', true);
        var context = this;
        if(this.state.popover){
            this.state.popover.hide();
        }

        if (document.getElementById(id_tag) && !this.searching_file) {
        var popover_desc = $("#id" + id_tag).dxPopover({
            target: "#" + id_tag,
            position: "top",
            width: 300,
            onContentReady: callback,
            onHidden: function(){
                context.setState({tag_template: false});
            }
        }).dxPopover("instance");

        popover_desc.show();
        this.setState({popover: popover_desc});

    }
        else{
            if(this.state.popover)
            {
                this.state.popover.hide();
            }
            if(this.state.popover_loading)
            {
                this.state.popover_loading.hide();
            }

        }
        this.debugLog('open_popover', false);
    },
    open_popover_loading:function(id_tag,show){
        this.debugLog('open_popover_loading', true);
        if(this.state.popover) {
            this.state.popover.hide();
        }
        if (document.getElementById(id_tag)) {
            this.setState({tag_popover_loading: true,tag_template: true});
            var popover_desc = $("#Loading_tag").dxPopover({
                target: "#" + id_tag,
                position: "top",
                width: 300
            }).dxPopover("instance");
            this.setState({popover_loading: popover_desc});
            popover_desc.show();
        }
        else{
            if(this.state.popover)
            {
                this.state.popover.hide();
            }
            if(this.state.popover_loading)
            {
                this.state.popover_loading.hide();
            }

        }
        this.debugLog('open_popover_loading', false);
    },
    check_recurrent_send_data: function(send_data){
        this.debugLog('check_recurrent_send_data', true);
        if (this.state.entity_attributes) {
            for (var field_path in this.state.update_fields) {

                for(var i=0;i<this.state.entity_attributes.attributes.length;i++)
                {
                    var attr = this.state.entity_attributes.attributes[i];
                    if (attr.attribute_type == "Recurrent" && attr.field_path == field_path)
                    {
                        var value = this.state.update_fields[field_path];
                        if (value == null)
                        {
                            continue;
                        }
                        if (value.slice(0, 6) == 'RRULE:') {
                            value = value.substr(6);
                        }
                        try {
                            RRule.fromString(value);
                        }
                        catch(Exception) {
                            send_data[field_path] = null;
                        }
                    }
                }
            }
        }
        this.debugLog('check_recurrent_send_data', false);
    },
    handle_deleted_records: function(entity_instance) {
        var attribute_array = this.state.entity_attributes.attributes,
            current_attribute,
            value_array,
            deleted_record_array,
            i,j;
        for (i= 0; i<((attribute_array)? attribute_array.length : 0);i++) {
            current_attribute = attribute_array[i];
            if (current_attribute.is_array && this.state.update_fields) {
                value_array = entity_instance[current_attribute.field_path];
                deleted_record_array = (this.state.update_fields[current_attribute.field_path] || []).filter(function(a) { return a.change_type == "removal";});
                for (j = 0; j<((deleted_record_array)? deleted_record_array.length : 0); j++) {
                    if(deleted_record_array[j]) {
                        if (!find_by_field('_id', deleted_record_array[j]._id, value_array)) {
                        value_array.push(deleted_record_array[j]);
                    }
                }
            }
        }
        }
    },
    handle_submit: function (event, view_options, fromAction,startingPoint) {
        var context = this;
        var isIE = !!document.documentMode;
        if (isIE){
                if (this.state && this.state.error){
                    $('#edit_form').find('.widget-body').scrollTop(0);
                    $( ".ie-warning" ).css("display","block");
                }
        }
        this.debugLog('handle_submit', true);
        if (event && event.preventDefault) {
            this.recovery_parent_style();
            event.preventDefault();
        }
        this.handle_deleted_records(this.state.entity_instance);
        this.state.submitting = true;
        for(var attribute in this.state.entity_attributes){
            this.on_field_blur(attribute, event);
            if(this.state.error) {
                break;
            }
        }

        if (!this.state.error){
            var entity_method;
            var send_data = this.state.update_fields;
            this.check_recurrent_send_data(send_data);
            context.state.update_fields._id = this.state.entity_instance._id;
            var error = function (err) {
                context.hidden_parent_style();
                context.recovery_parent_style();
                if (context.props.handle_executing) {
                    context.props.handle_executing(false);
                }//scrollup
                context.setState({error: err.message});
                server_error_action(err);
            };
            var success;
            var entity_name = context.state.entity_attributes.entity;
            if (context.props.data && (context.props.data.parent_react_entity && !context.props.data.is_reference_nest)) { //don't save directly, but do validate first.
                if (!context.state.update_fields) {
                    context.recovery_parent_style();
                    return context.props.data.parent_react_entity.close_nested_entity_view();
                }
                for (var field_path in context.state.update_fields) {
                    if (context.state.update_fields[field_path] !== undefined) {
                        context.state.entity_instance[field_path] = context.state.update_fields[field_path];
                    }
                }
                if (context.state.parent_react_entity.state.is_new_instance) {
                    send_data = context.state.entity_instance; //validate whole entity as a stand-alone (for now).
                }
                else {
                    context.state.entity_instance.change_type = "modified";
                    if (context.state.is_new_instance) {
                        context.state.entity_instance.change_type = "created";
                    }
                    send_data = {};
                    /*If form-edit is modified ignore entities only send _id*/
                        send_data[context.state.parent_entity_field_attribute.field_path] = [context.state.entity_instance];
                    send_data._id = context.state.parent_react_entity.state.entity_instance._id;
                    entity_name = context.state.parent_react_entity.state.app_object.entity;
                }
                success = function (data) {
                    fix_response_data(data, context.state.entity_attributes.field_paths);
                    if(!context.props.data.parent_react_entity.state.is_new_instance) {
                        Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                    }
                    if(data && data.data) {
                        data = data.data;
                    }
                    else {
                        data = {};
                        data[context.state.parent_entity_field_attribute.field_path] = context.state.update_fields;
                    }
                    context.recovery_parent_style();
                    return context.props.data.parent_react_entity.update_from_response(data, context.state.parent_entity_field_attribute.field_path, startingPoint); //field_path is for new entities
                };
            }
            else {
                success = function (data) {
                    context.recovery_parent_style();
                    fix_response_data(data, context.state.entity_attributes.field_paths);
                    try {context.update_state_form_edit(data.data);}catch(err) { }
                    if (context.state.is_new_instance) {
                        if (data.data && data.data._id) {
                            context.state._id = data.data._id;
                        }
                    }
                    if (context.state.fileToUpload) {
                        context.state._id = context.state.entity_instance._id;
                        if (context.props.handle_executing) {
                            context.props.handle_executing(false);
                        }
                        context.setState({uploadFileModal: true, areButtonsDisabled: true});
                        return;
                    }

                    if (view_options && view_options.confirmation_message) {
                        Notify(view_options.confirmation_message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                    }
                    else {
                        if (!context.state.is_reference_nest) {
                            Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                        }
                        /*If this instance is a pop up, sended data(newIdCreated) for show new element into dropdown*/
                        try {
                            if(  jQuery.isFunction(context.props.data.selectNewItemDropdown) && (typeof context.props.data != "undefined" )) {
                                var caption = '';
                                if ((context.state.caption_function)) {
                                    if (context.state.entity_instance) {
                                        caption = context.state.caption_function(context.state.entity_instance);
                                    }
                                    else {
                                        caption = context.state.caption_function(context.state.entity_attributes.attributes);
                                    }
                                }
                                context.props.data.selectNewItemDropdown(send_data._id, caption);
                            }
                        }catch(err) { }
                        context.setState( { childInstance : 1 } );
                        try {
                            if(
                                jQuery.isFunction(context.props.data.change_field_to_update) &&
                                (typeof context.props.data != "undefined" )
                            ) {
                                var newIdCreated = send_data._id;
                                context.props.data.change_field_to_update(send_data.name);
                            }
                        }catch(err){}
                    }
                    if (context.state.entity_attributes && context.state.entity_attributes.entity == 'translation') {
                        load_translations(false, function() {
                            context.forceUpdate();
                        });
                    }
                    if (context.state.app_object && context.state.app_object.custom_properties && context.state.app_object.custom_properties.refresh_user_data) {
                        context.props.update_avatar();
                        context.props.update_user_info();
                    }
                    if (context.props.data && context.props.data.is_reference_nest) { //nested edit form
                        if (context.state.parent_entity_field_attribute && context.state.parent_entity_field_attribute.db_type == "file" && context.state.entity_instance) {
                            if (!context.props.data.parent_react_entity.state.privateFiles) {
                                context.props.data.parent_react_entity.state.privateFiles = {};
                            }
                            if (!context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path]) {
                                context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path] = [];
                            }
                            if(context.state._id == undefined) {
                                for (var i = 0, l = context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].length; i < l; i++) {
                                    if (context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path][i]){
                                        if(context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path][i].entity_id== context.props._id)
                                        {
                                            context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path][i].caption = context.state.entity_instance.description || context.state.entity_instance.file_name
                                        }
                                    } else{
                                        context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].splice(i,1);
                                        i--;
                                        l--;
                                    }
                                }
                            }
                            else {
                                context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].push(
                                    {
                                        entity_id: context.state._id,
                                        caption: context.state.entity_instance.description || context.state.entity_instance.file_name
                                    });
                            }

                        }
                        context.props.data.parent_react_entity.close_nested_entity_view(context.state._id);
                        return context.props.data.parent_react_entity.on_field_change(context.state.field_change_event_args || context.state.parent_entity_field_attribute, context.state.parent_entity_array);
                    }
                    else {
                        if (view_options) {
                            if (view_options.skip_refresh) {
                                if (context.props.handle_executing) {
                                    context.props.handle_executing(false);
                                }

                                return context.props.navigation_handler("home");
                            }
                        }
                        context.setState({
                            update_fields: {},
                            error: undefined,
                            modified: false,
                            workflow_action: undefined,
                            is_new_instance: false,
                            resetFileUpload: undefined,
                            areButtonsDisabled: false
                        });
                        if( false && startingPoint == "saveandnew"){
                            context.setState({
                                entity_instance:{}
                            });
                        }
                        else if(!data || !data.new_entity_flag) {
                            context.handle_close(undefined, fromAction);
                            if (context.props.handle_executing) {
                                context.props.handle_executing(false);
                            }
                            return;
                        }
                        if (context.props.handle_executing) {
                            context.props.handle_executing(false);
                        }
                        context.state = {};
                        if(false && startingPoint != "saveandnew") {
                            context.initialize_edit_form(data.data, true);
                        }
                        else{
                            context.initialize_edit_form([], true);
                        }

                    }

                };

            }
            entity_method = "upsert";
            if(context.state.entity_attributes['entity']=='org_level_user_data'){
                localStorage.removeItem("home");
                localStorage.removeItem("get_home_layout");
            }
            if (fromAction && context.state.workflow_action) {
                if (!context.props.data || context.props.data.is_reference_nest) { //direct nests don't invoke workflow
                    entity_method = "change_workflow_state";
                }
            }
            if (context.props.handle_executing&&!(!context.state.parent_entity_field_attribute||context.state.is_reference_nest)) {
                context.props.handle_executing(true);
            }
            /*84230669/ When Save button is pressed, detect and delete private files  */
            if( context.state.entity_instance.xenforma_file_attachments != undefined ){
                try{
                    if(context.state.filesUploaded.length){
                        for(var i=0; i<context.state.filesUploaded.length; i++ ) {
                            var matchFile=false;
                            var currentNewFileUpload = context.state.filesUploaded[i]._id;
                            if(context.state.filesUploaded[i].is_public=="false"){
                                for(var j=0; j<context.state.entity_instance.xenforma_file_attachments.length; j++ ) {
                                    var currentXenforma=context.state.entity_instance.xenforma_file_attachments[j];
                                    if(currentNewFileUpload == currentXenforma){
                                        matchFile=true;
                                    }
                                }
                                if(!matchFile) {
                                    var data = {_id: currentNewFileUpload};
                                    invoke_method("file", "delete", data, function(){context.recovery_parent_style(); }, function(){ context.recovery_parent_style();  });
                                }
                            }
                        }
                    }
                }catch (err){}
            }
            /*91221083/ Method when value of LOV is null replace this value of empty before send to server */
            var final_request = $.extend(true, {}, send_data);
            fix_request_data(final_request, context.state.entity_attributes.field_paths);
            this.props.handle_executing(true);
            var complete = function(){
                context.hidden_parent_style();
                context.recovery_parent_style();
                context.props.handle_executing(false);
            };
            invoke_method(entity_name, entity_method , final_request, success, error, function(){  context.recovery_parent_style(); context.hidden_parent_style(); }, (context.props.return_app_object_code || ((context.props.parent_react_entity) ? context.props.parent_react_entity.props.app_object_code : null)));
        }
        else if(context.state.scrolled){
            var element = this.get_form_object().find('.widget-body');
            element.scrollTop(0);
        }
        this.state.submitting = false;
        this.debugLog('handle_submit', false);
    },
    invalidate_cached_attributes: function() {

    },
    handle_delete: function (event) {
        this.debugLog('handle_delete', true);
        event.preventDefault();
        var context = this;
        if (!context.confirming){
            context.confirm(R.label_delete_title, R.message_delete_entity, function (confirmation) {
                if (confirmation) {
                    if (context.props.data && context.props.data.parent_react_entity) {
                        context.props.data.parent_react_entity.delete_nested_entity(context.state.parent_entity_field_attribute, context.state.parent_entity_array, context.state.entity_instance._id);
                        context.invalidate_cached_attributes();
                        context.props.data.parent_react_entity.close_nested_entity_view();
                    }
                    else {
                        var success = function (data) {
                            fix_response_data(data, context.state.entity_attributes.field_paths);
                            context.setState({update_fields: {}, error: undefined,modified: false});
                            Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                            if (context.state.entity_attributes && context.state.entity_attributes.entity == 'translation') {
                                load_translations(false,context.forceUpdate);
                            }
                            context.props.navigation_handler("home");
                        };
                        var error = function (err) {
                            context.setState({error: err.message});
                            server_error_action(err);
                        };
                        var data = {_id: context.state.entity_instance._id};
                        context.props.handle_executing(true);
                        invoke_method(context.state.entity_attributes.entity, "delete", data, success, error, function(){context.props.handle_executing(false);});
                    }
                }
            });
        }
        this.debugLog('handle_delete', false);
    },
    handle_workflow_action_click: function (action, status, event, startingPoint, a, b, c) {
        this.debugLog('handle_workflow_action_click', true);
        if (event && "function" == typeof event.preventDefault) {
            event.preventDefault();
        }
        this.state.update_fields.workflow_status = status;
        if (action.is_constructor) {
            this.state.update_fields.constructor_used = action.name;
        }

        this.state.workflow_action = action;

        if (action && action.view_options) {
            if (action.confirmation_message) {
                action.view_options.confirmation_message = action.confirmation_message; //for easy passing to handle_submit
            }
        }

        if (action && action.comment) {
            if (action.button_caption) {
                this.setState({workflowCommentPopup: {title:action.button_caption, action:action}});
            }
            else {
                this.setState({workflowCommentPopup: {action:action}});
            }
        }
        else {
            if (action) {
                /*Set Inactive day*/
                this.handle_submit(null, action.view_options, true,startingPoint);
            }
            else {

                this.handle_submit(null, undefined, true,startingPoint);
            }
        }
        this.debugLog('handle_workflow_action_click', false);
    },
    close_all_tooltip_popovers: function(){
        //$('.dx-dialog .dx-button[aria-label="No"]').trigger('click');
        if (this.state.popover){
            this.state.popover.hide();
        }
        if (this.state.popover_loading){
            this.state.popover_loading.hide();
        }
        for (var i = 0; this.state.entity_attributes && i < this.state.entity_attributes.attributes.length; i++) {
            var attribute = this.state.entity_attributes.attributes[i];
            if (!attribute.form_visible) {
                continue;
            }

            var info_id = "#info_" + attribute.field_path.replace(/:/g, '-');

            if ($(info_id + '_desc').text()) {
                $(info_id + "_desc").dxPopover("hide");
                //$(info_id + "_desc").dxPopover("hide");

            }
        }
    },
    nested_entity_handler: function (child_entity_state_data) {
        this.debugLog('nested_entity_handler', true);
        this.close_all_tooltip_popovers();
        this.state.child_entity_state_data = child_entity_state_data;
        this.state.child_entity_state_data.entity_access = this.state.entity_access;
        this.state.areButtonsDisabled = true;
        this.save_scroll_position();
        this.debugLog('nested_entity_handler', false);
        return this.forceUpdate();
    },
    reference_entity_handler: function (reference_entity_attribute, reference_entity_id, target_entity, field_change_event_args) {
        this.debugLog('reference_entity_handler', true);
        var base_field_path, full_field_path;
        if(field_change_event_args) {
            base_field_path = field_change_event_args.base_field_path;
        }
        if(base_field_path && (base_field_path != "")) {
            full_field_path = base_field_path + ":" + reference_entity_attribute.field_path;
        }
        else {
            full_field_path = reference_entity_attribute.field_path;
        }
        if (target_entity){
            this.state.nested_reference_entity_data = {
                reference_entity_name: target_entity,
                reference_entity_id: reference_entity_id,
                attribute: reference_entity_attribute,
                field_change_event_args: field_change_event_args,
                value: get_json_data_using_field_paths(full_field_path, this.state.entity_instance)
            };
        } else {
            this.state.nested_reference_entity_data = {
                reference_entity_name: reference_entity_attribute.db_type,
                reference_entity_id: reference_entity_id,
                attribute: reference_entity_attribute,
                field_change_event_args: field_change_event_args,
                value: get_json_data_using_field_paths(full_field_path, this.state.entity_instance)
            };
        }
        this.state.areButtonsDisabled = true;
        this.save_scroll_position();
        this.debugLog('reference_entity_handler', false);
        return this.forceUpdate();
    },
    on_error_close: function () {
        this.debugLog('on_error_close', true);
        this.setState({scrolled : false});
        this.setState({error: undefined});
        this.debugLog('on_error_close', false);
    },
    form_is_read_only: function () {
        return (this.state && this.state.app_object && this.state.app_object.custom_properties && !this.state.app_object.custom_properties.editable || (this.state.entity_access && this.state.entity_access.indexOf('u')==-1 ));
    },
    handleCommentSaveClick: function(comment){
        this.debugLog('handleCommentSaveClick', true);
        this.state.update_fields.workflow_comment = comment;
        if (!comment || (comment.trim() == "")) {
            Notify(R.message_enter_workflow_comment, 'bottom-right', notify_timeout, 'red', 'fa-check', true);
        }
        else {
            var action = this.state.workflowCommentPopup.action;
            this.setState({workflowCommentPopup:undefined});
            this.handle_submit(null, action.view_options, true);
        }
        this.debugLog('handleCommentSaveClick', false);
    },
    handleCommentCancelClick: function(){
        this.debugLog('handleCommentCancelClick', true);
        this.setState({workflowCommentPopup:undefined});
        this.debugLog('handleCommentCancelClick', false);
    },
    selectNewItemDropdown:function(newKey, newCaption){
        this.debugLog('selectNewItemDropdown', true);
        /*Function to update states, when added a new option for dropdown*/
        this.setState({  flagSaveDropdown : 2 , newKey :newKey  , newCaption: newCaption});
        this.debugLog('selectNewItemDropdown', false);
    },
    update_state_form_edit:function( data ){
        this.debugLog('update_state_form_edit', true);
        this.setState({not_get_by_code : data});
        this.debugLog('update_state_form_edit', false);
    },
    /* 84230669/Custom function to save in state the list of files uploaded(public or not) for after validate if delete */
    uploadFiles_array: function (data,is_public){
        this.debugLog('uploadFiles_array', true);
        if(is_public==undefined) is_public=false;
        var array_files=[];
        if(!this.state.filesUploaded){
            array_files[0]=$.parseJSON('{ "_id" : "'+data+'" , "is_public" : "'+is_public+'" }') ;
        }else{
            var currentArray=this.state.filesUploaded;
            currentArray[currentArray.length]=$.parseJSON('{ "_id" : "'+data+'" , "is_public" : "'+is_public+'" }') ;
            array_files=currentArray;
        }
        this.setState({filesUploaded:array_files});
        this.debugLog('uploadFiles_array', false);
    },
    //86972915 setting state When edit button is pressed
    edit_fun: function ( local_attribute,attribute_value,local_attribute_field_change_event_args ){
        this.debugLog('edit_fun', true);
        this.setState( {
            reference_entity_id:attribute_value,
            startEdit : true,
            editAttribute : local_attribute,
            flagSaveDropdown : 1,
            currentFieldPath : local_attribute.field_path,
            currentAttribute: local_attribute
        });
        $("#info_"+local_attribute.field_path).parent().find(".dx-texteditor-input").focus();
        this.reference_entity_handler(local_attribute, null, null, local_attribute_field_change_event_args);
        this.debugLog('edit_fun', false);
    },
    variant_edit_function: function(local_attribute, attribute_value, target_entity){
        this.debugLog('variant_edit_function', true);
        this.setState( {
            reference_entity_id:attribute_value,
            startEdit : true,
            editAttribute : local_attribute
        });
        $("#info_"+local_attribute.field_path).parent().find(".dx-texteditor-input").focus();

        this.reference_entity_handler(local_attribute, attribute_value, target_entity);
        this.debugLog('variant_edit_function', false);
    },
    /* 86972915 On focus and write new text into dropdown when is saved */
    change_field_to_update : function (name){
        this.debugLog('change_field_to_update', true);
        var context=this;
        $("#info_"+context.state.editAttribute.field_path).parent().find(".dx-texteditor-input").focus();
        context.on_field_blur(context.state.editAttribute);
        this.debugLog('change_field_to_update', false);
    },
    openDescriptionForm: function(idForm,e){
        this.debugLog('openDescriptionForm', true);
        $(".descriptionForm[data-unique-id='"+idForm+"' ]").slideToggle();
        e.preventDefault();
        this.debugLog('openDescriptionForm', false);
    },
    openLookupFieldOnTab:function(){
        this.debugLog('openLookupFieldOnTab', true);
        $(".dx-lookup-field").click();
        this.debugLog('openLookupFieldOnTab', false);
    },
    variant_ref_add_button:function(attribute){
        this.debugLog('variant_ref_add_button', true);
        var context = this;
        if(! context[attribute.field_path+"search"]) {
            context[attribute.field_path+"search"] = {};
            if (!context[attribute.field_path+"search"].variant_ref_is_searching) {
                var success = function (attribute) {
                    return function (data) {
                        if (data.data.length > 1) {
                            context.state.entity_attributes.field_paths[attribute.field_path].choose_entity = data.data;
                            context.forceUpdate();

                        }
                        else if (data.data.length == 1) {

                            context.state.entity_attributes.field_paths[attribute.field_path].var_ref_entity = data.data;
                            context.forceUpdate();
                        }
                        else {
                            context.state.entity_attributes.field_paths[attribute.field_path].hide_var_ref_buttons = true;
                            context.forceUpdate();
                        }
                    }
                }(attribute);
                var error = function (error) {
                    context[attribute.field_path].variant_ref_is_searching = false;
                    context.setState({error: error.message});
                    server_error_action(error);
                };
                var request = {};
                request.data = {};
                request.data.entity_reference_category = attribute.entity_reference_variant.entity_reference_category;
                if(typeof context.state == "string") {
                    request.data.entity_permissions = context.state;
                }
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/entity/get_entities_with_permissions",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: success,
                    error: error
                });

                context[attribute.field_path+"search"].variant_ref_is_searching = true;
            }
        }
        this.debugLog('variant_ref_add_button', false);
    },
    change_private_state:function(){
        this.debugLog('change_private_state', true);
        if(this.state.entity_instance) {
            if(this.state.entity_instance.assigned_to_private_flag)
            {
                this.state.entity_instance.assigned_to_private_flag = false;
                this.on_field_change(this.state.entity_attributes.field_paths["assigned_to_private_flag"],false);
            }
            else{
                this.state.entity_instance.assigned_to_private_flag = true;
                this.on_field_change(this.state.entity_attributes.field_paths["assigned_to_private_flag"],true);
            }
            this.forceUpdate();
        }
        this.debugLog('change_private_state', false);
    },
    change_following_state:function(){
        this.debugLog('change_following_state', true);
        if(this.state.entity_instance) {
            if(this.state.entity_instance.following_flag)
            {
                this.state.entity_instance.following_flag = false;
                this.on_field_change(this.state.entity_attributes.field_paths["following_flag"],false);
            }
            else{
                this.state.entity_instance.following_flag = true;
                this.on_field_change(this.state.entity_attributes.field_paths["following_flag"],true);
            }
            this.forceUpdate();
        }
        this.debugLog('change_following_state', false);
    },
    assign_users:function(local_attribute){
        this.debugLog('assign_users', true);
        var context = this;
        context.open_popover(local_attribute.field_path, function(){
            var currentValue = context.state.entity_instance[local_attribute.field_path],
                element=$('#assign-to-user-select'),
                button_box = $('<div/>', {style:"padding:5px"}),
                save_button_var = $('<button/>',
                    {
                        type:'button',
                        style:'margin:5px; float:right; margin-left:5px: margin-top:5px',
                        class:'btn btn-success shiny workflow_button_margin',
                        id:'button_assigned_to_user',
                        text: R.label_save,
                        click: context.save_user_assigned
                    }),
                cancel_button_var = $('<button/>',{
                    type:'button',
                    style:'margin:5px; float:right; margin-left:5px: margin-top:5px',
                    class:'btn shiny btn-default',
                    id:'button_cancel_assigned_to_user',
                    text: R.label_cancel,
                    click: function(){context.state.popover.hide()}
                });
            if (currentValue){
                element.val( currentValue.entity_id);
            }

            $(cancel_button_var).appendTo(button_box);
            $(save_button_var).appendTo(button_box);
            $(button_box).appendTo($(element).parent()[0]);
        });
        this.debugLog('assign_users', false);
    },
    save_user_assigned:function(){
        this.debugLog('save_user_assigned', true);
        var local_attribute = get_attribute_by_field_path(this.state.entity_attributes.attributes, 'assigned_to_user');
        if (local_attribute){
            this.on_field_change(local_attribute,find_by_field("entity_id", $('#assign-to-user-select').val(), local_attribute.select_options));
            this.state.popover.hide();
        }
        this.debugLog('save_user_assigned', false);
    },
    set_open_state: function (attribute, state){
        this.debugLog('set_open_state', true);
        attribute.is_open = state;
        this.debugLog('set_open_state', false);
    },
    save_scroll_position: function(){
        this.debugLog('save_scroll_position', true);
        this.scrollPosition = (this.get_form_object().find('.widget-body').first().scrollTop() || this.scrollPosition) -10;
        if (this.scrollPosition < 0){
            this.scrollPosition = 0;
        }
        this.restoreScroll = false;
        this.debugLog('save_scroll_position', false);
    },
    restore_scroll_position: function (context) {
        this.debugLog('restore_scroll_position', true);
        context = context || this;
        delete context.recover_scroll;
        if (this.attempts == undefined){
            this.attempts = 5;
        }
        if (context.restoreScroll && !isNaN(context.restoreScroll)){
            var element = context.get_form_object().find('.widget-body'),
                currentPosition = element.scrollTop();
            element.scrollTop(context.scrollPosition);
            if (context.scrollPosition == element.scrollTop()){
                delete context.attempts;
                context.scrollPosition = 0;
                context.restoreScroll = false;
            } else {
                if (currentPosition == element.scrollTop()){
                    context.attempts--;
                }
                if (context.attempts > 0){
                    setTimeout(function(){context.restore_scroll_position(context);}, 200)
                }
                else {
                    context.scrollPosition = 0;
                    context.restoreScroll = false;
                    delete context.attempts;
                }
            }
        }
        this.debugLog('restore_scroll_position', false);
    },
    is_save_and_new: function(){
        this.debugLog('is_save_and_new', true);
        var is_cyclic_insert = this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.cyclic_insert;
        this.debugLog('is_save_and_new', false);
        return  is_cyclic_insert && this.state.is_new_instance && this.props.is_nested_grid_child;
    },
    set_element_reference: function(attribute, element){
        this[attribute.field_path] = element;
    },
    set_confirming: function(set){
        if (set){
            this.confirming = new Date();
        }
        else{
            delete this.confirming;
        }
    },
    confirm: function(title, message, callback){
        var context = this;
        if (!context.confirming || ((new Date() - context.confirming) >  500)){
            context.set_confirming(true);
            if (this.props.is_nested_grid_child){
                this.props.parent_react_entity.confirm(title, message, function(x){
                    context.set_confirming(false);
                    callback(x);
                });
            }
            else{
                display_yes_no_dialogue(title, message, function(x){
                    context.set_confirming(false);
                    callback(x);
                });
            }
        }
        else{
            callback(false);
        }
    },
    render: function () {
        this.debugLog('render', true);
        var edit_form = null,
            input_array = [],
            context = this,
            hasCaption = true,
            styleParent="block",
            input_div,
            record_fields_box,
            record_fields_box_show;
        if (this.state.nested_reference_entity_data) { //popup reference entity edit form
            styleParent="none";
            var ref_data = {
                is_reference_nest: true,
                parent_react_entity: this,
                app_object_code: this.state.nested_reference_entity_data.reference_entity_name + "_default_edit_form",
                key: this.state.nested_reference_entity_data.reference_entity_name + "_default_edit_form" + this.state.reference_entity_id,
                parent_entity_field_attribute: this.state.nested_reference_entity_data.attribute,
                field_change_event_args: this.state.nested_reference_entity_data.field_change_event_args,
                selectNewItemDropdown: this.selectNewItemDropdown,
                uploadFiles_array: this.uploadFiles_array,
                parent_entity_array: this.state.nested_reference_entity_data.value,
                change_field_to_update: this.change_field_to_update,
                resize_form_edit: this.hidden_parent_style
            };
            var input_element = <EditFormComponent data={ref_data}
                                               new_entity_json={context.state.nested_reference_entity_data.new_entity_json}
                                               app_object_handler={this.props.app_object_handler}
                                               handle_executing={this.props.handle_executing}
                                               _id={this.state.reference_entity_id}
                                               key={this.state.reference_entity_id}
                                               navigation_handler={this.props.navigation_handler}
                                               update_state_form_edit={this.update_state_form_edit}
                                               edit_func={ this.edit_fun }
                                               display_loader={this.display_loader}
                                               parentIsModal={this.props.isModal || this.props.parentIsModal}
                                               parent_react_entity={this}
            />;

            input_div = <div  className="row" key={"div-"+input_element.key}  >
                <div className={"col-sm-12"}>
                    <div className="form-group"  style={{marginBottom : "0px"}} >
                        {input_element}
                    </div>
                </div>
            </div>;
            edit_form = input_div;
        }
        else if (this.state.child_entity_state_data) {
            styleParent = "none";
            var input_element = <EditFormComponent
                data={context.state.child_entity_state_data}
                handle_executing={this.props.handle_executing}
                parentIsModal={this.props.isModal||this.props.parentIsModal}
                is_nested_grid_child={true}
                parent_react_entity={this}
            />;
            input_div = <div  className="row" key={"div-"+input_element.key} >
                <div className={"col-sm-12"}>
                    <div className="form-group" style={{marginBottom : "0px"}}   >
                        {input_element}
                    </div>
                </div>
            </div>;
            edit_form = input_div;
        }
        else {
            var options = {};
            input_array = create_form_controls_for_attributes(context, options);
            record_fields_box_show = options.record_fields_box_show;
            record_fields_box = options.record_fields_box;
        }
        var error_display = this.state.error?styleParent:'none';
        var error_component = "";
        var isIE = !!document.documentMode;
        if (this.state) {
            if (isIE){
                error_component = <div  className="ie-warning alert alert-danger" style={{ display : error_display,"paddingBottom":"15px"}}>
                    <div  className="ie-close" style={{ float : "right",color:"black",cursor:"pointer"}} onClick={this.on_error_close}>X</div>
                    {this.state.error}
                </div>;
            }else{
                error_component =
                    <ErrorNotificationComponent message={this.state.error} on_close={this.on_error_close}/>;
            }
        }

        var app_object_name, caption;
        if ((this.state.caption_function)) {
            if (this.state.entity_instance) {
                caption = this.state.caption_function(this.state.entity_instance);
                if(!caption){hasCaption = false;}else{hasCaption = true;}
            }
            else {
                caption = this.state.caption_function(this.state.entity_attributes.attributes);
            }
        }
        if (this.state.app_object && this.state.app_object.name) {
            app_object_name = this.state.app_object.name;
        }

        if ((!caption || ($.trim(caption.trim) == "")) && !context.props.duplicate_id) {
            caption = app_object_name;
        }

        if ((!caption || ($.trim(caption.trim) == "")) && (this.state.entity_attributes && this.state.entity_attributes.caption_singular) && !context.props.duplicate_id) {
            caption = this.state.entity_attributes.caption_singular + ": " + (caption || " ");
        }
        var workflow_status = "";
        var skip_save = (this.form_is_read_only() || (this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.disable_save));

        var form_buttons = [];
        //Cancel button
        var cancel_button = (
            <button type="button"
                    id="cancel-button"
                    style={{"margin":"0px", "float":"right","marginLeft":"5px","marginTop":"5px"}}
                    onClick={this.handle_close}
                    className="btn btn-default shiny"
                    disabled={this.state.areButtonsDisabled}
                    key={"QK-form-buttons-cancel"}>
                {R.label_cancel}
            </button>);

        //Delete button
        var delete_button;
        if (this.state.entity_access && this.state.entity_access.indexOf('d') !== -1 && !this.state.app_object.custom_properties.disable_delete ) {
            delete_button = <button type="button"
                                    id="delete-button"
                                    style={{"margin":"0px", "float":"right","marginLeft":"5px" , "marginTop":"5px"}}
                                    onClick={this.handle_delete}
                                    disabled={this.state.areButtonsDisabled}
                                    key={"QK-form-buttons-delete"}
                                    className="btn btn-danger shiny workflow_button_margin">
                {R.label_delete}
            </button>;
        }


        //Save button
        var save_button;
        if (this.state.entity_access && (this.state.entity_access.indexOf('c') !== -1 || this.state.entity_access.indexOf('u') !== -1)) {
            var save_click = (this.props.data && this.props.data.parent_react_entity)?this.handle_submit:context.handle_submit;
            save_button = <button type="button"
                                  id="submit-button"
                                  style={{"margin":"0px" , "float" : "right","marginLeft":"5px","marginTop":"5px"}}
                                  onClick={save_click}
                                  disabled={this.state.areButtonsDisabled}
                                  className="btn1 btn btn-success shiny workflow_button_margin"
                                  key="QK-form-buttons-save">
                {R.label_save}
            </button>;
            if(this.state.app_object.custom_properties && this.state.app_object.custom_properties.disable_save)
            {
                save_button = "";
            }
        }
        //Workflow buttons
        var workflow_buttons = [];
        if (this.state.is_new_instance && this.state.entity_access && this.state.entity_access.indexOf('u') !== -1) {
            if (!caption || (caption.trim() == "")) {
                caption = app_object_name;
            }
            else if(caption.indexOf(app_object_name) != -1 && hasCaption != true){
                caption = R.label_new + " " + caption;
            }
            if(caption == app_object_name && context.props.duplicate_id && hasCaption != true){
                caption = R.label_new + " " + caption;
            }
            if (this.state.workflow_states) {
                var has_constructor, has_default, keys = [];
                for (var status in this.state.workflow_states) {
                    var workflow_state = this.state.workflow_states[status];
                    if (workflow_state.default) {
                        has_default = true;
                    }
                    if (workflow_state.constructors) {
                        for (var workflow_constructor_key in workflow_state.constructors) {
                            var workflow_constructor = workflow_state.constructors[workflow_constructor_key];
                            has_constructor = true;
                            workflow_constructor.name = workflow_constructor_key;
                            workflow_constructor.is_constructor = true;
                            if (keys.indexOf(workflow_constructor_key)==-1){
                                keys.push(workflow_constructor_key);
                                workflow_buttons.push(<button type="button"
                                                              style={{"margin":"0px", float: "right" , "marginLeft":"5px" , "marginTop":"5px"}}
                                                              disabled={this.state.areButtonsDisabled}
                                                              onClick={this.handle_workflow_action_click.bind(this, workflow_constructor, status)}
                                                              key={"QK-form-buttons-workflow-"+workflow_constructor_key}
                                                              className="btn btn-primary shiny workflow_button_margin">
                                    {workflow_constructor.button_caption}
                                </button>);
                            }
                        }


                    }
                }
            }
        }
        else {
            if (this.state.workflow_states && this.state.entity_access && this.state.entity_access.indexOf('u') !== -1) {
                var entity_instance_status = this.state.entity_instance.workflow_status;
                if (entity_instance_status) {
                    for (var status in this.state.workflow_states) {
                        var workflow_state = this.state.workflow_states[status];
                        if (status == entity_instance_status) {
                            workflow_status = workflow_state;
                            for (var workflow_action_status in workflow_state.workflow_actions) {
                                var workflow_action = workflow_state.workflow_actions[workflow_action_status];
                                if (workflow_action.button_caption) {
                                    workflow_buttons.push(<button type="button"
                                                                  style={{"margin":"0px" , float : "right" , "marginLeft":"5px" , "marginTop":"5px"}}
                                                                  disabled={this.state.areButtonsDisabled}
                                                                  onClick={this.handle_workflow_action_click.bind(this, workflow_action,workflow_action_status)}
                                                                  key={"QK-form-buttons-workflow-action-"+workflow_action.button_caption}
                                                                      className="btn btn-primary shiny workflow_button_margin">
                                        {workflow_action.button_caption}
                                    </button>);
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }

        //save and new
        //show if is nested grid new item and flag sent from server. Replaces save button.
        var saveandnew, saveandnew_click = (this.state.workflow_states && this.state.is_new_instance)?this.handle_workflow_action_click.bind(this, workflow_constructor, status):context.handle_submit.bind(this,null,undefined,true);
        if(this.is_save_and_new()) {
            saveandnew = (
                <button type="button"
                        style={{"margin":"0px", "float":"right","marginLeft":"5px","marginTop":"5px"}}
                        onClick={saveandnew_click}
                        className="btn btn-success shiny workflow_button_margin"
                        disabled={this.state.areButtonsDisabled}
                        key={"QK-form-buttons-save-new"}>
                    {R.client_saveandnew_button_label?R.client_saveandnew_button_label:"Save and new"}
                </button>);
        }

        //Fill button box in proper order.
        if (cancel_button){form_buttons.push(cancel_button);}
        if (delete_button && !this.state.is_new_instance){form_buttons.push(delete_button);}
        if (workflow_buttons.length > 0) {workflow_buttons.forEach(function(x){form_buttons.push(x);});}
        if (!(has_default || has_constructor) && save_button) {
            form_buttons.push(save_button);
        }
        if (saveandnew){
            if (!this.save_and_new){this.save_and_new = true;}
            form_buttons.push(saveandnew);
        }

        var borderColor = "lightgray";
        var workflow_status_html;
        if ((workflow_status && workflow_status.caption && (workflow_status.caption.trim() != ""))) {
            if (workflow_status && workflow_status.view_options && workflow_status.view_options.class_color) {
                borderColor = workflow_status.view_options.class_color;
            }
            workflow_status_html = (<span>{" "}<span
                className={"label label-"+borderColor}>{" " + workflow_status.caption.toUpperCase() + " "}</span> </span>);
        }
        else {
            workflow_status = null;
        }

        var uploadFileModal = null;
        if (this.state.uploadFileModal) {
            uploadFileModal =
                <EditorFileUploadModal file={this.state.fileToUpload} fileId={this.state.entity_instance._id}
                                       onUploadEnd={this.on_file_upload_end.bind(this)}
                                       onUploadCancel={this.on_file_upload_cancel.bind(this)}/>
        }

        var workflow_comment_popup = null;
        if (this.state.workflowCommentPopup) {
            workflow_comment_popup = <EditFormComment
                title={this.state.workflowCommentPopup.title}
                handleSaveClick={this.handleCommentSaveClick}
                handleCancelClick={this.handleCommentCancelClick}
                comment={this.state.update_fields.workflow_comment}
                parentIsModal={this.props.isModal||this.props.parentIsModal}
            />;
        }

        var edit_form_description = "";
        if((this.state.app_object && this.state.app_object.description) || (this.state.entity_attributes && this.state.entity_attributes.description_singular)) {
            var app_object_desc;
            if (this.state.app_object && this.state.app_object.description) {
                app_object_desc = this.state.app_object.description
            }
            else {
                app_object_desc = this.state.entity_attributes.description_singular;
            }

            var stylecontainer= {
                "wordBreak" : "break-word",
                "borderStyle": "solid",
                "borderWidth": "2px",
                "borderRadius": "5px",
                "marginBottom": "10px",
                "position":"relative",
                "display": styleParent

            };
            var styleback = {
                "backgroundColor" : borderColor,
                "width": "100%",
                "opacity":"0.3",
                "height":"100%",
                "position":"absolute"
            };
            var styletext = {
                "padding": "10px",
                "color":"black"

            };
            edit_form_description = (<div  className={"bordered-bottom bordered-"+borderColor}
                                           style={stylecontainer} >
                <div  style={styleback}>
                </div>
                <div style={styletext}>
                    <i style={{"marginRight":"5px"}} className="fa fa-check-square-o">&nbsp;</i>
                    {app_object_desc}
                </div>
            </div>);
        }

        form_buttons = (!this.state.init || this.state.areButtonsDisabled || this.state.uploadFileModal || this.state.workflowCommentPopup)?null:form_buttons;
        var idEntity = this.state.entity_instance?this.state.entity_instance._id||0:0;
        var colorSkip='rgba(96,96,96,1)';
        var displayDescription='';
        var marginRightskip="10px";
        var lockState = "fa-unlock-alt";
        var followState = "fa-rss";
        var assignState = "fa fa-user-plus";

        if(this.state.entity_instance){
            if(this.state.entity_instance.following_flag)
            {
                followState = "fa-rss-square"
            }
            else{
                followState = "fa-rss"
            }
        }

        if(this.state.entity_instance){
            if(this.state.entity_instance.assigned_to_private_flag)
            {
                lockState = "fa-lock"
            }
            else{
                lockState = "fa-unlock-alt"
            }
        }
        var descriptionButton=   <i  style={{
                                            cursor : "pointer" ,
                                            color : colorSkip,
                                            display : displayDescription,
                                            marginRight: marginRightskip,
                                            fontSize : "14px"
                                            }}
                                     data-unique-id={idEntity}
                                     onClick={ this.openDescriptionForm.bind(this,idEntity) }
                                     className="fa fa-question fa-3" aria-hidden="true" is-nested="true">&nbsp;</i>;
        var lockButton = <i  style={{
                                            cursor : "pointer" ,
                                            color : colorSkip,
                                            display : displayDescription,
                                            marginRight: marginRightskip,
                                            fontSize : "14px"
                                            }}
                             data-unique-id={idEntity}
                             onClick={ this.change_private_state.bind(this,idEntity) }
                             className={"fa "+ lockState} aria-hidden="true" is-nested="true">&nbsp;</i>;
        var followButton = <i  style={{
                                            cursor : "pointer" ,
                                            color : colorSkip,
                                            display : displayDescription,
                                            marginRight: marginRightskip,
                                            fontSize : "14px"
                                            }}
                               data-unique-id={idEntity}
                               onClick={ this.change_following_state.bind(this,idEntity) }
                               className={"fa "+ followState} aria-hidden="true" is-nested="true">&nbsp;</i>;

        if(this.state.entity_attributes && this.state.entity_attributes.field_paths["assigned_to_private_flag"])
        {
            if(this.state.entity_attributes.field_paths["assigned_to_private_flag"].read_only === true)
            {
                lockButton = "";
            }

        }
        else{
            lockButton = "";
        }

        if(this.state.entity_attributes && this.state.entity_attributes.field_paths["following_flag"])
        {
            if(this.state.entity_attributes.field_paths["following_flag"].read_only === true)
            {
                followButton = "";
            }

        }
        else{
            followButton = "";
        }
        if(this.state.entity_attributes && this.state.entity_attributes.field_paths["following_flag"])
        {
            if(this.state.entity_attributes.field_paths["following_flag"].read_only === true || this.form_is_read_only())
            {
                followButton = "";
            }

        }
        else{
            followButton = "";
        }
        if(this.state.entity_attributes && this.state.entity_attributes.field_paths["assigned_to_user"])
        {
            var assignedattribute = this.state.entity_attributes.field_paths["assigned_to_user"];
            var assignButton = <i  style={{
                                            cursor : "pointer" ,
                                            color : colorSkip,
                                            display : displayDescription,
                                            marginRight: marginRightskip,
                                            fontSize : "14px"
                                            }}
                                   data-unique-id={idEntity}
                                   id={"assigned_to_user"}
                                   onClick={ this.assign_users.bind(this,assignedattribute) }
                                   className={"fa "+ assignState} aria-hidden="true" is-nested="true">&nbsp;</i>;
            if(this.state.entity_attributes.field_paths["assigned_to_user"].read_only === true || this.form_is_read_only())
            {
                assignButton = "";
            }


        }
        else{
            assignButton = "";
        }
        var close_button = <i  style={{
                                            cursor : "pointer" ,
                                            color : colorSkip,
                                            display : displayDescription,
                                            marginRight: marginRightskip,
                                            fontSize : "14.7px"
                                            }}
                               id="close-button"
                               data-unique-id={idEntity}
                               onClick={this.handle_close}
                               className="fa fa-times" aria-hidden="true" is-nested="true">&nbsp;</i>;
        var styleParentBlock=styleParent=='block'? 'inline-table' : 'none';
        var loading_tag = '';
        if (context.state.tag_popover_loading){
            loading_tag =<div id="Loading_tag"><div style={{"textAlign":"center","margin":"auto"}}><img style={{"height":"30px"}} src="css/icons/loader.gif"/></div></div>
        }
        var dataObject = this.state.app_object_code || context.props.app_object_code || context.props.data.app_object_code || context.props.data.app_object.code;
        var defBody =(
            <form
                id="edit_form"
                role="form"
                data-object={dataObject}
                onSubmit={this.handle_submit}
                style={{ marginBottom: "0px" }}>
                <div
                    className="widget"
                    style={{ marginBottom : "0px"}}
                    key={idEntity}>
                    <div
                        className={"widget-header edit_form_header bordered-bottom bordered-"+borderColor}
                        style={{  display : styleParent }}>
                        <div class="row">
                            <div id="option1" className="col-def-xxs-3  col-def-xxm-4  widget-caption">
                                <div className="widget-caption truncate-text" style={{"textAlign":"left","lineHeight":"34px"}}>
                                    <b>  {caption || " "}</b>
                                </div>
                            </div>
                            <div id="option2" className="col-def-xxl-5 col-sm-9  col-md-9 col-lg-9  widget-caption">
                                <div className="widget-caption truncate-text" style={{"textAlign":"left","lineHeight":"34px"}}>
                                    <b>  {caption || app_object_name || " "}</b>
                                </div>
                            </div>
                            <div className="col-def-xxs-9 col-def-xxm-8 col-def-xxl-7  col-sm-3 col-md-3 col-lg-3 widget-caption" style={{"textAlign":"right", "paddingRight":"0px"}}>
                            </div>
                            {workflow_status_html}
                            <div className="widget-buttons">
                                {assignButton}
                                {followButton}
                                {lockButton}
                                {descriptionButton}
                                {close_button}
                            </div>
                        </div>
                    </div>
                    <div
                        id="form-body"
                        data-unique-id=''
                        className="widget-body"
                        data-is-nested={styleParent=='block' ?  'false' : 'true' }
                        data-resize='resize_element'
                        style={{height:this.get_height(styleParent), overflowX : "hidden" , overflowY : "auto"  , padding : styleParent=='block'?'10px':'0px' }}>
                        <span style={{ display : styleParent  }}>
                            <span className="descriptionForm" style={(this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.autoexpand_description) ? { display : "block" } : { display : "none" }} data-unique-id={idEntity}>
                                {edit_form_description}
                            </span>
                            {uploadFileModal}
                            {workflow_comment_popup}
                        </span>
                        <div
                            style={ ((uploadFileModal == null) && (workflow_comment_popup == null)) ? { paddingBottom:"0px"  } : {"display":"none"}}>
                            <span  style={{ display : error_display}}>
                                {error_component}
                            </span>


                            {edit_form}
                            {(this.state.custom_html_template) ? <CustomEditFormComponent state_data={this.state} props_data={this.props} edit_form_context={this} nested_list_entities={this.state.nested_list_entities} entity_attributes={this.state.entity_attributes} entity_instance={this.state.entity_instance} /> : input_array}
                            {loading_tag}
                        </div>
                    </div>
                    <div id="workflow-button-box"
                         align="right"
                         className="pull-right"
                         data-unique-id={idEntity}
                         style={{
                            width: "100%",
                            height : "100%" ,
                            padding: "10px",
                            backgroundColor : "white",
                            boxShadow: "1px 0 10px 1px rgba(0,0,0,0.3)",
                            textAlign: "right"
                            }}>
                        <div style={{width: "auto"}}>
                            <div id="record-fields-box" style={{
                            display:record_fields_box_show?"inline":"none",
                            overflow:"visible",
                            float:"left",
                            textAlign:"left"}}>
                                {record_fields_box}
                            </div>
                            <div style={{display:"inline"}}>
                                {form_buttons}
                            </div>
                        </div>
                        <div style={{width: "auto", position:"absolute", opacity:"0"}}>
                            <div id="record-fields-box-test" style={{
                                display:"inline",
                                overflow:"visible",
                                textAlign:"left",
                                float:"left",
                                paddingRight:"30px",
                                zIndex:"-1",
                                right:"10px"
                                }}>
                                {record_fields_box}
                            </div>
                            <div style={{display:"inline"}}>
                                {form_buttons}
                            </div>
                        </div>
                    </div>
                </div>
            </form>);

        var cap = '';
        if (caption){
            if (caption.indexOf(':') != -1){
                var cap_array = caption.split(':');
                if (cap_array[1].trim()){
                    cap = cap_array[1].trim();
                } else {
                    cap = cap_array[0].trim();
                }
            } else {
                cap = caption;
            }
        }
        var body = (
            <form id="edit_form"
                  role="form"
                  data-object={dataObject}
                  onSubmit={this.handle_submit} style={{ marginBottom: "0px" }}
            >
                <div className="widget" style={{ marginBottom : "0px"}} key={idEntity}>
                    <div  className={"widget-header edit_form_header bordered-bottom bordered-"+borderColor}
                          style={{  display : styleParent }}>
                        <div class="row">
                            <div id="option1" className="col-def-xxs-4  col-def-xxm-6  widget-caption">
                                <div className="widget-caption  truncate-text" style={{"textAlign":"left","lineHeight":"34px"}}>
                                    <b>{cap}</b>
                                </div>
                            </div>
                            <div id="option2" className="col-def-xxl-7 col-sm-8  col-md-9  col-lg-9 widget-caption">
                                <div className="widget-caption truncate-text" style={{"textAlign":"left","lineHeight":"34px"}}>
                                    <b>  {caption || app_object_name || " "}</b>
                                </div>
                            </div>
                            <div className="col-def-xxs-8 col-def-xxm-6 col-def-xxl-5  col-sm-4 col-md-3 col-lg-3 widget-caption" style={{"textAlign":"right", "paddingRight":"0px"}}>
                            </div>
                            {workflow_status_html}
                            <div className="widget-buttons">
                                {assignButton}
                                {followButton}
                                {lockButton}
                                {descriptionButton}
                                {close_button}
                            </div>

                        </div>

                    </div>

                    <div
                        id="form-body"
                        data-unique-id=''
                        className="widget-body"
                        data-is-nested={styleParent=='block' ?  'false' : 'true' }
                        data-resize='resize_element'
                        style={{height:this.get_height(styleParent), overflowX : "hidden" , overflowY : "auto"  , padding : styleParent=='block'?"10px":"0px" }}>
                        <span  style={{ display : styleParent  }}>
                            <span className="descriptionForm" style={(this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.autoexpand_description) ? { display : "block" } : { display : "none" }} data-unique-id={idEntity}>
                                {edit_form_description}
                            </span>
                            {uploadFileModal}
                            {workflow_comment_popup}
                        </span>
                        <div
                            style={ ((uploadFileModal == null) && (workflow_comment_popup == null)) ? { paddingBottom:"0px"  } : {"display":"none"}}>

                            <span id="one" style={{ display : error_display}}>
                                <hr className="wide"/>
                                {error_component}
                            </span>
                            {edit_form}
                            {(this.state.custom_html_template) ? <CustomEditFormComponent state_data={this.state} props_data={this.props} edit_form_context={this} nested_list_entities={this.state.nested_list_entities} entity_attributes={this.state.entity_attributes} entity_instance={this.state.entity_instance} /> : input_array}
                            {loading_tag}
                        </div>
                    </div>

                    <div id="workflow-button-box"
                         align="right"
                         className="pull-right"
                         data-unique-id={idEntity}
                         style={{
                            width: "100%",
                            height : "100%" ,
                            padding: "10px",
                            backgroundColor : "white",
                            boxShadow: "1px 0 10px 1px rgba(0,0,0,0.3)",
                            textAlign: "right",
                            display : styleParentBlock
                            }}>
                        <div style={{width: "auto"}}>
                            <div id="record-fields-box" style={{
                                display:record_fields_box_show?"inline":"none",
                                overflow:"visible",
                                textAlign:"left",
                                float:"left"}}>
                                {record_fields_box}
                            </div>
                            <div style={{display:"inline"}}>
                                {form_buttons}
                            </div>
                        </div>
                        <div style={{width: "auto", position:"absolute", opacity:"0"}}>
                            <div id="record-fields-box-test" style={{
                                display:"inline",
                                overflow:"visible",
                                textAlign:"left",
                                float:"left",
                                paddingRight:"30px",
                                right:"10px",
                                zIndex:"-1"
                                }}>
                                {record_fields_box}
                            </div>
                            <div style={{display:"inline"}}>
                                {form_buttons}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
        $('.fachange_history').removeClass('fachange_history').addClass( "fa fa-pencil-square-o" );
        $('.facomment').removeClass('fachange_history').addClass( "fa fa-comment-o" );
        $('.comment-footer[note_type!="comment"]').css("display" , "none");
        $('.faworkflow').removeClass('fachange_history').addClass( "fa fa-check-circle-o" );
        $('.fasystem_event').removeClass('fachange_history').addClass( "fa fa-asterisk" );
        context.debugLog('render', false);


        if (this.props.isModal) {
            if(this.state.is_new_instance){
                return <ModalComponent  handleHideModal={this.handle_close} ref="modal">{  defBody }</ModalComponent>
            }else{
                return <ModalComponent  handleHideModal={this.handle_close} ref="modal">{  body }</ModalComponent>
            }
        }
        else {
            return body ;
        }
    }
});