/**
 * ========================================================
 * Description: A web control for uploading a file (non-modal)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1	02/08/2016	Vasil Galinovsky	File uploads try 'get_file' when newly created	Added is new instance flag to file upload editor
 * 2    02/18/2016  Sebastian Mansfield  Fixed download link styles
 * ========================================================
 **/
var EditorFileUpload = React.createClass({
    getInitialState: function () {
        return null;
    },
    componentWillReceiveProps:function(nextProps) {
        if (nextProps.resetFileUpload)
        {
            this.fileUploadReset();
        }
    },
    render: function () {
        if (this.props.readOnly) {
            return <div></div>
        }
        var context = this;
        return <div style={{"marginTop":"6px"}}>
            <input ref={function(ref){context.domElement = ref}} type="file" style={{"display":"inline"}}/>
        </div>;
    },
    componentDidMount: function () {
        var context = this;
        if (this.props.readOnly)
            return;
        $(this.domElement).on('change', function (e) {
            var file = e.target.files[0];
            context.props.onFileNameChange(file);
        });

        if (this.props && this.props.fileName) {
            this.setState({fileName: this.props.fileName});
        }
    },
    fileUploadReset: function () {
        $(this.domElement).wrap('<form>').closest('form').get(0).reset();
        $(this.domElement).unwrap();
    },
});
