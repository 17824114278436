/**
 * ========================================================
 * Description: A web control for a tag-box (multi-select
 *              drop-down list)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author             Task        Description
 * ========================================================
 * 1   02/10/2016   Vasil Galinovsky                  Tag Box and Lookup  Adding item on enter
 * 2   04/14/2016   Sebastian Mansfield               Added template for tagbox
 * 3   07/11/2016   Mcasanova                         Props Now recive correct data, fix for iexplorer
 * 4   07/14/2016   Victor Hernandez      100137728   Fixed tagbox allowing duplicate values.
 * 5   07/21/2016   Victor Hernandez      100947647   Fixed tagbox not updating properly after adding a new file.
 * 6   07/27/2016   Victor Hernandez      102931086   Earlier dataSource loading
 * ========================================================
 **/
var EditorDevExtremeTagBox = React.createClass({
    getInitialState: function() {
        return {};
    },
    render: function() {
        var context = this;
        var className = "";
        if (this.props.add_button)
        {
            className = "add-button-margin";
        }
        return <div className={className} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    },
    componentWillReceiveProps:function(nextProps) {
        var newValues = nextProps.values;
        if (newValues) {
                if (this && this.state && this.state.tagbox_element){
                    var currentValues = this.state.tagbox_element.option('value'),
                        equals = true;
                    if (currentValues.length != newValues.length){
                        equals = false;
                    }
                    else {
                        for(var i = 0, u = newValues.length; i<u; i++){
                            if (currentValues.indexOf(newValues[i]) == -1){
                                equals = false;
                                break;
                            }
                        }
                    }
                    if (!equals){
                        this.state.tagbox_element.option('value', this.parseValues(newValues));
                    }
                }
            }
    },
    parseValues:function(inputValues){
        var values = [];
        for (var i=0;i<inputValues.length;i++)
        {
            var item = inputValues[i];
            if (typeof item == "object")
            {
                values.push(item[this.props.valueExpr]);
            }
            else {
                values.push(item);
            }
        }
        return values;
    },
    componentWillMount: function(){
        var ds = this.props.dataSource,
            context = this;
        if ('object' == typeof ds && !ds.items().length){
            context.state.loading = true;
            ds.load().done(function(){
                if(context.isMounted){
                    context.state.loading = false;
                    context.init();
                }
            });
        }
    },
    componentDidMount: function(){
        this.isMounted = true;
        if (!this.state.loading){
            this.init();
        }
    },
    init:function() {
        var context = this;
        var tagbox_element = $(this.domElement);
        var values = this.parseValues(context.props.values);
        var searchattribute = true;
        if (document.documentElement.clientWidth <= 650) {
            searchattribute = false;
        }
        var init_object = {
            readOnly: context.props.readOnly,
            value: values,
            displayExpr: context.props.displayExpr,
            valueExpr: context.props.valueExpr,
            dataSource: context.props.dataSource,
            searchEnabled: searchattribute,
            onValueChanged: function(data) {
                context.props.onChange(data.value);
                context.props.onBlur();
            }
        };
        if (context.props.usetagtemplate) {
            init_object.tagTemplate = context.props.tagTemplate;
        }
        this.state.tagbox_element = tagbox_element.dxTagBox(init_object).dxTagBox("instance");
        if ('function' == typeof this.props.set_element_reference){
            this.props.set_element_reference(this.state.tagbox_element);
        }
        // Handle adding item on enter
        // https://www.devexpress.com/Support/Center/Question/Details/T283536
        this.state.tagbox_element.registerKeyHandler("enter", function(e) {
            e.stopPropagation();
            if(this.option("fieldEditEnabled") && !this._$list.find(".dx-state-focused").length) {
                this._completeSelection();
            } else {
                if (this.option("opened"))
                {
                    this._selectFirstItem();
                    this._completeSelection();
                }
                else {
                    this.option("opened") && e.preventDefault();
                    this._keyboardProcessor._childProcessors[0].process(e);
                }
            }
        });
        this.state.tagbox_element.repaint();
    }
});
