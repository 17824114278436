/**
 * ========================================================
 * Description: Component to render a server error to the page
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1       ?           ?           ?           Created
 * 2      11/04/16     Victor H                Added self focus when it's spawned.
 * 3      07/07/16     andrew.gay  99925113    Messages now create line breaks when \n is found
 * 4      07/07/16     andrew.gay  99925113    Messages now create line breaks in IE as well
 * ========================================================
 **/
var ErrorNotificationComponent = React.createClass({
    componentDidMount: function(){
        ReactDOM.findDOMNode(this).scrollIntoView();
    },
    render: function() {
        var message = this.props.message;

        if(message) {
            message = message.split("\n").map(function (i) {
                return <div>{i}</div>;
            });
        }
        return (
            <div id="error_notification" className="alert alert-danger fade in">
                <button type="button" className="close" onClick={this.props.on_close}>
                    ×
                </button>
                {message}
            </div>);
    }
});
