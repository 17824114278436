"use strict";
/**
 ==================================================================================
 Description:       Calendar View DevExtreme
 Creation Date:     6/24/2016
 Author:            Victor Hernandez
 ==================================================================================
 Revision History
 ==================================================================================
 Rev    Date        Author           Task                Description
 ==================================================================================
 1      06/24/16  Victor Hernandez  98988838             Created
 2      07/14/16  Victor Hernandez 101180112             Overriding showAppointmentPopup to have the edit button show the edit form instead.
 3      08/17/16  Victor Hernandez Bug                   Fixed calendar item background color.
 ==================================================================================
 */


var EditorDevExtremeCalendarView = React.createClass({
    getInitialState: function () {
        if (!this.props.resources){
            this.props.buildResources();
        }
        return {
            dataSource: this.props.dataSource,
            descriptionExpr: this.props.descriptionExpr,
            endDateExpr: this.props.endDateExpr,
            startDateExpr: this.props.startDateExpr
        };
    },
    componentWillReceiveProps: function (nextProps) {
        if (this.state.element){
            try{
                if(nextProps.dataSource != this.state.dataSource){
                    this.state.dataSource = nextProps.dataSource;
                    this.state.element.dxScheduler('option', 'dataSource', nextProps.dataSource);
                }
                if (nextProps.descriptionExpr != this.state.descriptionExpr) {
                    this.state.descriptionExpr = nextProps.descriptionExpr;
                    this.state.element.dxScheduler('option', 'descriptionExpr', nextProps.descriptionExpr);
                }
                if (nextProps.endDateExpr != this.state.endDateExpr) {
                    this.state.endDateExpr = nextProps.endDateExpr;
                    this.state.element.dxScheduler('option', 'endDateExpr', nextProps.endDateExpr);
                }
                if (nextProps.startDateExpr != this.state.startDateExpr) {
                    this.state.startDateExpr = nextProps.startDateExpr;
                    this.state.element.dxScheduler('option', 'startDateExpr', nextProps.startDateExpr);
                }
                this.forceUpdate();
            } catch(e){}
        }
    },
    componentDidMount: function () {
        var context = this;
        this.state.dataSource = this.props.dataSource;
        this.props.dataSource.on('changed', function(){
            var items = this.items(),
                resources,
                currentItem,
                new_resources = [],
                current_resources = context.props.resources[0].dataSource;
            for (var i = 0, u = current_resources.length; i < u; i++){
                new_resources.push(current_resources[i].id);
            }
            for (i = 0, u = items.length; i<u; i++){
                currentItem = items[i];
                resources = currentItem.resources;
                for (var j = 0, v = resources.length; j<v;j++){
                    if (new_resources.indexOf(resources[j]) == -1){
                        new_resources.push(resources[j]);
                    }
                }
            }
            for (j=0,v=current_resources.length;j<v;j++){
                for (i=0, u = new_resources.length;i<u;i++){
                    if (!new_resources[i].id && new_resources[i].indexOf(current_resources[j].id)!=-1){
                        new_resources[i] = {
                            color:current_resources[j].color,
                            id:new_resources[i],
                            text:current_resources[j].text
                        };
                    } else if('string' == typeof new_resources[i] && new_resources[i].indexOf('none')!=-1){
                        new_resources[i] = {
                            color:'teal',
                            id:new_resources[i],
                            text:'None'
                        }
                    }
                }
            }
            context.props.resources[0].dataSource = new_resources;
        });
        var schedulerConfig = {
            readOnly: this.props.readOnly,
            dataSource: this.props.dataSource,
            groups: [],
            //endDateExpr: this.props.endDateExpr,
            //startDateExpr: this.props.startDateExpr,
            resources: this.props.resources,
            onAppointmentClick: this.props.onAppointmentClick,
            //onContentReady: this.calendar_contrast_fix,
            views:['month', 'week'],
            currentView: 'month',
            currentDate: new Date(),
            appointmentTemplate: this.appointmentTemplate,
            appointmentPopupTemplate: this.appointmentPopupTemplate,
            appointmentTooltipTemplate: this.appointmentTooltipTemplate,
            height:this.props.getHeight,
            editing:{
                allowAdding:false,
                allowDeleting:false,
                allowDragging:false,
                allowResizing:false,
                allowUpdating:true
            }
        };
        this.state.element = $(this.domElement).dxScheduler(schedulerConfig).dxScheduler('instance');
        this.state.element.showAppointmentPopup = function(e){
            $('.dx-scheduler-dropdown-appointments[aria-expanded="true"]').click();
            context.props.onAppointmentClick(e);
        }
    }, 
    appointmentTemplate: function(appointmentInfo, appointmentIndex, appointmentElement){
        var retval = 'item';
        appointmentElement.addClass('label-' + find_by_field('id', appointmentInfo.resources[0], this.state.element.option('resources')[0].dataSource).color);
        return retval;
    },
    appointmentPopupTemplate: function(){
        var retval = 'appointmentPopup';
        return retval;
    },
    appointmentTooltipTemplate: function(){
        var retval = 'appointmentTooltip';
        return retval;
    },
    render: function () {
        var context = this;
        var className = "";
        if (this.props.add_button) {
            className = "add-button-margin";
        }
        return <input class="form-control"/>,<div id={this.props.grid_id} className={className} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    }
});
