/**
 * ========================================================
 * Description: A web control for selecting a time (no date)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 * Revision History
 * ========================================================
 * Rev     Date        Author      Task        Description
 * ========================================================
 * 1       ?           ?           ?           Created
 * 2       07/20/16    Victor H    98735752    Invalid strings and empty values will now clear the picker.
 * ========================================================
 **/
var EditorDevExtremeTimePicker = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            if (this.state.value){
                this.state.element.dxDateBox('instance').option("value", this.state.value);
            }
            else{
                this.state.element.dxDateBox('instance').reset();
            }
        }
    },
    componentDidMount:function()
    {
        var context = this;
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxDateBox({
            type: "time",
            readOnly: this.props.readOnly,
            value: this.state.value == null? null:new Date(this.state.value),
            onValueChanged: function(data) {
                var value = new Date(data.value), old_value = new Date(data.previousValue);
                if(data.value && moment(value).isValid() && this.option('isValid')){
                    this.option('value', value);
                    context.setState({value:value});
                    if (value.toString() != old_value.toString()) {
                        context.props.onChange(value);
                    }
                } else {
                    this.reset();
                    context.setState({value:''});
                    context.props.onChange(null);
                }
            },
            onChange: function (data) {
                var value = new Date(data.value);
                if(!(moment(value).isValid() && this.option('isValid') && data.value)){
                    this.reset();
                    context.setState({value:''});
                }
            },
            onFocusOut: function(e){
                context.props.onBlur();
            }
        });
    },
    render: function() {
        var context = this;
        return <div ref={function(ref){context.domElement = ref}} />;
    }
});
